import React, { useState } from 'react';
import { text } from '../../utils';
import ArchiveIcon from '@material-ui/icons/Archive';
import ZipAsyncJobInterface from '../../store/interfaces/ZipAsyncJobInterface';
import useStyles from './DownloadZipButton.styles';
import { useInterval } from '../../hooks/useInterval';
import ActionButton from '../ActionButton';
import AsyncJobStatusEnum from '../../store/enums/AsyncJobStatusEnum';

enum ItemTypeEnum {
  OFFER = 'offer',
  APARTMENT = 'apartment',
  PROJECT = 'project',
  ATTACHMENT = 'attachment',
  MESSAGE = 'message',
  BUNDLE = 'bundle',
  BUNDLE_MATERIAL = 'bundle_material',
  BUNDLE_MATERIAL_PRICE = 'bundle_material_price',
  BUNDLE_PRICE = 'bundle_price',
  OFFER_ROW_CHANGE = 'offer_row_change',
  IMAGE = 'image',
  PROJECT_MATERIAL = 'project_material',
  MATERIAL_CATEGORY = 'material_category'
}

const DEFAULT_INTERVAL = 10000;

const ButtonTextAndIcon = ({ caption }: { caption: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonContainer}>
      <span data-testid='download-zip-button'>{caption}</span>
      <div>
        <ArchiveIcon />
      </div>
    </div>
  );
};

const DownloadZipButton = ({
  requestHandler,
  downloadHandler,
  pollingHandler,
  errorHandler,
  apartmentId,
  itemType,
  style,
  interval = DEFAULT_INTERVAL
}: {
  requestHandler: ({
    itemType,
    apartmentId
  }: {
    apartmentId?: number;
    itemType?: ItemTypeEnum;
  }) => Promise<ZipAsyncJobInterface>;
  downloadHandler: (downloadUrl: string) => Promise<void>;
  pollingHandler: (pollingUrl: string) => Promise<ZipAsyncJobInterface>;
  errorHandler: (message?: string) => Promise<void>;
  interval?: number;
  apartmentId?: number;
  itemType?: ItemTypeEnum;
  style?: object;
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [pollingUrl, setPollingUrl] = useState<string | null>(null);
  const [caption, setCaption] = useState<string>(text('zipDownload'));
  const onClickHandler = async (evt: React.SyntheticEvent<Element, Event>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (showSpinner) {
      return;
    }
    setShowSpinner(true);
    setCaption(text('preparingZip'));
    try {
      const job = await requestHandler({ itemType, apartmentId });
      await checkStatusAndDownload({ job });
      return;
    } catch (err) {
      console.error(err);
      setShowSpinner(false);
    }
  };
  async function checkStatusAndDownload({ job }: { job: ZipAsyncJobInterface }) {
    if (job.status === AsyncJobStatusEnum.FAILURE) {
      await errorHandler(job.message);
      setCaption(text('zipDownload'));
      setShowSpinner(false);
      setPollingUrl(null);

      return;
    }
    if (job.status === AsyncJobStatusEnum.SUCCESS && job.downloadUrl) {
      setPollingUrl(null);
      setCaption(text('downloadingZip'));
      await downloadHandler(job.downloadUrl);
      setCaption(text('downloadZip'));
      setShowSpinner(false);

      return;
    }
    if (!pollingUrl) {
      setPollingUrl(job.pollingUrl);
    }
  }
  useInterval(
    async () => {
      const job = await pollingHandler(pollingUrl!);
      await checkStatusAndDownload({ job });
    },
    pollingUrl ? interval : null
  );
  return (
    <div
      style={
        style || {
          margin: '12px 0px',
          padding: 0
        }
      }
    >
      <ActionButton
        onClick={onClickHandler}
        loading={showSpinner}
        loadingCaption={caption}
        disabled={showSpinner}
      >
        <ButtonTextAndIcon caption={text('zipDownload')} />
      </ActionButton>
    </div>
  );
};

export default DownloadZipButton;
