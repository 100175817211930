import ReactGA4 from 'react-ga4';
import appStore from '../store/AppStore';
import { GAEvent } from '../services';

interface TrackersProps {
  pageView: (_path: string) => void;
  modalView: (_path: string) => void;
  triggerEvent: (_gaEvent: GAEvent, _trackers?: string[]) => void;
}

/**
 * useTrackers hook
 *
 * @returns - TrackersProps
 */
export const useTrackers = (): TrackersProps => {
  const { organisationName, projectId, apartmentId } = appStore;
  const pageView = (path: string) =>
    ReactGA4.send({ hitType: 'pageview', page: path, title: 'Page' });

  const modalView = (path: string) =>
    ReactGA4.send({ hitType: 'pageview', page: path, title: 'Modal' });

  const triggerEvent = (gaEvent: GAEvent) => {
    ReactGA4.event(gaEvent.action, {
      category: gaEvent.category,
      label: gaEvent.label,
      organisation: organisationName,
      project: projectId,
      apartment: apartmentId
    });
  };

  return {
    pageView,
    modalView,
    triggerEvent
  };
};

// const { triggerEvent } = useTrackers();
