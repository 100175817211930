import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from '../locales/';

const instance = i18n
  .use(LngDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init(
    {
      resources,
      debug: false,
      fallbackLng: 'en',
      load: 'all',
      defaultNS: 'main',
      interpolation: {
        escapeValue: false // not needed for react!!
      },

      // react i18next special options (optional)
      react: {
        wait: false,
        // bindI18n: 'languageChanged loaded',
        // bindStore: 'added removed',
        nsMode: 'default'
      }
    },
    undefined
  );

export default instance;
