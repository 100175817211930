import { observable, action, computed } from 'mobx';

class LightBoxStore {
  @observable public isOpen = false;
  @observable public images: string[] = [];

  @observable public shownImageIndex: number = -1;

  @action public open = (images: string[], shownImageIndex = 0) => {
    this.isOpen = true;
    this.images = images;
    this.shownImageIndex = shownImageIndex;
  };

  @action public close = () => {
    this.isOpen = false;
    this.images = [];
  };

  @action public next = () => {
    this.shownImageIndex = this.images.length > this.shownImageIndex + 1 && [
      this.shownImageIndex + 1
    ]
      ? this.shownImageIndex + 1
      : 0;
  };

  @action public previous = () => {
    this.shownImageIndex = this.images[this.shownImageIndex - 1]
      ? this.shownImageIndex - 1
      : this.images.length - 1;
  };

  @computed get shownImage() {
    return this.images[this.shownImageIndex];
  }
}

const lightBoxStore = new LightBoxStore();

export default lightBoxStore;
