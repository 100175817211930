import { useState, useEffect } from 'react';
import { instance as axiosInstance } from '../axios';
import appStore from '../store/AppStore';
import authStore from '../store/AuthStore';

export interface ProjectSetting {
  key: string;
  value: string | number | boolean | null;
  origin?: string;
  group: string;
}

export interface ProjectSettingProperties {
  value: string | number | boolean | null;
  label?: string;
  description?: string;
  origin?: string;
  group: string;
}

interface ProjectSettings {
  loading: boolean;
  settings: ProjectSetting[];
  settingsMap: ProjectSettingsMap;
  error: boolean;
}

export type ProjectSettingsMap = Record<string, string | number | boolean | null> | null;

/**
 * useFetchProjectSettings hook
 *
 * @returns - ProjectSettings
 */
export const useFetchProjectSettings = (): ProjectSettings => {
  const { projectId, organisationId, tenantId } = appStore;
  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<ProjectSetting[]>([]);
  const [settingsMap, setSettingsMap] = useState<ProjectSettingsMap>({});
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!projectId || !authStore.token.length) return;

    setLoading(true);

    const fetch = async () => {
      const response = await axiosInstance.get(
        `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/settings`
      );
      const { data } = response.data;
      const projectSettings: ProjectSetting[] = [];
      const projectSettingsMap: ProjectSettingsMap = {};
      for await (const [key, value] of Object.entries(data.settings)) {
        const settingProperties = value as ProjectSettingProperties;
        projectSettings.push({ key, ...settingProperties } as ProjectSetting);
        projectSettingsMap[key] = settingProperties.value;
      }

      setSettings(projectSettings);
      setSettingsMap(projectSettingsMap);
      setLoading(false);
    };

    fetch().catch((e) => {
      setError(true);
      console.error(e);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, authStore.token]);

  return { loading, settings, settingsMap, error };
};
