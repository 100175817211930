export enum StartUrlParameters {
  apartmentId = 'apartmentId',
  tenantId = 'tenantId',
  organisationId = 'organisationId',
  projectId = 'projectId',
  accessToken = 'accessToken',
  username = 'username',
  password = 'password',
  gbToken = 'gbToken',
  environmentLocale = 'environmentLocale',
  lang = 'lang',
  authToken = 'authToken',
  integratedApp = 'integratedApp'
}

export const StartUrlParametersArray = [
  StartUrlParameters.apartmentId,
  StartUrlParameters.tenantId,
  StartUrlParameters.organisationId,
  StartUrlParameters.projectId,
  StartUrlParameters.accessToken,
  StartUrlParameters.username,
  StartUrlParameters.password,
  StartUrlParameters.gbToken,
  StartUrlParameters.environmentLocale,
  StartUrlParameters.lang,
  StartUrlParameters.integratedApp
];

enum UrlParameters {
  help = 'help',
  schedule = 'schedule',
  messages = 'messages',
  contact = 'contact',
  documents = 'documents',
  roomId = 'roomId',
  bundleId = 'bundleId'
}

export const modalParametersArray = [
  UrlParameters.help,
  UrlParameters.schedule,
  UrlParameters.messages,
  UrlParameters.contact,
  UrlParameters.documents
];

export default UrlParameters;
