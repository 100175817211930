import React from 'react';
import { SectionArrow } from '../Styled/SectionTitleDivider';
import Button from '../Button/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  width: 40px;
  height: 40px;

  svg {
    width: 15px;
    min-width: 15px;
    height: 10px;
    min-height: 10px;
  }
`;

const UpButton = ({ ...rest }) => {
  return (
    <StyledButton className='visible-on-mobile' {...rest}>
      <SectionArrow open />
    </StyledButton>
  );
};

export default UpButton;
