import React from 'react';
import { MessagingClientType, MessagingUI } from '@groupbuilderoy/messaging-client';
import i18next from 'i18next';
import { Apartment } from '@groupbuilderoy/messaging-client/dist/interfaces';
import appStore from '../../store/AppStore';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import authStore from '../../store/AuthStore';
import Progress from '../../components/Progress';
import { MessagingModal } from './styles';
import alertStore from '../../store/AlertStore';
import { text } from '../../utils';
import routingStore from '../../store/RoutingStore';
import theme from '../../theme';

const Messaging = () => {
  const {
    apartment,
    apartmentInfoFetched,
    apartmentInfoFetchingFailed,
    organisationId,
    tenantId,
    projectId,
    projectName
  } = appStore;

  return (
    <MessagingModal
      data-testid='messaging-modal'
      onClose={() => {
        routingStore.push('/materials');
      }}
    >
      <div className='messaging-modal-content'>
        <>
          {!apartmentInfoFetched ? (
            <Progress />
          ) : apartmentInfoFetchingFailed ? (
            alertStore.show(text('errors.fetchingApartmentInfoFailed'))
          ) : (
            <MessagingUI
              token={authStore.token}
              tenantId={tenantId}
              organisationId={organisationId}
              project={{ id: Number(projectId), name: projectName }}
              apartments={[
                {
                  id: apartment?.id,
                  name: String(apartment?.name)
                } as Apartment
              ]}
              theme={theme}
              language={i18next.language}
              clientType={MessagingClientType.CustomerJourney}
            />
          )}
        </>
      </div>
    </MessagingModal>
  );
};

export default (withRouter as any)(observer(Messaging));
