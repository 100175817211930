import moment from 'moment';
import 'moment/min/locales';
import i18n from 'i18next';
import DateFormat from '../store/enums/DateFormat';

export const datePassed = (date: string | undefined | null) => {
  return !!date ? moment(date).isBefore(moment()) : false;
};

export const compareDate = (dateA: string, dateB: string) => {
  return new Date(dateA).getTime() > new Date(dateB).getTime() ? -1 : 1;
};

export const getFormattedDateBasedOnLocale = (
  date: string | null | undefined,
  format: DateFormat
) => {
  if (!date) {
    return '-';
  } else {
    moment.locale(i18n.language);
    switch (format) {
      case DateFormat.COMPACT:
        return moment(date).utc().format('L');
      case DateFormat.EXTENDED:
        return moment(date).utc().format('L') + ', ' + moment(date).format('LT');
    }
  }
};

export const getDateFormat = () => {
  return moment.localeData(i18n.language).longDateFormat('L');
};
