import { Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './Notification.styles';
import { Badge, Icon, IconKeys, StyledPaper } from '@groupbuilderoy/gb-components-library';
import AuxiliarButton from '../AuxiliarButton';

export enum NotificationVariant {
  default = 'default',
  compact = 'compact'
}

export interface BadgeCustomColors {
  badgeColor: string;
  captionColor: string;
  descriptionColor: string;
  containerColor: string;
}

interface NotificationProps {
  id?: number;
  badgeCaption: string;
  caption: string;
  description: string;
  deadline?: string;
  containerColor?: string;
  borderColor?: string;
  badgeColors?: BadgeCustomColors;
  actionCaption?: string;
  actionOnClick?: () => void;
  onClose?: (id: number) => void;
  actionAsButton?: boolean;
  [x: string]: any;
}

const AuxiliarNotification: React.FC<NotificationProps> = ({
  id = 0,
  badgeCaption,
  caption,
  description,
  deadline,
  containerColor,
  borderColor,
  badgeColors,
  actionCaption,
  actionOnClick,
  onClose,
  actionAsButton,
  ...rest
}: NotificationProps) => {
  const classes = useStyles({ containerColor, borderColor, badgeColors })();
  return (
    <StyledPaper {...rest} className={`${classes.root} ${rest.className}`}>
      <div style={{ display: 'flex' }}>
        <div className={classes.mainContainer}>
          <div className={classes.infoContainer}>
            <Badge caption={badgeCaption} description={caption} customColors={badgeColors} />
            <div className={classes.descriptionContainer}>
              {description && (
                <Typography className={classes.description}>
                  {`${description ? `${description}.` : ''}`}
                </Typography>
              )}
              {deadline && (
                <Typography className={classes.description}>{deadline ? deadline : ''}</Typography>
              )}
            </div>
          </div>
          {actionOnClick && (
            <div className={classes.actionsContainer}>
              {actionCaption && !actionAsButton && (
                <div className={classes.action} onClick={actionOnClick}>
                  <Typography className={classes.actionText}>{actionCaption}</Typography>
                  <Icon
                    className={classes.actionIcon}
                    iconKey={IconKeys.arrowRight}
                    size={20}
                    color={badgeColors?.descriptionColor}
                  />
                </div>
              )}
              {actionCaption && actionAsButton && (
                <AuxiliarButton
                  caption={actionCaption}
                  onClick={actionOnClick}
                  endIcon={IconKeys.arrowRight}
                />
              )}
            </div>
          )}
        </div>
        {onClose && (
          <Icon
            className={classes.closeIcon}
            iconKey={IconKeys.close}
            color={'gray'}
            size={20}
            onClick={() => onClose(id)}
          />
        )}
      </div>
    </StyledPaper>
  );
};

export default AuxiliarNotification;
