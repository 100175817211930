// Import doesn't recognize pdf files, use require

const tilausaikatauluKohaus = require('./tilausaikataulu_kohaus.pdf');
const tilausaikatauluKeksitehdas = require('./tilausaikataulu_keksitehdas.pdf');
const tilausaikatauluTikli = require('./tilausaikataulu_tikli.pdf');
const tilausaikatauluSuvikulma = require('./tilausaikataulu_suvikulma.pdf');
const tilausaikatauluTohtori = require('./tilausaikataulu_tohtori.pdf');
const tilausaikatauluToripoliisiDemo = require('./tilausaikataulu_toripoliisi-demo.pdf');
const tilausaikatauluBarista = require('./tilausaikataulu_barista.pdf');
const tilausaikatauluNiittyla = require('./tilausaikataulu_niittyla.pdf');
const tilausaikatauluTahtitorni = require('./tilausaikataulu_tahtitorni.pdf');
const tilausaikatauluRomeo = require('./tilausaikataulu_romeo.pdf');
const tilausaikatauluPanimonrinne = require('./tilausaikataulu_panimonrinne.pdf');
const tilausaikatauluKarikukko = require('./tilausaikataulu_karikukko.pdf');
const showcaseDemoSchedule = require('./showcase_demo_schedule.pdf');
const tilausaikatauluProosa = require('./tilausaikataulu_proosa.pdf');
const tilausaikatauluTeodor = require('./tilausaikataulu_teodor.pdf');
const tilausaikatauluRatti = require('./tilausaikataulu_ratti.pdf');
const tilausaikatauluKaukonraitti = require('./tilausaikataulu_kaukonraitti.pdf');
const tilausaikatauluNiittyTonttu = require('./tilausaikataulu_niittytonttu.pdf');
const tilausaikatauluMyllykatu14 = require('./tilausaikataulu_myllykatu14.pdf');
const tilausaikatauluSuntionkulma = require('./tilausaikataulu_suntionkulma.pdf');
const tilausaikatauluFado = require('./tilausaikataulu_fado.pdf');

// Schedules mapped by 'tenantId_projectId'
const schedules = {
  'yit-br_4055': tilausaikatauluKohaus,
  'local-demo-br_108': null,
  'local-yit-demo_111': tilausaikatauluToripoliisiDemo,
  'yit-br_108': tilausaikatauluKeksitehdas,
  'yit-br_15368': tilausaikatauluTikli,
  'yit-br_19614': tilausaikatauluSuvikulma,
  'yit-br_10425': tilausaikatauluTohtori,
  'yit-br_54334': tilausaikatauluBarista,
  'yit-br_87130': tilausaikatauluNiittyla,
  'yit-br_25531': tilausaikatauluTahtitorni,
  'yit-br_36074': tilausaikatauluRomeo,
  'yit-br_81350': tilausaikatauluPanimonrinne,
  'yit-br_73685': tilausaikatauluKarikukko,
  'showcase-demo_156': showcaseDemoSchedule,
  'showcase-demo-en_7184': showcaseDemoSchedule,
  'yit-br_44313': tilausaikatauluProosa,
  'yit-br_93932': tilausaikatauluTeodor,
  'yit-br_98587': tilausaikatauluRatti,
  'yit-br_146573': tilausaikatauluKaukonraitti,
  'yit-br_110755': tilausaikatauluNiittyTonttu,
  'yit-br_178641': tilausaikatauluMyllykatu14,
  'yit-br_213812': tilausaikatauluSuntionkulma,
  'yit-br_240678': tilausaikatauluFado
};

export default schedules;
