import React, { useState } from 'react';

import { SectionTitle, SectionArrow, SectionTitleDivider } from '../Styled/SectionTitleDivider';

import AttachmentRowContainer from '../AttachmentRow/AttachmentRow';
import { mediaSmallMax } from '../Styled/media';
import styled from 'styled-components';
import SectionResizer from '../SectionResizer/SectionResizer';
import { Section as SectionInterface, SectionType } from '../../store/interfaces/Section';
import Contacts from '../Contacts/Contacts';
import { text } from '../../utils';
import { Attachment } from '../../store/interfaces/Attachment';
import appStore from '../../store/AppStore';

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2.6rem;

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const StyledSection = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
`;

const SubSectionTitle = styled.h3`
  margin: 1rem 0;
`;

interface SectionProps {
  section: SectionInterface;
  openAtStart?: boolean;
}

const SectionWrapper = ({ section, openAtStart }: SectionProps) => {
  const { integratedApp } = appStore;
  const [isOpen, setIsOpen] = useState(!!openAtStart);
  const [maxHeight, setMaxHeight] = useState('56px');

  const projectAttachments = section.attachments?.filter((a) => a.projectId !== null) || [];
  const apartmentAttachments = section.attachments?.filter((a) => a.apartmentId !== null) || [];
  const signableAttachments = section.signableAttachments || [];

  const renderAttachmentSection = (attachments: Attachment[], titleKey: string) => {
    if (!attachments.length) return null;

    return (
      <>
        <SubSectionTitle role='sectionhead'>{text(titleKey)}</SubSectionTitle>
        {attachments.map((attachment) => (
          <AttachmentRowContainer key={attachment.id} attachment={attachment} />
        ))}
      </>
    );
  };

  return (
    <StyledSection role='section' key={section.id} style={{ maxHeight, minHeight: maxHeight }}>
      <SectionResizer effectListeners={[isOpen]} setNewHeight={setMaxHeight}>
        <SectionTitleDivider
          onClick={() => setIsOpen(!isOpen)}
          open={isOpen}
          integratedCj2={integratedApp}
        >
          <SectionTitle>
            <h3>{section.path}</h3>
          </SectionTitle>
          <SectionArrow open={isOpen} />
        </SectionTitleDivider>
        {isOpen && (
          <>
            {section.type === SectionType.DocumentsAndLinks ? (
              <AttachmentsContainer>
                {renderAttachmentSection(signableAttachments, 'signableAttachments')}
                {renderAttachmentSection(projectAttachments, 'projectAttachments')}
                {renderAttachmentSection(apartmentAttachments, 'apartmentAttachments')}
              </AttachmentsContainer>
            ) : section.type === SectionType.Contacts && section.contacts ? (
              <Contacts contacts={section.contacts} />
            ) : null}
          </>
        )}
      </SectionResizer>
    </StyledSection>
  );
};

export default SectionWrapper;
