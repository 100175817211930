import React, { useEffect, useState } from 'react';
import { changeParams } from '../../store/RoutingStore';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import contactHeader from './contactBackground.png';
import { text, getSubsections } from '../../utils';
import contactStore from '../../store/ContactStore';
import { mediaSmallMax } from '../../components/Styled/media';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';
import { observer } from 'mobx-react-lite';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import Phase from '../../store/models/Phase';
import { useTrackers } from '../../hooks/useTrackers';

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media ${mediaSmallMax} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledBtn = styled(Button)`
  outline: none;
`;

const NoContactsContainer = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const Contact = () => {
  const { triggerEvent } = useTrackers();
  const { contactsFetched, fetchContacts, phases, phasesWithContacts } = contactStore;

  const [selectedPhaseId, setSelectedPhaseId] = useState(-1);
  const selectPhase = (id: number) => {
    if (id !== selectedPhaseId) setSelectedPhaseId(id);
  };
  const selectedPhase: Phase | undefined = phases.find((p) => p.id === selectedPhaseId);

  const sections =
    selectedPhase && selectedPhase.sections
      ? getSubsections(selectedPhase.sections, '').filter((section) => !!section.contacts!.length)
      : [];

  useEffect(() => {
    if (!contactsFetched) fetchContacts();
    if (!!phasesWithContacts.length) setSelectedPhaseId(phasesWithContacts[0].id);
  }, [phasesWithContacts.length]); // eslint-disable-line

  return (
    <Modal onClose={() => changeParams({ contact: undefined })} id='contacts' overflow='auto'>
      <ModalHeader
        bgImage={contactHeader}
        title={text('contacts')}
        description={text('contactsDescription')}
      />

      {!!phasesWithContacts.length && !!sections.length ? (
        <>
          {phasesWithContacts.length > 1 && (
            <ButtonRow>
              {phasesWithContacts.map((p) =>
                phasesWithContacts ? (
                  <StyledBtn
                    key={p.id}
                    invertColors={p.id === selectedPhaseId}
                    onClick={() => {
                      triggerEvent({
                        action: EventAction.OPEN_CONTACT_PHASE,
                        category: EventCategory.USER
                      });
                      selectPhase(p.id);
                    }}
                  >
                    {p.name}
                  </StyledBtn>
                ) : null
              )}
            </ButtonRow>
          )}
          {sections.map((section, i) => (
            <SectionWrapper key={section.id} section={section} openAtStart={i === 0} />
          ))}
        </>
      ) : (
        <NoContactsContainer>
          <h3>{text('thereAreNoContacts')}</h3>
        </NoContactsContainer>
      )}
    </Modal>
  );
};

export default observer(Contact);
