import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components/macro';
import Button from './Button';

const StyledButton = styled(Button)`
  padding: 10px 12px; 
  max-height: 46px;
  min-width: 46px;
  position: relative;

  &.loading {
    pointer-events: none;
    padding-top: 8px;
    
    .children {
      visibility: hidden;
    }
  }

  .progress {
    width: 25px !important;
    height: 25px !important;
  }
  
  &:hover {
    img {
      filter: brightness(0) invert(1);
    }
  }
'`;

export interface GBButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export const GBButton: React.FC<GBButtonProps> = (props) => {
  const { loading, children, ...rest } = props;

  return (
    <StyledButton className={loading ? 'loading' : ''} {...rest}>
      {loading && <CircularProgress className='progress' />}
      <div className='children'>{children}</div>
    </StyledButton>
  );
};
