import styled from 'styled-components/macro';
import Image from '../Icons/Image';
import { mediaSmallMax } from '../Styled/media';

export const MaterialImageWrapper = styled.div<{ onClick?: any }>`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const MaterialImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(-50%, -50%);
`;

export const MaterialPrice = styled.p`
  position: absolute;
  right: 0;
  bottom: 1rem;
  background-color: #f2f2f2;
  font-size: 15px;
  line-height: 16px;
  padding: 6px 9px;
  min-width: 3rem;

  @media ${mediaSmallMax} {
    padding: 4px 6px;
    font-size: 12px;
    line-height: 13px;
    bottom: 0.5rem;
  }
`;

export const NoImage = styled(Image)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: var(--color-lightGray);
`;
