import styled from 'styled-components/macro';
import { mediaSmallMax, mediaMediumMax } from '../Styled/media';
import Lock from '../Icons/Lock';
import { footerHeight, footerHeightMobile } from '../Footer/FooterStyledComponents';

export const StyledPopUp = styled.div`
  display: flex;
  width: 75%;
  height: 7rem;
  color: white;
  background-color: var(--custom-colorAccent);
  z-index: 9000;
  border: 2px solid var(--custom-colorAccent);
  max-height: 100%;
  position: fixed;
  left: 50vw;
  bottom: calc(${footerHeight} + 1rem);
  transform: translateX(-50%);
  height: 9rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  animation: fade-in 1s ease-in-out;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media ${mediaSmallMax} {
    width: 90%;
    height: 10rem;
    bottom: calc(${footerHeightMobile} + 1rem);
  }

  @media ${mediaMediumMax} {
    width: 85%;
  }
`;

export const StyledContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

export const StyledHeading = styled.div`
  font-size: 1.5rem;
  padding: 2px;

  @media ${mediaMediumMax} {
    font-size: 1.3rem;
  }
`;

export const StyledMessage = styled.div`
  font-size: 1rem;
  padding: 2px;

  @media ${mediaMediumMax} {
    font-size: 0.8rem;
  }
`;

export const StyledImage = styled(Lock)`
  width: 2.5rem;
  height: 2.5rem;
  float: left;
  margin: 0.5rem;
  margin-right: 1rem;
  fill: white;

  @media ${mediaMediumMax} {
    width: 2.2rem;
    height: 2.2rem;
  }

  @media ${mediaSmallMax} {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledButton = styled.button`
  border: 1px solid var(--custom-colorNotif2);
  border-radius: 2px;
  background-color: var(--custom-colorNotif2);
  color: white;
  font-size: 1rem;
  width: 5.5rem;
  height: 2rem;
  position: absolute;
  float: right;
  bottom: 0;
  right: 0;
  margin: 1rem;

  &:hover {
    background-color: var(--custom-colorAccent);
  }
`;
