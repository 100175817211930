import styled from 'styled-components';
import Modal from '../../components/Modal/Modal';
import { mediaXSmallMax } from '../../components/Styled/media';

export const MessagingModal = styled(Modal)`
  width: calc(100vw - 50px);
  max-width: 1700px;
  border-radius: 10px;
  overflow: auto;

  .messaging-modal-content {
    padding: 0.5rem 1.2rem;
  }

  .modal-close-btn {
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    z-index: 1;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .message-list {
  }

  @media ${mediaXSmallMax} {
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;

    .messaging-modal-content {
      height: calc(100vh - 6rem);
    }
  }
`;
