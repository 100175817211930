import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const progressSize: number = 18;

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: '80px',
      position: 'relative',
      textTransform: 'none',
      backgroundColor: theme.palette.primary.main, // TODO: this should not be necessary, since button has color "primary"

      '&.loading': {
        pointerEvents: 'none',

        '& .children': {
          display: 'none',
          margin: 'auto'
        }
      },

      '& .progress': {
        width: `${progressSize}px !important`,
        height: `${progressSize}px !important`,
        left: `calc(50% - ${progressSize / 2}px)`,
        top: '6px',
        color: 'white'
      }
    },
    stateCaption: {
      marginRight: '.5rem'
    }
  })
);
