import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SectionTitleDivider, SectionTitle, SectionArrow } from '../Styled/SectionTitleDivider';
import SectionResizer from '../SectionResizer/SectionResizer';
import { mediaMediumMax, mediaLargeMin, mediaSmallMax } from '../Styled/media';
import appStore from '../../store/AppStore';

const HeadingContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-wrap: wrap;
  flex-shrink: 0;

  @media ${mediaLargeMin} {
    padding-right: 2.6rem;
    &:not(.visible-on-desktop) {
      display: none;
    }
  }

  @media ${mediaMediumMax} {
    justify-content: flex-start;
    width: 100%;
    padding: 1rem 1rem 1rem 2.6rem;
    min-width: 0;

    &.visible-on-desktop {
      display: none;
    }
  }

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const StyledPageHeading = styled.div`
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const StyledSectionResizer = styled(SectionResizer)<{ integratedCj2?: boolean }>`
  ${SectionTitle} {
    display: block;
    justify-content: space-between;
    min-width: 100%;
  }

  ${SectionTitleDivider} {
    cursor: pointer;
  }

  @media ${mediaLargeMin} {
    ${SectionArrow} {
      display: none;
    }

    ${SectionTitle} {
      pointer-events: none;
      align-items: flex-start;
    }

    ${SectionTitleDivider} {
      padding-top: ${({ integratedCj2 }) => !integratedCj2 && '0 !important'};
      background: ${({ integratedCj2 }) =>
        integratedCj2 ? 'none !important' : 'white !important'};
      cursor: auto !important;
    }

    display: flex;
    justify-content: space-between;
    min-height: 4.5rem;
    align-items: center;
  }
`;

const HeadingText = styled.p`
  display: inline;
  @media ${mediaMediumMax} {
    max-height: 28px;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
  }
`;

const HeadingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  & > * {
    padding-right: 2.6rem;

    @media ${mediaMediumMax} {
      padding: 1rem 1rem 1rem 2.6rem;
    }

    @media ${mediaSmallMax} {
      padding: 1rem;
    }
  }
`;

interface PageHeadingProps {
  title: string;
  text?: string;
  children?: any | any[];
  contentProps?: any;
  resizeable?: boolean;
  resizeEffectListeners?: any[];
  whiteBg?: boolean;
  [x: string]: any;
}

const PageHeading = ({
  text,
  title,
  children,
  contentProps,
  resizeable,
  resizeEffectListeners,
  whiteBg,
  ...rest
}: PageHeadingProps) => {
  const [pageHeadingOpen, setPageHeadingOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(resizeable ? '66px' : undefined);
  const open = pageHeadingOpen && resizeable;
  const { integratedApp } = appStore;

  return (
    <StyledPageHeading style={{ maxHeight, minHeight: maxHeight }}>
      <StyledSectionResizer
        integratedCj2={integratedApp}
        effectListeners={[pageHeadingOpen, ...(resizeEffectListeners || [])]}
        setNewHeight={setMaxHeight}
      >
        <SectionTitleDivider
          integratedCj2={integratedApp}
          {...rest}
          style={{
            backgroundColor: !open && !whiteBg ? 'var(--color-lightGray)' : 'white'
          }}
        >
          <SectionTitle
            onClick={resizeable ? () => setPageHeadingOpen(!pageHeadingOpen) : undefined}
          >
            <h3 style={{ display: 'inline' }}>{title}</h3>
            {text && !(pageHeadingOpen || !resizeable) && <HeadingText>{text}</HeadingText>}
            {resizeable && <SectionArrow open={open} />}
          </SectionTitle>
        </SectionTitleDivider>
        {children && (pageHeadingOpen || !resizeable) && (
          <HeadingContentWrapper>
            <p>{text}</p>
            <HeadingContent {...contentProps}>{children}</HeadingContent>
          </HeadingContentWrapper>
        )}
        <HeadingContent className='visible-on-desktop' {...contentProps}>
          {children}
        </HeadingContent>
      </StyledSectionResizer>
    </StyledPageHeading>
  );
};

export default PageHeading;
