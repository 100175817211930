import { action, observable } from 'mobx';
import { fetchBundleDeadlineEvents } from '../axios';
import appStore from './AppStore';
import { CalendarEvent, ApiCalendarEvent } from './models/CalendarEvent';
import { CalendarEventInterface } from './interfaces/CalendarEvent';

export class CalendarStore {
  @observable calendarEvents: CalendarEvent[] = [];

  @action
  public fetchCalendarEvents = async () => {
    const eventsResp = await fetchBundleDeadlineEvents(
      appStore.organisationId,
      appStore.tenantId,
      appStore.projectId,
      appStore.apartmentId
    );
    this.calendarEvents = eventsResp.data.calendarEvents.reduce(
      (pre: CalendarEvent[], cur: ApiCalendarEvent) => {
        if (pre.find((ce) => ce.id === cur.id) === undefined) pre.push(new CalendarEvent(cur));
        return pre;
      },
      [] as Array<CalendarEvent>
    );
  };

  @action
  public getEventDeadLinesForHeader() {
    const upcomingEvents: CalendarEventInterface[] = this.calendarEvents.filter((event) => {
      if (event.eventDate && event.eventDate !== null) {
        const d1 = event.eventDate.split(/\./).reverse().join('-');
        if (new Date(d1).getTime() > new Date().getTime()) {
          return true;
        }
      }
      return false;
    });

    return upcomingEvents.sort(
      (a, b) =>
        new Date(a.eventDate.split(/\./).reverse().join('-')).getTime() -
        new Date(b.eventDate.split(/\./).reverse().join('-')).getTime()
    );
  }
}

const calendarStore = new CalendarStore();

export default calendarStore;
