import { observable, action } from 'mobx';
import { readMassmessage } from '../../axios';
import appStore from '../AppStore';

export interface MessageData {
  id?: number;
  content: string;
  userName: string;
  isFromUser: 0 | 1;
  discussionEnd: 0 | 1;
  sendTime: number;
  received: 0 | 1;
  recipient?: { authUserId: string; name: string };
  apartmentName?: string;
  attachmentFilePath: string;
  attachmentFileDisplayName: string;
}

export default class Message implements MessageData {
  @observable public id?: number;
  @observable public content: string;
  @observable public userName: string;
  @observable public isFromUser: 0 | 1;
  @observable public discussionEnd: 0 | 1 = 0;
  @observable public sendTime: number;
  @observable public sending: boolean = false;
  @observable public received: 0 | 1 = 0;
  @observable public attachmentFileDisplayName: string;
  @observable public attachmentFilePath: string;
  @observable public recipient?: { authUserId: string; name: string };
  @observable public apartmentName?: string;

  constructor(data: MessageData) {
    this.id = data.id;
    this.content = data.content;
    this.userName = data.userName;
    this.isFromUser = data.isFromUser;
    this.discussionEnd = data.discussionEnd;
    this.sendTime = data.sendTime;
    this.received = data.received;
    this.recipient = data.recipient;
    this.apartmentName = data.apartmentName;
    this.attachmentFileDisplayName = data.attachmentFileDisplayName;
    this.attachmentFilePath = data.attachmentFilePath;
  }

  @action
  public changeSending = (sending: boolean) => {
    this.sending = sending;
  };

  @action
  public receive = () => {
    this.received = 1;
    if (this.id) readMassmessage(appStore.apartmentId, this.id.toString());
  };
}
