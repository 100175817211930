import { observable, action, computed } from 'mobx';
import { saveAs } from 'file-saver';
import { fetchAttachments, getAttachmentsBySection, getZipAttachments } from '../axios';
import appStore from './AppStore';
import { triggerGAError } from '../services';
import Phase from './models/Phase';
import { SignableAttachment } from './interfaces/Attachment';
import userRightsStore from './UserRightsStore';
import { UserRight } from './enums/UserRights';
import { text } from '../utils';
import attachmentRepository from '../repositories/AttachmentRepository';
import alertStore from './AlertStore';
import Done from '../components/Icons/Done';
import React from 'react';

class AttachmentsStore {
  @observable public phases: Phase[] = [];
  @observable public attachmentsFetched = false;

  @observable public signableAttachments: SignableAttachment[] = [];
  @observable public signableAttachmentsFetched = false;

  @observable public signableDocument: unknown;

  @observable public fetchingFailed: boolean = false;

  @action public fetchAttachments = async () => {
    try {
      this.fetchingFailed = false;
      const { data } = await fetchAttachments(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId,
        appStore.apartmentId
      );
      this.phases = data.phases.map((p: any) => new Phase(p));

      await this.fetchSignableAttachments();
    } catch (e) {
      triggerGAError('fetching attachments failed', e.toString());
      console.error(e);
      this.fetchingFailed = true;
    } finally {
      this.attachmentsFetched = true;
    }
  };

  @action public fetchSignableAttachments = async () => {
    const { organisationId, tenantId, projectId, apartmentId } = appStore;
    try {
      this.signableAttachmentsFetched = false;

      const attachments = await getAttachmentsBySection(
        organisationId,
        tenantId,
        projectId,
        apartmentId
      );
      this.signableAttachments = attachments.filter((a: any) => a.signable || a.signed);

      this.setSignableAttachmentsToPhaseSections();
    } catch (e) {
      triggerGAError('Fetching signable attachments failed', e.toString());
      console.error(e);
    } finally {
      this.signableAttachmentsFetched = true;
    }
  };

  @computed
  public get phasesWithAttachments(): Phase[] {
    return this.phases.filter((a) => a.hasAttachments);
  }

  private async setSignableAttachmentsToPhaseSections() {
    for (const phase of this.phasesWithAttachments) {
      for (const section of phase.sections) {
        section.signableAttachments = this.signableAttachments.filter((attachment) => {
          return attachment.sections.some((s) => s.sectionId === section.id);
        });

        // duplicate attachments filtered out
        section.attachments = section.attachments?.filter((attachment) => {
          return !section.signableAttachments?.some(
            (signableAttachment) => signableAttachment.id === attachment.id
          );
        });
      }
    }
  }

  public getUnsignedDocumentsCount() {
    if (!userRightsStore.check(UserRight.SIGN_DOCUMENT)) return 0;

    return this.signableAttachments.filter((a) => !a.signed && a.signable).length;
  }

  @action public downloadZip = async () => {
    try {
      const blob = await getZipAttachments(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId
      );
      const tempURL = URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
      await saveAs(tempURL, text('attachmentsFilename') + '.zip');
    } catch (err) {
      throw new Error('Failed to zip attachments');
    }
  };

  @action public downloadZipAsync = async (downloadUrl: string) => {
    try {
      const blob = await attachmentRepository.downloadZipAttachments(downloadUrl);
      const tempURL = URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
      await saveAs(tempURL, text('attachmentTable.attachmentsFilename') + '.zip');
      alertStore.show(text('downloadZipSuccess'), '', 7000, <Done />, 'var(--custom-colorNotif3)');
    } catch (err) {
      alertStore.show(text('errors.creatingAttachmentsZip'));
    }
  };

  @action public pollZipAsync = async (pollingUrl: string) => {
    try {
      const job = await attachmentRepository.pollZipAttachments(pollingUrl);

      return job;
    } catch (err) {
      alertStore.show(text('errors.creatingAttachmentsZip'));
    }
  };

  @action public showZipAsyncError = async (message?: string) => {
    if (!message) {
      alertStore.show(text('errors.creatingAttachmentsZip'));
      return;
    }
    alertStore.show(message);
  };
}

const attachmentsStore = new AttachmentsStore();

export default attachmentsStore;
