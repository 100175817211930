import React from 'react';
// eslint-disable-next-line
export default (props: any) => (
  <svg
    width='14'
    height='24'
    viewBox='0 0 14 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M13.4753 13.1798L2.94581 23.5484C2.5257 23.9685 1.84638 23.9685 1.42627 23.5484L0.791643 22.9137C0.371536 22.4936 0.371536 21.8143 0.791643 21.3942L9.93567 12.42L0.800581 3.44581C0.380474 3.0257 0.380474 2.34638 0.800581 1.92627L1.43521 1.29164C1.85532 0.871536 2.53464 0.871536 2.95475 1.29164L13.4842 11.6602C13.8954 12.0803 13.8954 12.7597 13.4753 13.1798Z' />
  </svg>
);
