import { Customisation } from './store/models/OrganisationCustomisation';

interface OrganisationCustomisations {
  [x: string]: Customisation;
}

/** Stores organisation specific customisations and settings (logos, colors, fonts)
 */
const organisationCustomisations: OrganisationCustomisations = {
  yit: {
    colorMain: '#009FDA',
    colorHover: '#b9c9dd',
    colorAccent: '#294754',
    colorAccent2: '#294754',
    colorAccent3: '#bdbdbd',
    colorNotif1: '#DB4D69',
    colorNotif2: '#E98300',
    colorNotif3: '#3F9C35',
    colorNotif4: '#009FDA',
    colorLoadingBackground: undefined,
    colorLoadingSpinner: undefined,
    fontSrc: undefined,
    fontLight: 'Helvetica Neue LT W05_45 Light',
    fontMedium: 'Helvetica Neue LT W05_65 Medium',
    fontNormal: 'Helvetica Neue LT W05_55 Roman',
    fontBold: 'Helvetica Neue LT W01_75 Bold'
  },

  bonava: {
    colorMain: '#009FDA',
    colorHover: '#85C8B5',
    colorAccent: '#85C8B5',
    colorAccent2: '#004A32',
    colorAccent3: '#85C8B5',
    colorNotif1: '#DB4D69',
    colorNotif2: '#E98300',
    colorNotif3: '#3F9C35',
    colorNotif4: '#009FDA',
    colorLoadingBackground: undefined,
    colorLoadingSpinner: undefined,
    fontSrc: undefined,
    fontLight: 'Helvetica Neue LT W05_45 Light',
    fontMedium: 'Helvetica Neue LT W05_65 Medium',
    fontNormal: 'Helvetica Neue LT W05_55 Roman',
    fontBold: 'Helvetica Neue LT W01_75 Bold'
  },

  skanska: {
    colorMain: '#293E6B',
    colorHover: '#C0DDEA',
    colorAccent: '#293E6B',
    colorAccent2: '#313131',
    colorAccent3: '#313131',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: 'rgb(30, 120, 30)',
    colorNotif4: '#293E6B',
    colorNavLinks: '#0078D2',
    colorLoadingBackground: 'rgb(255,255,255, 0.5)',
    colorLoadingSpinner: '#293E6B',
    fontSrc: undefined,
    fontLight: 'Verdana',
    fontMedium: 'Verdana Bold',
    fontNormal: 'Verdana',
    fontBold: 'Verdana Bold'
  },

  gbDefaultCustomisation: {
    colorMain: 'rgb(13,128,132)',
    colorHover: 'rgb(120,120,120)',
    colorAccent: 'rgb(63,63,63)',
    colorAccent2: 'rgb(63,63,63)',
    colorAccent3: 'rgb(63,63,63)',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: 'rgb(30, 120, 30)',
    colorNotif4: 'rgb(13,128,132)',
    colorLoadingBackground: 'rgb(255,255,255, 0.5)',
    colorLoadingSpinner: 'rgb(13,128,132)',
    fontSrc: undefined,
    fontLight: 'Helvetica Neue LT W05_45 Light',
    fontMedium: 'Helvetica Neue LT W05_65 Medium',
    fontNormal: 'Helvetica Neue LT W05_55 Roman',
    fontBold: 'Helvetica Neue LT W01_75 Bold'
  },

  lapti: {
    colorMain: 'rgb(13,128,132)',
    colorHover: 'rgb(120,120,120)',
    colorAccent: '#bb3239',
    colorAccent2: 'rgb(63,63,63)',
    colorAccent3: '#bb3239',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: 'rgb(30, 120, 30)',
    colorNotif4: '#bb3239',
    colorLoadingBackground: undefined,
    colorLoadingSpinner: undefined,
    fontSrc: undefined,
    fontLight: 'Helvetica Neue LT W05_45 Light',
    fontMedium: 'Helvetica Neue LT W05_65 Medium',
    fontNormal: 'Helvetica Neue LT W05_55 Roman',
    fontBold: 'Helvetica Neue LT W01_75 Bold'
  },

  hartela: {
    colorMain: 'rgb(46,46,46)',
    colorHover: 'rgb(210,210,210)',
    colorAccent: '#4E474E',
    colorAccent2: '#4E474E',
    colorAccent3: '#4E474E',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: '#4E474E',
    colorNotif4: 'rgb(13,128,132)',
    colorLoadingBackground: undefined,
    colorLoadingSpinner: undefined,
    fontSrc: undefined,
    fontLight: 'CeraPro-Light',
    fontMedium: 'CeraPro-Regular',
    fontNormal: 'CeraPro-Medium',
    fontBold: 'CeraPRO-Bold'
  },

  ion: {
    colorMain: 'rgb(13,128,132)',
    colorHover: 'rgb(120,120,120)',
    colorAccent: '#bb3239',
    colorAccent2: 'rgb(63,63,63)',
    colorAccent3: '#bb3239',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: 'rgb(30, 120, 30)',
    colorNotif4: '#bb3239',
    colorLoadingBackground: undefined,
    colorLoadingSpinner: undefined,
    fontSrc: undefined,
    fontLight: 'Helvetica Neue LT W05_45 Light',
    fontMedium: 'Helvetica Neue LT W05_65 Medium',
    fontNormal: 'Helvetica Neue LT W05_55 Roman',
    fontBold: 'Helvetica Neue LT W01_75 Bold'
  },

  hsb: {
    colorMain: 'rgb(13,128,132)',
    colorHover: 'rgb(120,120,120)',
    colorAccent: 'rgb(114, 183, 95)',
    colorAccent2: 'rgb(63,63,63)',
    colorAccent3: 'rgb(63,63,63)',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: 'rgb(30, 120, 30)',
    colorNotif4: 'rgb(13,128,132)',
    colorLoadingBackground: 'rgb(255,255,255, 0.5)',
    colorLoadingSpinner: 'rgb(13,128,132)',
    fontSrc: undefined,
    fontLight: 'Helvetica Neue LT W05_45 Light',
    fontMedium: 'Helvetica Neue LT W05_65 Medium',
    fontNormal: 'Helvetica Neue LT W05_55 Roman',
    fontBold: 'Helvetica Neue LT W01_75 Bold'
  },
  srv: {
    colorMain: 'rgb(13,128,132)',
    colorHover: 'rgb(120,120,120)',
    colorAccent: '#212427',
    colorAccent2: '#212427',
    colorAccent3: '#212427',
    colorNotif1: 'rgb(210, 70, 70)',
    colorNotif2: 'rgb(210, 100, 20)',
    colorNotif3: 'rgb(30, 120, 30)',
    colorNotif4: 'rgb(13,128,132)',
    colorLoadingBackground: 'rgb(255,255,255, 0.5)',
    colorLoadingSpinner: 'rgb(13,128,132)',
    fontSrc: undefined,
    fontLight: 'FK Grotesk Neue Light',
    fontMedium: 'FK Grotesk Neue Medium ',
    fontNormal: 'FK Grotesk Neue Regular',
    fontBold: 'FK Grotesk Neue Bold'
  }
};

export default organisationCustomisations;
