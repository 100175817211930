import { EventAction } from './EventAction';
import { EventCategory } from './EventCategory';
import { ModalView } from './ModalView';

export const Enums = {
  EventAction,
  EventCategory,
  ModalView
};

export * from './tracking';
