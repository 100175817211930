enum ItemTypeEnum {
  OFFER = 'offer',
  APARTMENT = 'apartment',
  PROJECT = 'project',
  ATTACHMENT = 'attachment',
  MESSAGE = 'message',
  BUNDLE = 'bundle',
  BUNDLE_MATERIAL = 'bundle_material',
  BUNDLE_MATERIAL_PRICE = 'bundle_material_price',
  BUNDLE_PRICE = 'bundle_price',
  OFFER_ROW_CHANGE = 'offer_row_change',
  IMAGE = 'image',
  PROJECT_MATERIAL = 'project_material',
  MATERIAL_CATEGORY = 'material_category'
}

export default ItemTypeEnum;
