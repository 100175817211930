import React from 'react';

// Remove when we fetch image from somewhere else
import { Switch, Route } from 'react-router';
import FrontPageHeader from './FrontPageHeader';
import MaterialsPageHeader from './MaterialsPageHeader';
import OrderConfirmationHeader from './OrderConfirmationHeader';
import appStore from '../../store/AppStore';

const Header = () => {
  return (
    <Switch>
      <Route
        exact
        path='/'
        component={appStore.integratedApp ? MaterialsPageHeader : FrontPageHeader}
      />
      <Route path='/materials' component={MaterialsPageHeader} />

      <Route path='/order-confirmation' component={OrderConfirmationHeader} />
    </Switch>
  );
};

export default Header;
