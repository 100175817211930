const getElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  return {
    element: (element || document.createElement('script')) as HTMLScriptElement,
    elementDidExist: !!element
  };
};

export const appendScript = (elementId: string, script: string, callback?: () => void) => {
  const { element, elementDidExist } = getElement(elementId);

  element.id = elementId;
  element.async = true;
  element.innerHTML = script;

  if (!elementDidExist) {
    document.head.appendChild(element);
  }

  if (callback) {
    callback();
  }

  return () => {
    document.head.removeChild(element);
  };
};
