import React, { useState, useEffect } from 'react';
import { changeParams } from '../../store/RoutingStore';
import Modal from '../../components/Modal/Modal';
import documentsHeader from './documentsHeader.png';
import ModalHeader from '../../components/Modal/ModalHeader';
import { text, getSubsections } from '../../utils';
import styled from 'styled-components/macro';
import { SectionTitleDivider } from '../../components/Styled/SectionTitleDivider';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import attachmentsStore from '../../store/AttachmentsStore';
import Button from '../../components/Button/Button';
import { mediaSmallMax } from '../../components/Styled/media';

import { Phase } from '../../store/interfaces/Phase';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';
import Progress from '../../components/Progress';
import SignableDocument from './SignableDocument';
import ExclamationIcon from '../../components/Icons/Exclamation';
import Notification from '../../components/Notification/Notification';
import { NotificationBadge } from '../../components/AttachmentRow/AttachmentRow';
import DownloadZipButton from '../../components/DownloadZipButton/DownloadZipButton';
import attachmentRepository from '../../repositories/AttachmentRepository';

const StyledBtn = styled(Button)`
  outline: none;

  .badge {
    left: unset;
    right: -9px;
    top: -7px;
    z-index: 1;
  }
`;

const DocumentsContent = styled.section`
  margin-bottom: 5rem;

  @media ${mediaSmallMax} {
    ${SectionTitleDivider} {
      padding: 1rem;

      h3 {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media ${mediaSmallMax} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const NoDocumentsContainer = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const Documents = () => {
  const {
    attachmentsFetched,
    phases,
    phasesWithAttachments,
    fetchAttachments,
    signableAttachmentsFetched,
    fetchingFailed
  } = attachmentsStore;

  const [selectedPhaseId, setSelectedPhaseId] = useState(
    phasesWithAttachments.length === 1 ? phasesWithAttachments[0].id : -1
  );

  const selectPhase = (id: number) => {
    if (id !== selectedPhaseId) setSelectedPhaseId(id);
  };

  const selectedPhase: Phase | undefined = phases.find((p) => p.id === selectedPhaseId);

  const sections =
    selectedPhase && selectedPhase.sections
      ? getSubsections(selectedPhase.sections, '').filter(
          (section) => !!section.attachments!.length || !!section.signableAttachments!.length
        )
      : [];

  useEffect(() => {
    if (!attachmentsFetched) fetchAttachments();
    if (!!phasesWithAttachments.length) setSelectedPhaseId(phasesWithAttachments[0].id);
  }, [attachmentsFetched]); // eslint-disable-line

  const content = () => {
    if (!attachmentsFetched || !signableAttachmentsFetched) return <Progress />;
    else if (fetchingFailed) {
      return (
        <Notification
          color='var(--custom-colorNotif1)'
          icon={<ExclamationIcon fill='white' />}
          isStatic={true}
        >
          <p className='font-medium'>{text('errors.fetchingAttachmentsFailed')}</p>
          <p>{text('errors.commonMessage')}</p>
        </Notification>
      );
    }

    if (attachmentsStore.signableDocument) {
      return <SignableDocument />;
    } else if (!!phasesWithAttachments.length && !!sections.length) {
      return (
        <>
          {phasesWithAttachments.length > 1 && (
            <ButtonRow>
              {phasesWithAttachments.map((p) => {
                return (
                  <StyledBtn
                    key={p.id}
                    invertColors={p.id === selectedPhaseId}
                    onClick={() => selectPhase(p.id)}
                  >
                    {p.name}
                    {p.hasUnsignedDocuments && p.id !== selectedPhaseId && (
                      <NotificationBadge className='badge' />
                    )}
                  </StyledBtn>
                );
              })}
            </ButtonRow>
          )}
          {sections.map((section, i) => (
            <SectionWrapper key={section.id} section={section} openAtStart={i === 0} />
          ))}
        </>
      );
    } else {
      return (
        <NoDocumentsContainer>
          <h3>{text('thereAreNoDocuments')}</h3>
        </NoDocumentsContainer>
      );
    }
  };

  return (
    <Modal
      role='dialog'
      overflow='auto'
      onClose={() => {
        attachmentsStore.signableDocument = null;
        changeParams({ documents: undefined });
      }}
      id='documents'
    >
      <ModalHeader
        bgImage={documentsHeader}
        title={text('documents')}
        description={text('documentsDescription')}
        children={
          <DownloadZipButton
            requestHandler={attachmentRepository.getZipAttachmentsAsync}
            downloadHandler={attachmentsStore.downloadZipAsync}
            pollingHandler={attachmentsStore.pollZipAsync}
            errorHandler={attachmentsStore.showZipAsyncError}
            interval={attachmentRepository.POLLING_ZIP_ATTACHMENT_INTERVAL}
          />
        }
      />
      <DocumentsContent>{content()}</DocumentsContent>
    </Modal>
  );
};

export default (withRouter as any)(observer(Documents));
