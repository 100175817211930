import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import offersStore from '../../store/OffersStore';
import OffersConfirmationDropdown from './OffersConfirmationDropdown';
import { text } from '../../utils';
import { Offer } from '../../store/models/Offer';
import { scrollTo } from '../../utils';
import { Element } from 'react-scroll';
import { changeParams } from '../../store/RoutingStore';
import appStore from '../../store/AppStore';
import { tenantsUsingInfoBoxes } from '../../utils/tenantsUtils';
import { IconKeys, InfoBox } from '@groupbuilderoy/gb-components-library';
import { Typography } from '@material-ui/core';
import OfferState from '../../store/enums/OfferState';

interface OffersConfirmationProps {
  offers: Offer[];
  hideConfirmationGuide?: boolean;
}
const OffersConfirmation = ({ offers, hideConfirmationGuide }: OffersConfirmationProps) => {
  useEffect(() => {
    if (offersStore.selectedOffer) scrollTo(`offer${offersStore.selectedOffer}`, true);
    offersStore.selectedOffer = null;
  });
  return (
    <>
      {!!offers.length &&
        !hideConfirmationGuide &&
        tenantsUsingInfoBoxes.includes(appStore.organisationId) &&
        !!offers.filter((o) => o.state === OfferState.OPEN).length && (
          <div
            style={{ margin: appStore.integratedApp ? '1.5rem auto' : '1rem 3rem' }}
            data-testid='info-box'
          >
            <InfoBox
              icon={IconKeys.signable}
              title={text('confirmationGuideTitle')}
              color={'var(--custom-colorMain)'}
              content={text('confirmationGuideDescription')}
              additionalContent={
                <Typography
                  variant='subtitle2'
                  style={{
                    marginTop: '0.5rem',
                    fontSize: '15px',
                    color: '#294754',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={() => changeParams({ offersTermsAndConditions: 'true' })}
                >
                  {text('readTermsAndConditions')}
                </Typography>
              }
            />
          </div>
        )}
      {offers
        .sort((a, b) => {
          if ((a.isOpen && !b.isOpen) || (a.deadline && !b.deadline)) return -1;
          if ((b.isOpen && !a.isOpen) || (b.deadline && !a.deadline)) return 1;
          if (a.deadline && b.deadline) {
            const aDate = new Date(a.deadline);
            const bDate = new Date(b.deadline);

            return aDate < bDate ? -1 : 1;
          }
          return 1;
        })
        .map((offer) => {
          return (
            <Element key={offer.id} name={`offer${offer.id}`}>
              <OffersConfirmationDropdown
                offersStore={offersStore}
                openAtStart={offer.id === offersStore.selectedOffer ? true : false}
                offer={offer}
                key={offer.id}
              />
            </Element>
          );
        })}
    </>
  );
};

export default observer(OffersConfirmation);
