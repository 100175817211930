import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => {
  return createStyles({
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      '& > span': {
        margin: 'auto',
        paddingRight: '0.5em',
        textTransform: 'uppercase'
      },
      '& > div': {
        margin: 'auto',
        display: 'flex'
      }
    }
  });
});
