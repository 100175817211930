import { observable } from 'mobx';
import organisationCustomisation from '../../organisationCustomisations';

export interface Customisation {
  colorMain: string;
  colorHover: string;
  colorAccent: string;
  colorAccent2: string;
  colorAccent3: string;
  colorNotif1: string;
  colorNotif2: string;
  colorNotif3: string;
  colorNotif4: string;
  colorNavLinks?: string;
  colorLoadingBackground?: string;
  colorLoadingSpinner?: string;
  fontSrc?: string;
  fontLight?: string;
  fontNormal: string;
  fontMedium?: string;
  fontBold?: string;
}

const { gbDefaultCustomisation } = organisationCustomisation;

export default class OrganisationCustomisation implements Customisation {
  @observable public fontNormal = gbDefaultCustomisation.fontNormal;
  @observable public colorMain = gbDefaultCustomisation.colorMain;
  @observable public colorHover = gbDefaultCustomisation.colorHover;
  @observable public colorAccent = gbDefaultCustomisation.colorAccent;
  @observable public colorAccent2 = gbDefaultCustomisation.colorAccent2;
  @observable public colorAccent3 = gbDefaultCustomisation.colorAccent3;
  @observable public colorNotif1 = gbDefaultCustomisation.colorNotif1;
  @observable public colorNotif2 = gbDefaultCustomisation.colorNotif2;
  @observable public colorNotif3 = gbDefaultCustomisation.colorNotif3;
  @observable public colorNotif4 = gbDefaultCustomisation.colorNotif4;
  @observable public colorLoadingBackground = gbDefaultCustomisation.colorLoadingBackground;
  @observable public colorLoadingSpinner = gbDefaultCustomisation.colorLoadingSpinner;
  @observable public colorNavLinks = gbDefaultCustomisation.colorNavLinks;
  @observable public fontSrc?: string;
  @observable public fontLight?: string;
  @observable public fontMedium?: string;
  @observable public fontBold?: string;

  constructor(customisation: Customisation) {
    this.fontNormal = customisation.fontNormal || gbDefaultCustomisation.fontNormal;
    this.colorMain = customisation.colorMain || gbDefaultCustomisation.colorMain;
    this.colorHover = customisation.colorHover || gbDefaultCustomisation.colorHover;
    this.colorAccent = customisation.colorAccent || gbDefaultCustomisation.colorAccent;
    this.colorAccent2 = customisation.colorAccent2 || gbDefaultCustomisation.colorAccent2;
    this.colorAccent3 = customisation.colorAccent3 || gbDefaultCustomisation.colorAccent3;
    this.colorNotif1 = customisation.colorNotif1 || gbDefaultCustomisation.colorNotif1;
    this.colorNotif2 = customisation.colorNotif2 || gbDefaultCustomisation.colorNotif2;
    this.colorNotif3 = customisation.colorNotif3 || gbDefaultCustomisation.colorNotif3;
    this.colorNotif4 = customisation.colorNotif4 || gbDefaultCustomisation.colorNotif4;
    this.colorLoadingBackground =
      customisation.colorLoadingBackground || gbDefaultCustomisation.colorLoadingBackground;
    this.colorLoadingSpinner =
      customisation.colorLoadingSpinner || gbDefaultCustomisation.colorLoadingSpinner;
    this.colorNavLinks = customisation.colorNavLinks || gbDefaultCustomisation.colorNavLinks;
    this.fontSrc = customisation.fontSrc || gbDefaultCustomisation.fontSrc;
    this.fontLight = customisation.fontLight || gbDefaultCustomisation.fontLight;
    this.fontNormal = customisation.fontNormal || gbDefaultCustomisation.fontNormal;
    this.fontMedium = customisation.fontMedium || gbDefaultCustomisation.fontMedium;
    this.fontBold = customisation.fontBold || gbDefaultCustomisation.fontBold;
  }
}
