import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import FolderIcon from '../../components/Icons/folder.svg';
import { withRouter } from 'react-router';
import MessageModel from '../../store/models/Message';
import { FormattedTextSection } from '../../components/Styled/FormattedTextSection';
import { text } from '../../utils';

const MessageRow = styled.div`
  width: 100%;
  margin-bottom: 30px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface StyledMessageProps {
  isFromUser: boolean;
  mass: boolean;
  sending: boolean;
}
const StyledMessage = styled(FormattedTextSection)<StyledMessageProps>`
  max-width: ${({ mass }) => (mass ? '100%' : '50%')};
  align-self: ${({ isFromUser }) => (isFromUser ? 'flex-end' : 'flex-start')};
  color: ${({ sending }) => (sending ? 'lightgray' : 'inherit')};
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isFromUser }) => (isFromUser ? 'flex-end' : 'flex-start')};
  margin-right: ${({ isFromUser }) => (isFromUser ? '30px' : '0')};
`;

const MessageAuthor = styled.p`
  font-family: var(--custom-fontNameLight);
  font-weight: 500;
  margin-bottom: 5px;
`;

const MessageRecipient = styled.p`
  font-family: var(--custom-fontNameLight);
  font-weight: 500;
  font-size: 0.8em;
  margin-bottom: 5px;
`;

const MessageTime = styled.p`
  font-size: 14px;
`;

const MessageContent = styled.pre<StyledMessageProps>`
  font-size: 18px;
  margin: 10px 0 30px 0;
  font-family: var(--custom-fontNameLight);
  font-weight: 500;
  max-width: 100%;
  text-align: ${({ isFromUser }) => (isFromUser ? 'right' : 'left')};
`;

const Attachment = styled.div`
  padding: 20px;

  img {
    margin-right: 10px;
  }
`;

interface MessageProps {
  message: MessageModel;
  read: boolean;
  mass: boolean;
}

const Message = ({ message, read, mass }: MessageProps) => {
  let time;
  if (message.sendTime) {
    const date = new Date(0);
    date.setUTCSeconds(message.sendTime);
    time = date.toLocaleString('default', {
      hour12: false,
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  }

  useEffect(() => {
    if (read) message.receive();
  }, [read, message]);

  return (
    <MessageRow>
      <StyledMessage isFromUser={!!message.isFromUser} mass={mass} sending={message.sending}>
        <MessageAuthor>{message.userName}</MessageAuthor>
        {!!message.isFromUser && message.recipient && (
          <MessageRecipient>
            {`${text('recipientMessage')} ${message.recipient.name} (${message.apartmentName})`}
          </MessageRecipient>
        )}
        <MessageTime>{time}</MessageTime>
        <MessageContent isFromUser={!!message.isFromUser} mass={mass} sending={message.sending}>
          {message.content}
        </MessageContent>
        {message.attachmentFilePath && (
          <Attachment>
            <img src={FolderIcon} alt='folder-icon' />
            <a href={message.attachmentFilePath} target='_blank' rel='noopener noreferrer'>
              {message.attachmentFileDisplayName}
            </a>
          </Attachment>
        )}
      </StyledMessage>
    </MessageRow>
  );
};

export default (withRouter as any)(observer(Message));
