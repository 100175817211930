import { observable, action } from 'mobx';

class ModalStore {
  @observable public isOpen = false;

  @action public changeOpen = (isOpen: boolean) => {
    this.isOpen = isOpen;
  };
}

const modalStore = new ModalStore();

export default modalStore;
