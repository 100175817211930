import React from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import arrowLeft from '../Icons/arrow_left.svg';
import { mediaSmallMax } from '../Styled/media';

const StyledThemeSlider = styled.div`
  min-width: 100%;
  height: 3.75rem;

  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: stretch;

  @media ${mediaSmallMax} {
    position: absolute;
    top: 0.5rem;
    left: 0;
    z-index: 1;
  }
`;

const MiddleText = styled.p`
  font-weight: 400;
  font-family: var(--custom-fontName);
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mediaSmallMax} {
    opacity: 0;
    pointer-events: none;
  }
`;

const StyledArrow = styled.img<{ rotated?: boolean }>`
  transform: ${({ rotated }) => (rotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const ArrowContainer = styled.button`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  transition: all 0.2s ease-in-out;
  outline: 0;

  &:hover {
    background-color: var(--custom-colorHover);

    @media ${mediaSmallMax} {
      background: none;
    }
  }
`;

const Arrow = (props: { alt: string; rotated?: boolean; [x: string]: any }) => (
  <StyledArrow src={arrowLeft} {...props} />
);

interface ThemeSliderProps {
  onLeft: () => void;
  onRight: () => void;
}

const ThemeSlider = ({ onLeft, onRight }: ThemeSliderProps) => (
  <StyledThemeSlider>
    <ArrowContainer onClick={onLeft}>
      <Arrow alt='slider-left-arrow' />
    </ArrowContainer>
    <MiddleText>{text('browseOtherThemes')}</MiddleText>
    <ArrowContainer onClick={onRight}>
      <Arrow alt='slider-right-arrow' rotated />
    </ArrowContainer>
  </StyledThemeSlider>
);

export default ThemeSlider;
