import React from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import {
  Header,
  HeaderContent,
  HeaderContentWrapper,
  HeaderRow,
  HeaderLogo,
  HeaderBg
} from './headerStyledComponents';
import appStore from '../../store/AppStore';
import { mediaSmallMax } from '../Styled/media';
import { observer } from 'mobx-react-lite';
import { gbDefaultLogo } from '../../store/models/TenantSettings';

const StyledFrontPageHeader = styled.h1`
  color: white;
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media ${mediaSmallMax} {
    font-size: 24px;
    line-height: 26px;
    margin-top: 1rem;
    height: 8rem;
  }
`;

const StyledHeaderContentWrapper = styled(HeaderContentWrapper)`
  min-height: 25rem;
  justify-content: flex-start;
`;

const FrontPageHeader = () => (
  <Header>
    <HeaderBg src={appStore.headerImg} alt='header' />
    <HeaderContent>
      <StyledHeaderContentWrapper>
        <HeaderRow>
          {appStore.tenantSettings.logoUrls?.organisationLogoLightUrl &&
            !appStore.tenantSettings.customerJourney2_showHeader && (
              <HeaderLogo
                src={appStore.tenantSettings.logoUrls?.organisationLogoLightUrl}
                alt='header-logo'
                onError={(e) => {
                  if (e.currentTarget.src !== gbDefaultLogo) {
                    e.currentTarget.src = gbDefaultLogo;
                  }
                }}
              />
            )}
        </HeaderRow>
        <StyledFrontPageHeader>{text('firstHeaderText')}</StyledFrontPageHeader>
        <p className='font-normal'>{text('firstHeaderDesc')}</p>
      </StyledHeaderContentWrapper>
    </HeaderContent>
  </Header>
);

export default observer(FrontPageHeader);
