import AttachmentInterface from '../store/interfaces/AttachmentInterface';
import UserLevelRightInterface from '../store/interfaces/UserLevelRightInterface';
import { SectionInterface } from '../store/interfaces/SectionInterface';
import AttachmentDataInterface from '../store/interfaces/AttachmentDataInterface';
import LegacyAttachmentInterface from '../store/interfaces/LegacyAttachmentInterface';
import GbApiRepository from './GbApiRepository';
import AttachmentCollectionInterface from '../store/interfaces/AttachmentCollectionInterface';
import MessageAttachmentInterface from '../store/interfaces/MessageAttachmentInterface';
import OfferAttachmentInterface from '../store/interfaces/OfferAttachmentInterface';
import ProjectAttachmentInterface from '../store/interfaces/ProjectAttachmentInterface';
import ApartmentAttachmentInterface from '../store/interfaces/ApartmentAttachmentInterface';
import { text } from '../utils';
import appStore from '../store/AppStore';
import alertStore from '../store/AlertStore';
import ItemTypeEnum from '../store/enums/ItemTypeEnum';
import attachmentFactory from '../store/models/AttachmentFactory';

interface SaveAttachmentSignature {
  itemType: string;
  itemTypeId: number | null;
  attachments: AttachmentInterface[];
  userLevelRights: Record<string, UserLevelRightInterface>;
  sections?: SectionInterface[];
  apartmentIds?: number[];
}

interface UpdateSignature {
  itemType: string;
  itemTypeId: number;
  attachment: AttachmentInterface;
}

type AttachmentFilterType =
  | Partial<MessageAttachmentInterface>
  | Partial<OfferAttachmentInterface>
  | Partial<ProjectAttachmentInterface>
  | Partial<ApartmentAttachmentInterface>;

function getAttachmentRequestQueryParams(
  start?: number,
  limit?: number,
  filter?: AttachmentFilterType
) {
  const queryParams = new URLSearchParams();
  if (start !== undefined) {
    queryParams.append('start', String(start));
  }
  if (limit !== undefined) {
    queryParams.append('limit', String(limit));
  }
  if (filter) {
    queryParams.append('filter', JSON.stringify(filter));
  }

  return queryParams.toString().length > 0 ? '?' + queryParams.toString() : '';
}

class AttachmentRepository extends GbApiRepository {
  public readonly POLLING_ZIP_ATTACHMENT_INTERVAL = 10000;
  public getLegacyAttachmentsByApartmentId = async (apartmentId: number) => {
    const { data: body } = await this.axios.get(
      `/api/v1/private/apartments/${apartmentId}/attachments`
    );
    const attachments = body.data;
    const projectAttachments = attachments.project_attachments.map(
      (legacyAttachmentData: LegacyAttachmentInterface) =>
        attachmentFactory.createFromLegacyData(legacyAttachmentData)
    );
    const apartmentAttachments = attachments.apartment_attachments.map(
      (legacyAttachmentData: LegacyAttachmentInterface) =>
        attachmentFactory.createFromLegacyData(legacyAttachmentData)
    );
    return { projectAttachments, apartmentAttachments };
  };

  public saveAttachments = async ({
    itemType,
    itemTypeId,
    attachments,
    userLevelRights,
    sections,
    apartmentIds
  }: SaveAttachmentSignature) => {
    const { tenantId, organisationId, projectId } = appStore;
    const formData = new FormData();
    const attachmentsData: Array<Partial<AttachmentInterface>> = attachments.map(
      (attachment: AttachmentInterface) => {
        formData.append('file[]', attachment.file!);
        const { file, stream, ...attachmentData } = { ...attachment };

        return { ...attachmentData, originalName: attachment.file?.name };
      }
    );

    formData.append('userLevelRights', JSON.stringify(userLevelRights));
    formData.append('attachments', JSON.stringify(attachmentsData));
    if (sections) {
      formData.append('sections', JSON.stringify(sections));
    }
    if (apartmentIds && apartmentIds.length > 0) {
      formData.append('itemTypeIds', JSON.stringify(apartmentIds));
    }
    let url = `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${itemType}`;
    if (itemTypeId) {
      url += `/${itemTypeId}`;
    }

    const { data: body } = await this.axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return body.data.map((attachmentData: AttachmentDataInterface) => {
      return attachmentFactory.createFromAttachmentData(attachmentData);
    });
  };

  public delete = async (attachment: AttachmentInterface) => {
    const { tenantId, organisationId, projectId } = appStore;
    const { data: body } = await this.axios.post(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${attachment.id}/delete`,
      {
        attachment: {
          id: attachment.id,
          uuidFilename: attachment.uuidFilename
        }
      }
    );

    return attachmentFactory.createFromAttachmentData(body.data);
  };

  public update = async ({ itemType, itemTypeId, attachment }: UpdateSignature) => {
    const { tenantId, organisationId, projectId } = appStore;
    const { data: body } = await this.axios.put(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${itemType}/${itemTypeId}`,
      attachment
    );

    return attachmentFactory.createFromAttachmentData(body.data);
  };

  public getAttachmentsByItemType = async ({
    itemType,
    start,
    limit,
    filter
  }: {
    itemType: string;
    start?: number;
    limit?: number;
    filter?: AttachmentFilterType;
  }) => {
    const { tenantId, organisationId, projectId } = appStore;
    const { data: body } = await this.axios.get(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${itemType}${getAttachmentRequestQueryParams(
        start,
        limit,
        filter
      )}`
    );

    return {
      data: body.data.map((attachmentData: AttachmentDataInterface) => {
        return attachmentFactory.createFromAttachmentData(attachmentData);
      }),
      limit: body.limit,
      start: body.start,
      total: body.total
    } as AttachmentCollectionInterface;
  };

  public getAttachmentsByItemTypeId = async ({
    itemTypeId,
    itemType,
    start,
    limit
  }: {
    itemTypeId: number;
    itemType: string;
    start?: number;
    limit?: number;
  }) => {
    const { tenantId, organisationId, projectId } = appStore;
    const { data: body } = await this.axios.get(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${itemType}/${itemTypeId}${getAttachmentRequestQueryParams(
        start,
        limit
      )}`
    );

    return {
      data: body.data.map((attachmentData: AttachmentDataInterface) => {
        return attachmentFactory.createFromAttachmentData(attachmentData);
      }),
      limit: body.limit,
      start: body.start,
      total: body.total
    } as AttachmentCollectionInterface;
  };

  public getAllApartmentAttachmentsByProjectId = async ({
    start,
    limit
  }: {
    start?: number;
    limit?: number;
  }) => {
    const { tenantId, organisationId, projectId } = appStore;
    const { data: body } = await this.axios.get(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/apartment${getAttachmentRequestQueryParams(
        start,
        limit
      )}`
    );

    return {
      data: body.data.map((attachmentData: AttachmentDataInterface) => {
        return attachmentFactory.createFromAttachmentData(attachmentData);
      }),
      limit: body.limit,
      start: body.start,
      total: body.total
    } as AttachmentCollectionInterface;
  };

  public deleteAttachmentsByItemTypeId = async ({
    itemTypeId,
    itemType
  }: {
    itemTypeId: number;
    itemType: string;
  }) => {
    const { tenantId, organisationId, projectId } = appStore;
    const resp = await this.axios.delete(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${itemType}/${itemTypeId}`
    );
    if (resp.status !== 200) alertStore.show(text('errors.deleteAttachmentFailed'));
    return resp.data;
  };

  public getZipAttachments = async () => {
    const { tenantId, organisationId, projectId } = appStore;
    const resp = await this.axios.get(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/zip`,
      {
        responseType: 'arraybuffer'
      }
    );
    if (resp.status !== 200) {
      alertStore.show(text('errors.zipAttachmentFailed'));
    }
    return resp.data;
  };

  public downloadZipAttachments = async (downloadUrl: string) => {
    const resp = await this.axios.get(downloadUrl, {
      responseType: 'arraybuffer'
    });
    if (resp.status !== 200) {
      alertStore.show(text('errors.zipAttachmentFailed'));
    }
    return resp.data;
  };

  public pollZipAttachments = async (pollingUrl: string) => {
    const resp = await this.axios.get(pollingUrl);
    if (resp.status !== 200) {
      alertStore.show(text('errors.zipAttachmentFailed'));
    }
    return resp.data.data.job;
  };

  public getZipAttachmentsAsync = async ({
    apartmentId,
    itemType
  }: {
    itemType?: ItemTypeEnum;
    apartmentId?: number;
  } = {}) => {
    const { tenantId, organisationId, projectId } = appStore;
    let attachmentTypeUrl = ``;
    switch (itemType) {
      case ItemTypeEnum.APARTMENT:
        attachmentTypeUrl = `${ItemTypeEnum.APARTMENT}/${apartmentId}/`;
        break;
      case ItemTypeEnum.PROJECT:
        attachmentTypeUrl = `${ItemTypeEnum.PROJECT}/${projectId}/`;
        break;
      default:
        break;
    }
    const resp = await this.axios.get(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/${attachmentTypeUrl}zip-async`
    );
    if (resp.status !== 200) {
      alertStore.show(text('errors.zipAttachmentFailed'));
    }
    return resp.data.data.job;
  };

  public getDownloadHttpLink(downloadUrl: string) {
    return this.baseUrl + downloadUrl;
  }
}

const attachmentRepository = new AttachmentRepository();
export default attachmentRepository;
