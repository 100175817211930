import { observable } from 'mobx';
import AttachmentInterface from '../interfaces/AttachmentInterface';
import AttachmentDecorator from './AttachmentDecorator';

class ApartmentAttachmentDecorator extends AttachmentDecorator {
  @observable public apartmentId: number;

  public constructor(
    attachment: AttachmentInterface,
    source: {
      apartmentId: number;
    }
  ) {
    super(attachment);
    this.apartmentId = source.apartmentId;
  }
}

export default ApartmentAttachmentDecorator;
