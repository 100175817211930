import styled from 'styled-components/macro';

const CircleBadge = styled.div<{ size?: number }>`
  --circle-size: ${({ size }) => (size ? `${size}px` : '22px')};

  min-width: var(--circle-size);
  min-height: var(--circle-size);
  max-width: var(--circle-size);
  max-height: var(--circle-size);
  overflow: hidden;
  text-align: center;
  font-size: 15px;
  line-height: var(--circle-size);
  color: white;
  border-radius: 100%;
  background-color: var(--custom-colorNotif1);
  font-family: var(--custom-fontNameMedium);
`;

export default CircleBadge;
