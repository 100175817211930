import { Phase as PhaseInterface } from '../interfaces/Phase';
import { observable, computed } from 'mobx';
import { Section } from '../interfaces/Section';

export default class Phase implements PhaseInterface {
  @observable public icon: string;
  @observable public id: number;
  @observable public name: string;
  @observable public orderinproject: number;
  @observable public projectId: number;
  @observable public sections: Section[];

  constructor(data: Phase) {
    this.icon = data.icon;
    this.id = data.id;
    this.name = data.name;
    this.orderinproject = data.orderinproject;
    this.projectId = data.projectId;
    this.sections = data.sections;
  }

  @computed
  public get allSections(): Section[] {
    const result: Section[] = [];

    const flattenSections = (e: Section | Phase) => {
      if (e.sections && !!e.sections.length) {
        e.sections.forEach((s) => {
          const sectionData = { ...s };
          // @ts-ignore
          delete sectionData.sections;
          result.push(sectionData);
          flattenSections(s);
        });
      }
    };
    flattenSections(this);

    return result;
  }

  @computed
  public get hasAttachments(): boolean {
    return this.allSections.some((s) => s.attachments?.length || s.signableAttachments?.length);
  }

  @computed
  public get hasUnsignedDocuments(): boolean {
    return this.allSections.some((s) => s.signableAttachments?.some((a) => a.signable));
  }

  @computed
  public get hasContacts(): boolean {
    return this.allSections.some((s) => s.contacts);
  }
}
