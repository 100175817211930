import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import InfoIcon from '../../components/Icons/Info';
import { mediaSmallMax } from '../Styled/media';

const ConfirmationGuidance = styled.div`
  white-space: pre-line;
  line-height: 1.5rem;
  border: 0.5px solid rgba(0, 159, 218, 0.8);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  @media ${mediaSmallMax} {
    flex-direction: column;
    margin: 1rem 0rem;
  }
`;

const BoxTexts = styled.div`
  padding: 1rem;
`;

const TextBoxTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0.5rem;
`;

const TextBoxDescription = styled.p`
  font-size: 15px;
  color: #294754;
`;

const IconBox = styled.div`
  display: flex;
  padding: 1rem;
  background: rgba(0, 159, 218, 0.8);
`;

const StyledInfoIcon = styled(InfoIcon)`
  fill: white;
  margin: auto;
  width: 50px;
`;

const TermsAndConditionsLink = styled.p`
  font-size: 15px;
  color: #294754;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;

interface InfoBoxProps {
  styles?: object;
  title?: string | undefined;
  description: string;
  link?: string;
  onClickLink?: () => void;
}
const InfoBox = ({ styles, title, description, link, onClickLink }: InfoBoxProps) => {
  return (
    <ConfirmationGuidance style={styles}>
      <IconBox>
        <StyledInfoIcon />
      </IconBox>
      <BoxTexts>
        {title && <TextBoxTitle>{title}</TextBoxTitle>}
        <TextBoxDescription>{description}</TextBoxDescription>
        {link && <TermsAndConditionsLink onClick={onClickLink}>{link}</TermsAndConditionsLink>}
      </BoxTexts>
    </ConfirmationGuidance>
  );
};

export default observer(InfoBox);
