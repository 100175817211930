import React from 'react';
import styled from 'styled-components/macro';
// eslint-disable-next-line
export default (props: any) => (
  <svg
    width='20'
    height='26'
    viewBox='0 0 20 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M17.4481 13.1921H5.50169V8.41351C5.50169 4.89929 6.76602 2.04212 10.2802 2.04212C13.7945 2.04212 15.0588 4.89929 15.0588 8.41351V13.3912C15.0588 13.7197 15.3276 13.9885 15.6561 13.9885H16.0543C16.3829 13.9885 16.6516 13.7197 16.6516 13.3912V8.48818C16.6516 4.08793 14.7054 0.464198 10.3051 0.449265C5.89991 0.434332 3.90884 4.01327 3.90884 8.41351V13.1921H3.11242C1.79334 13.1921 0.723145 14.2623 0.723145 15.5813V23.5456C0.723145 24.8647 1.79334 25.9349 3.11242 25.9349H17.4481C18.7671 25.9349 19.8373 24.8647 19.8373 23.5456V15.5813C19.8373 14.2623 18.7671 13.1921 17.4481 13.1921ZM18.2445 23.5456C18.2445 23.9836 17.8861 24.342 17.4481 24.342H3.11242C2.67439 24.342 2.31599 23.9836 2.31599 23.5456V15.5813C2.31599 15.1433 2.67439 14.7849 3.11242 14.7849H17.4481C17.8861 14.7849 18.2445 15.1433 18.2445 15.5813V23.5456Z' />
  </svg>
);

export const StyledLockContainer = styled.div`
  min-width: 2rem;
  min-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.45);
  position: absolute;
  bottom: 0.7rem;
  left: 0.7rem;
`;
