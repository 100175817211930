import React from 'react';
import styled from 'styled-components/macro';
import appStore from '../../store/AppStore';
import themeStore from '../../store/ThemeStore';
import ThemeSet from '../../store/models/ThemeSet';
import CheckSolid from '../Icons/CheckSolid';
import { text, limitStringLength } from '../../utils';
import { mediaSmallMax } from '../Styled/media';
import classnames from 'classnames';

export const ThemeSetName = styled.p`
  font-size: 22px;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  word-break: break-word;

  @media ${mediaSmallMax} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ThemeSetNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-lightGray);
  width: inherit;
  overflow: hidden;
`;

const NoSelectionText = styled.div`
  font-size: 1rem;
  align-self: center;
  justify-self: center;
`;

const ImgThumb = styled.div<{ bg: string }>`
  position: relative;
  overflow: hidden;
  background: center / cover no-repeat url('${({ bg }) => bg}');
`;

const StyledThemeSetCard = styled.div`
  // margin: 1.25rem 1rem 2.4rem 1rem;
  position: relative;
  height: 100%;
  min-width: 20rem;
  // max-width: 20rem;
  cursor: pointer;
  animation: theme-card-appear 0.5s ease-in-out;
  border: 4px solid rgba(255, 255, 255, 0);
  transition: all 0.2s ease-in-out;
  font-size: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 3fr 1fr;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  @keyframes theme-card-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.selected {
    border: 4px solid var(--custom-colorAccent);
    padding: 0px;

    ${ThemeSetNameContainer}, ${ImgThumb},
    &:hover ${ThemeSetNameContainer}, &:hover ${ImgThumb} {
      background-color: var(--custom-colorAccent);
      color: white;
    }
  }

  &:hover {
    border: 4px solid var(--custom-colorHover);
    padding: 0px;

    ${ThemeSetNameContainer}, ${ImgThumb} {
      background-color: var(--custom-colorHover);
    }
  }

  ${ThemeSetNameContainer}, ${ImgThumb} {
    transition: all 0.2s ease-in-out;
  }

  @media ${mediaSmallMax} {
    min-width: 13rem;
    max-width: 13rem;
    grid-template-rows: 9rem 3rem;
    margin: 0.5rem;
  }
`;

const ThemeNames = styled.p`
  font-size: 18px;
  width: 100%;
  padding: 2px 6px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${mediaSmallMax} {
    padding: 0;
    font-size: 12px;
    line-height: 14px;
  }
`;

const StyledCheck = styled(CheckSolid)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  fill: var(--custom-colorAccent);

  background-color: var(--color-lightGray);
  border-radius: 100%;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;

  @media ${mediaSmallMax} {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

interface ThemeSetDeadlineProps {
  highlight?: boolean;
  noSelection?: boolean;
}
const ThemeSetDeadline = styled.p<ThemeSetDeadlineProps>`
  background-color: ${({ highlight }) =>
    highlight ? 'var(--custom-colorNotif1)' : 'var(--custom-colorAccent)'};
  width: 100%;
  color: white;
  font-size: 14px;
  height: 30px;
  font-weight: 400;
  padding: 4px 8px;
  font-family: var(--custom-fontName);
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: calc(20% + 10px);

  @media ${mediaSmallMax} {
    padding: 2px 4px;
    font-size: 12px;
    line-height: 13px;
    bottom: calc(3rem + 5px);
    height: 18px;
  }
`;

interface ThemeSetCardProps
  extends Pick<
    ThemeSet,
    'id' | 'name' | 'showTick' | 'deadlineAsDate' | 'selectedThemes' | 'image' | 'canBeIgnored'
  > {
  selected: boolean;
  scroll?: boolean;
  onClick?: () => void;
}

const ThemeSetCard = ({
  selected,
  id,
  showTick,
  deadlineAsDate,
  selectedThemes,
  name,
  image,
  scroll,
  onClick,
  canBeIgnored
}: ThemeSetCardProps) => {
  return (
    <StyledThemeSetCard
      className={classnames({
        selected
      })}
      key={id}
      onClick={() => {
        themeStore.selectThemeSet(id, scroll);
        if (onClick) {
          onClick();
        }
      }}
    >
      {showTick && <StyledCheck />}
      {canBeIgnored && !selectedThemes.length ? (
        <NoSelectionText>{text('noSelectionText')}</NoSelectionText>
      ) : (
        <ImgThumb bg={image} />
      )}
      {deadlineAsDate && (
        <ThemeSetDeadline>{text('deadline', { date: deadlineAsDate })}</ThemeSetDeadline>
      )}
      <ThemeSetNameContainer>
        <ThemeSetName>{limitStringLength(name, 50, 48)}</ThemeSetName>
        {appStore.tenantSettings.customerJourney2_usePackageSets && (
          <ThemeNames>
            {limitStringLength(selectedThemes.map((t) => t.name).join(', '), 50, 48)}
          </ThemeNames>
        )}
      </ThemeSetNameContainer>
    </StyledThemeSetCard>
  );
};

export default ThemeSetCard;
