import { Typography } from '@material-ui/core';
import React from 'react';
import { changeParams } from '../../store/RoutingStore';
import { text } from '../../utils';
import MarketplaceLogo from '../../images/MarketplaceLogo';
import GBuilderLogo100w from '../../images/GBuilderLogo100w';
interface MarketplaceInformationProps {
  showMail?: boolean;
}

const MarketplaceInformation = ({ showMail }: MarketplaceInformationProps) => {
  return (
    <>
      <Typography
        variant='subtitle2'
        style={{
          marginTop: '0.5rem',
          fontSize: '15px',
          color: '#294754',
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        onClick={() => changeParams({ marketplaceTermsAndConditions: 'true' })}
      >
        {text('marketplace.readTermsAndConditions')}
      </Typography>
      <Typography
        variant='subtitle2'
        style={{
          marginTop: '0.5rem',
          fontSize: '15px',
          color: '#294754',
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        onClick={() => changeParams({ marketplaceFAQ: 'true' })}
      >
        {text('marketplace.faq')}
      </Typography>
      {showMail && (
        <Typography
          variant='subtitle2'
          style={{
            marginTop: '0.5rem',
            fontSize: '15px',
            color: '#294754',
            cursor: 'pointer'
          }}
        >
          {'Asiakaspalvelu: '}{' '}
          <a href='mailto:support@gbhomebook.com?subject = Feedback&body = Message'>
            <span>support@gbhomebook.com</span>
          </a>
        </Typography>
      )}

      <div style={{ float: 'right', display: 'flex', gap: '1rem' }}>
        <Typography
          variant='subtitle2'
          style={{
            marginTop: '0.5rem',
            fontSize: '15px',
            color: '#294754',
            fontStyle: 'italic'
          }}
          onClick={() => changeParams({ marketplaceFAQ: 'true' })}
        >
          Palvelun tarjoaa
        </Typography>
        <GBuilderLogo100w
          onClick={() => window.open('https://www.gbuilder.com/')}
          style={{ margin: 'auto', maxWidth: '100px', height: 'fit-content', cursor: 'pointer' }}
          alt={'GBuilder logo'}
        />
        <MarketplaceLogo
          onClick={() => window.open('https://www.verkkokauppa.com/fi/etusivu')}
          style={{
            margin: 'auto',
            maxWidth: '140px',
            height: 'fit-content',
            maxHeight: '23px',
            cursor: 'pointer'
          }}
          alt={'Verkkokauppa logo'}
        />
      </div>
    </>
  );
};

export default MarketplaceInformation;
