import React from 'react';
import styled from 'styled-components/macro';
import { limitStringLength, text } from '../../utils';
import appStore from '../../store/AppStore';

const StyledThemeCard = styled.div<{ integratedApp?: boolean }>`
  position: relative;
  cursor: pointer;
  animation: theme-card-appear 0.5s ease-in-out;
  border: 4px solid rgba(255, 255, 255, 0);
  transition: border 0.2s ease-in-out;
  font-size: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 11rem 3.2rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  @keyframes theme-card-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.selected {
    border: ${({ integratedApp }) =>
      integratedApp
        ? `4px solid var(--custom-themeSettingsPrimary)`
        : '4px solid var(--custom-colorAccent)'};
    padding: 0px;

    div,
    &:hover div {
      background-color: ${({ integratedApp }) =>
        integratedApp ? `var(--custom-themeSettingsPrimary)` : 'var(--custom-colorAccent)'};
      color: ${appStore.organisationId === 'srv' ? '#212427' : 'white'};
    }
  }

  &:hover:not(.dimmed) {
    border: 4px solid var(--custom-colorHover);
    padding: 0px;
    color: ${appStore.organisationId === 'srv' && 'white'};

    div {
      background-color: var(--custom-colorHover);
    }
  }

  div {
    transition: background-color 0.2s ease-in-out;
  }

  &.dimmed {
    pointer-events: none;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: ' ';
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  /* selected background background-color: var(--custom-colorAccent); */
`;

export const ThemeCardName = styled.div`
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-lightGray);
`;

const ImgThumb = styled.div<{ bg: string }>`
  position: relative;
  transition: none;
  overflow: hidden;
  background: center / cover no-repeat url('${({ bg }) => bg}');
`;

interface ThemeDeadlineProps {
  highlight?: boolean;
  noSelection?: boolean;
}
const ThemeDeadline = styled.p<ThemeDeadlineProps>`
  background-color: ${({ highlight }) =>
    highlight ? 'var(--custom-colorNotif1)' : 'var(--custom-colorAccent)'};
  width: 100%;
  color: white;
  font-size: 14px;
  height: 30px;
  font-weight: 400;
  padding: 4px 8px;
  font-family: var(--custom-fontName);
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: calc(3.2rem + 5px);
`;

interface ThemeCardProps {
  name: string;
  image: string;
  deadline?: string;
  [x: string]: any;
}

const ThemeCard = ({ name, image, deadline, ...props }: ThemeCardProps) => {
  return (
    <StyledThemeCard {...props} integratedApp={appStore.integratedApp}>
      {deadline && <ThemeDeadline>{text('deadline', { date: deadline })}</ThemeDeadline>}
      <ImgThumb bg={image} />
      <ThemeCardName>{limitStringLength(name, 70, 68)}</ThemeCardName>
    </StyledThemeCard>
  );
};

export default ThemeCard;
