import React from 'react';
import { observer } from 'mobx-react-lite';
import lightBoxStore from '../../store/LightBoxStore';
import Backdrop from '../Backdrop/Backdrop';
import styled from 'styled-components/macro';
import ArrowRight from '../Icons/ArrowRight';
import CircleButton from '../Button/CircleButton';
import { ResponsiveCloseIcon } from '../Icons/Close';
import { Carousel } from 'react-responsive-carousel';
import { mediaSmallMax } from '../Styled/media';
import { EventCategory } from '../../services/EventCategory';
import { EventAction } from '../../services/EventAction';
import { useTrackers } from '../../hooks/useTrackers';

interface ButtonProps {
  rotated?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  align-self: center;
  width: 5rem;
  height: 5rem;

  svg {
    transform: ${({ rotated }) => (rotated ? 'rotate(180deg)' : 'none')};
  }
  outline: none;
`;

const carouselTopMargin = '--lightBox-carouselTopMargin';
const arrowsContainerHeight = '--lightBox-arrowsContainerHeight';
const carouselHeight = `--lightBox-carouselHeight`;

const StyledBackdrop = styled(Backdrop)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  ${carouselTopMargin}: 5vh;
  ${arrowsContainerHeight}: 15vh;
  ${carouselHeight}: calc(100vh - var(${carouselTopMargin}) - var(${arrowsContainerHeight}));

  @media ${mediaSmallMax} {
    ${arrowsContainerHeight}: 10vh;
  }
`;

const StyledCircleButton = styled(CircleButton)`
  position: absolute;
  z-index: 100;
  top: 5vh;
  right: 5vh;
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 97.5vw;
  max-height: var(${carouselHeight});
  overflow: hidden;

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: unset;
      max-height: var(${carouselHeight});
      max-width: 97.5vw;
    }
  }

  .carousel .slide {
    background: none;
  }
`;

const ArrowsContainer = styled.div`
  width: 97.5vw;
  max-height: var(${arrowsContainerHeight});
  height: var(${arrowsContainerHeight});
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media ${mediaSmallMax} {
    justify-content: center;
  }
`;

const LightBox = () => {
  const { triggerEvent } = useTrackers();
  const onPreviousClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    triggerEvent({
      action: EventAction.BROWSE_CAROUSEL_IMAGES,
      category: EventCategory.USER,
      label: 'previous'
    });
    lightBoxStore.previous();
  };

  const onNextClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    triggerEvent({
      action: EventAction.BROWSE_CAROUSEL_IMAGES,
      category: EventCategory.USER,
      label: 'next'
    });
    lightBoxStore.next();
  };

  return lightBoxStore.isOpen && lightBoxStore.images.length ? (
    <StyledBackdrop>
      <StyledCircleButton
        onClick={() => {
          triggerEvent({
            category: EventCategory.USER,
            action: EventAction.CLOSE_LIGHTBOX
          });
          lightBoxStore.close();
        }}
      >
        <ResponsiveCloseIcon />
      </StyledCircleButton>

      <CarouselContainer>
        <Carousel
          selectedItem={lightBoxStore.shownImageIndex}
          showThumbs={false}
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          centerSlidePercentage={30}
          useKeyboardArrows={true}
        >
          {lightBoxStore.images.map((image) => {
            return (
              <div key={image}>
                <img src={image} alt={image} onClick={(e: any) => e.stopPropagation()} />
              </div>
            );
          })}
        </Carousel>
      </CarouselContainer>
      {lightBoxStore.images.length > 1 && (
        <ArrowsContainer>
          <StyledButton rotated onClick={onPreviousClick}>
            <ArrowRight fill='white' />
          </StyledButton>
          <StyledButton onClick={onNextClick}>
            <ArrowRight fill='white' />
          </StyledButton>
        </ArrowsContainer>
      )}
    </StyledBackdrop>
  ) : null;
};

export default observer(LightBox);
