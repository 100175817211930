import { observable } from 'mobx';

export interface OrganisationSettingsInterface {
  organisationLogoLight?: string;
  organisationLogoDark?: string;
  organisationLogoLightMediumThumb?: string;
  organisationLogoDarkMediumThumb?: string;
  organisationLogoLightThumb?: string;
  organisationLogoDarkThumb?: string;

  organisationLongName?: string;
  organisationName?: string;
}

export const gbDefaultOrganisationSettings: OrganisationSettingsInterface = {
  organisationLogoLight:
    'https://cdn.images.gbuilder.com/thumbor/IYGw-wT1EdpEW73IHsvZ52khkEg=/fit-in/350x350/center/middle/https://gb19.blob.core.windows.net/logo/GBGreyHorizSpace.png',
  organisationLogoDark:
    'https://cdn.images.gbuilder.com/thumbor/IYGw-wT1EdpEW73IHsvZ52khkEg=/fit-in/350x350/center/middle/https://gb19.blob.core.windows.net/logo/GBGreyHorizSpace.png',
  organisationLongName: 'GroupBuilder',
  organisationName: 'GBuilder'
};

export default class OrganisationSettings {
  @observable public organisationLogoLight = gbDefaultOrganisationSettings.organisationLogoLight;
  @observable public organisationLogoDark = gbDefaultOrganisationSettings.organisationLogoDark;
  @observable public organisationLongName = gbDefaultOrganisationSettings.organisationLongName;
  @observable public organisationName = gbDefaultOrganisationSettings.organisationName;

  constructor(settings: OrganisationSettingsInterface) {
    this.organisationLogoLight =
      settings.organisationLogoLight ||
      settings.organisationLogoLightThumb ||
      gbDefaultOrganisationSettings.organisationLogoLight;
    this.organisationLogoDark =
      settings.organisationLogoDark ||
      settings.organisationLogoLight ||
      settings.organisationLogoLightThumb ||
      gbDefaultOrganisationSettings.organisationLogoDark ||
      gbDefaultOrganisationSettings.organisationLogoLight;
    this.organisationLongName =
      settings.organisationLongName ||
      settings.organisationName ||
      gbDefaultOrganisationSettings.organisationLongName;
    this.organisationName =
      settings.organisationName || gbDefaultOrganisationSettings.organisationName;
  }
}
