import { useEffect } from 'react';
import appStore from '../store/AppStore';

const useSetupFavIcon = (): void => {
  useEffect(() => {
    if (appStore.tenantSettings.themeCustomizationSettings?.assets.favIcon) {
      let link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = appStore.tenantSettings.themeCustomizationSettings?.assets.favIcon;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.tenantSettings.themeCustomizationSettings]); //eslint-disable
};

export default useSetupFavIcon;
