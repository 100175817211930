import { PaletteType } from '@groupbuilderoy/gb-components-library';
import { useEffect, useState } from 'react';
import appStore from '../store/AppStore';

export default function useGetCustomizationSettings() {
  const [parsedSettings, setParsedSettings] = useState<PaletteType>();
  const [assets, setAssets] = useState<{
    logo: string;
    headerImg: string;
  }>();
  // const [fonts, setFonts] = useState<ThemeFonts>();
  const customizationSettings = appStore.tenantSettings?.themeCustomizationSettings;

  useEffect(() => {
    if (customizationSettings) {
      setParsedSettings({
        primary: customizationSettings?.palette?.main || '#B7835F',
        secondary: customizationSettings?.palette?.secondary || '#EFEEE4',
        navBarPrimary: customizationSettings?.palette?.navigationBar || '#05061B',
        navBarSecondary: customizationSettings?.palette?.navigationBarText || '#F2F4F7',
        sectionsHeader: customizationSettings.palette?.sectionHeaderText || '#000000de'
      });
      setAssets(customizationSettings.assets);
      // setFonts(customizationSettings.fonts);
    }
  }, [customizationSettings]);

  return { parsedSettings, assets };
}
