import React from 'react';
import styled from 'styled-components/macro';
import themeStore from '../../store/ThemeStore';
import ThemeSetCard from './ThemeSetCard';

const ThemeSetsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ThemeSetCardWrapper = styled.div`
  margin: 1.25rem 1rem 2.4rem 1rem;
`;

const ThemeSets = () => {
  return (
    <ThemeSetsContainer>
      {themeStore.themeSets.map((ts, i) => (
        <ThemeSetCardWrapper key={ts.id}>
          <ThemeSetCard
            {...ts}
            image={ts.image}
            showTick={ts.showTick}
            deadlineAsDate={ts.deadlineAsDate}
            selectedThemes={ts.selectedThemes}
            selected={!!themeStore.selectedThemeSet && ts.id === themeStore.selectedThemeSet.id}
            scroll={true}
          />
        </ThemeSetCardWrapper>
      ))}
    </ThemeSetsContainer>
  );
};

export default ThemeSets;
