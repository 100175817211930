import { widthLarge, widthSmall, widthMedium, widthXlarge } from './breakpoints';

export const mediaSmallMin = `screen and (min-width: ${widthSmall}px)`;
export const mediaMediumMin = `screen and (min-width: ${widthMedium}px)`;
export const mediaLargeMin = `screen and (min-width: ${widthLarge}px)`;
export const mediaXlargeMin = `screen and (min-width: ${widthXlarge}px)`;

export const mediaXSmallMax = `screen and (max-width: ${widthSmall - 1}px)`;
export const mediaSmallMax = `screen and (max-width: ${widthMedium - 1}px)`;
export const mediaMediumMax = `screen and (max-width: ${widthLarge - 1}px)`;
export const mediaLargeMax = `screen and (max-width: ${widthXlarge - 1}px)`;
