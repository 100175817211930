import React from 'react';
import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { useStyles } from './AuxiliarButton.styles';
import { IconKeys, IconPosition, Icon } from '@groupbuilderoy/gb-components-library';

export enum ButtonSizeType {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum ButtonType {
  outlined = 'outlined',
  contained = 'contained'
}

export interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  caption: string;
  children?: JSX.Element;
  size?: ButtonSizeType;
  type?: ButtonType;
  startIcon?: IconKeys;
  endIcon?: IconKeys;
  iconSize?: number;
  textVariant?: Variant;
  fitContainer?: boolean;
}

export const capitalizeFirstLetter = (stringToConvert: string) => {
  return stringToConvert.charAt(0).toUpperCase() + stringToConvert.slice(1);
};

const AuxiliarButton: React.FC<ButtonProps> = ({
  caption,
  children,
  size = ButtonSizeType.medium,
  type = ButtonType.contained,
  startIcon,
  endIcon,
  iconSize,
  textVariant,
  fitContainer = false,
  className,
  ...rest
}: ButtonProps) => {
  const classes = useStyles({ fitContainer })();
  return (
    <button
      {...rest}
      className={`${classes.root} ${classes[size]} ${classes[type]} ${className ? className : ''}`}
    >
      <div className={classes.content}>
        {startIcon && <Icon iconKey={startIcon} position={IconPosition.start} size={iconSize} />}
        {children ? (
          children
        ) : (
          <Typography variant={textVariant || 'inherit'} className={classes.caption}>
            {caption ? capitalizeFirstLetter(caption) : 'Button'}
          </Typography>
        )}
        {endIcon && <Icon iconKey={endIcon} position={IconPosition.end} size={iconSize} />}
      </div>
    </button>
  );
};

export default AuxiliarButton;
