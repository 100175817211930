import { observable } from 'mobx';
import UserLevelRightInterface from '../interfaces/UserLevelRightInterface';

class UserLevelRight implements UserLevelRightInterface {
  public static readonly userLevelRights: Record<number, { id: number; userLevelKey: string }> = {
    [-1]: {
      id: -1,
      userLevelKey: 'public'
    },
    0: {
      id: 0,
      userLevelKey: 'virtualBuyer'
    },
    1: {
      id: 1,
      userLevelKey: 'visitor'
    },
    2: {
      id: 2,
      userLevelKey: 'realEstateAgent'
    },
    3: {
      id: 3,
      userLevelKey: 'basicOwner'
    },
    4: {
      id: 4,
      userLevelKey: 'advancedOwner'
    },
    5: {
      id: 5,
      userLevelKey: 'builder'
    },
    6: {
      id: 6,
      userLevelKey: 'maintenance'
    },
    7: {
      id: 7,
      userLevelKey: 'inspector'
    },
    8: {
      id: 8,
      userLevelKey: 'architect'
    },
    9: {
      id: 9,
      userLevelKey: 'worksiteManager'
    },
    10: {
      id: 10,
      userLevelKey: 'projectManager'
    }
  };

  public static createUserLevelRight(source: UserLevelRightInterface) {
    return new UserLevelRight(source);
  }

  @observable public canRead: boolean;
  @observable public canWrite: boolean;
  @observable public canDelete: boolean;
  private privateUserLevelKey: string;
  private privateId: number;

  private constructor(source: UserLevelRightInterface) {
    this.canRead = source.canRead;
    this.canWrite = source.canWrite;
    this.canDelete = source.canDelete;
    this.privateUserLevelKey = source.userLevelKey;
    this.privateId = source.id;
  }

  public get userLevelKey(): string {
    return this.privateUserLevelKey;
  }

  public get id(): number {
    return this.privateId;
  }

  public isEqual(userLevelRight: UserLevelRightInterface) {
    return (
      this.canDelete === userLevelRight.canDelete &&
      this.canWrite === userLevelRight.canWrite &&
      this.canRead === userLevelRight.canRead &&
      this.id === userLevelRight.id
    );
  }

  public toJSON() {
    const { privateId, privateUserLevelKey, ...publicAttributes } = this;
    return {
      ...publicAttributes,
      id: this.id
    };
  }
}

export default UserLevelRight;
