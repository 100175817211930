import React from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from './HeaderText.styles';

export interface HeaderTextProps {
  userName?: string;
  heading?: string;
  description?: string;
  [x: string]: any;
}

const HeaderText: React.FC<HeaderTextProps> = ({
  userName,
  heading,
  description,
  ...rest
}: HeaderTextProps) => {
  const classes = makeStyles();

  return (
    <div style={{ marginTop: '2rem' }} {...rest}>
      <Typography variant='h4' className={classes.header}>
        {heading ? heading : 'Welcome home'}
        {userName ? `, ${userName}` : ''}
      </Typography>
      {description && <Typography className={classes.description}>{description}</Typography>}
    </div>
  );
};

export default HeaderText;
