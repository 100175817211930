import { RouterStore } from 'mobx-react-router';
import queryString from 'query-string';
import { pageview } from 'react-ga';

const routingStore = new RouterStore();

export const changeParams = (
  newParams: {
    [x: string]: number | string | undefined;
  },
  replace?: boolean
) => {
  const oldParams = queryString.parse(routingStore.location.search);
  const currentLocation = routingStore.location.pathname;

  const query = queryString.stringify({
    ...oldParams,
    ...newParams
  });

  if (!replace) routingStore.push(`${currentLocation}?${query}`);
  else routingStore.replace(`${currentLocation}?${query}`);
  pageview(`${currentLocation}?${query}`);
};

export const getParams = (search?: string) => {
  return search
    ? queryString.parse(search)
    : routingStore.location
    ? queryString.parse(routingStore.location.search)
    : {};
};

export const changeLocation = (newLocation: string) => {
  const oldParams = queryString.parse(routingStore.location.search);
  const query = queryString.stringify(oldParams);
  routingStore.push(`${newLocation}?${query}`);

  pageview(`${newLocation}?${query}`);
};

export default routingStore;
