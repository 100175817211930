import { useEffect, useRef } from 'react';

/**
 * Dan Abramovich's very own implementation for setInterval
 * @see https://usehooks-typescript.com/use-interval
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {Function} callback
 * @param {number | null} delay
 */
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void | null>();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });
  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback?.current !== 'undefined') {
        savedCallback?.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
};
