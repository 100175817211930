import React from 'react';
import { observer } from 'mobx-react-lite';
import OfferIcon from '../Icons/offer.svg';
import { FooterBtn, FooterBtnImgContainer, FooterLinkText } from './FooterStyledComponents';
import { OffersOrDeadlines } from './Footer';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import routingStore from '../../store/RoutingStore';
import { text } from '../../utils';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import { useTrackers } from '../../hooks/useTrackers';

const ConfirmationAndOffersFooterBtn = (props: { separated: boolean }) => {
  const { triggerEvent } = useTrackers();
  const { pendingBundleOffers, upComingDeadlines } = materialSelectorStore;
  const { separated } = props;

  const redirectToConfirmationPage = () => {
    triggerEvent({
      action: EventAction.FOOTER_CONFIRMATION_CLICKED,
      category: EventCategory.USER
    });
    routingStore.push('/order-confirmation');
  };

  if (separated) {
    return (
      <>
        <FooterBtn onClick={redirectToConfirmationPage}>
          <FooterBtnImgContainer>
            {!!pendingBundleOffers.length && (
              <OffersOrDeadlines>{pendingBundleOffers.length}</OffersOrDeadlines>
            )}
            <img src={OfferIcon} alt='offer-icon' />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('offers')}</FooterLinkText>
        </FooterBtn>

        <FooterBtn onClick={redirectToConfirmationPage}>
          <FooterBtnImgContainer>
            {!!upComingDeadlines.length && (
              <OffersOrDeadlines>{upComingDeadlines.length}</OffersOrDeadlines>
            )}
            <img src={OfferIcon} alt='confirmation-icon' />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('confirmations')}</FooterLinkText>
        </FooterBtn>
      </>
    );
  } else {
    return (
      <FooterBtn onClick={redirectToConfirmationPage}>
        <FooterBtnImgContainer>
          {(!!pendingBundleOffers.length || !!upComingDeadlines.length) && (
            <OffersOrDeadlines>
              {pendingBundleOffers.length + upComingDeadlines.length}
            </OffersOrDeadlines>
          )}
          <img src={OfferIcon} alt='offer-icon' />
        </FooterBtnImgContainer>
        <FooterLinkText>{text('confirmationsAndOffers')}</FooterLinkText>
      </FooterBtn>
    );
  }
};

export default observer(ConfirmationAndOffersFooterBtn);
