import { computed } from 'mobx';
import AttachmentInterface from '../interfaces/AttachmentInterface';

abstract class AttachmentDecorator implements AttachmentInterface {
  private attachment: AttachmentInterface;

  protected constructor(attachment: AttachmentInterface) {
    this.attachment = attachment;
  }

  @computed
  get id() {
    return this.attachment.id;
  }
  set id(id) {
    this.attachment.id = id;
  }

  @computed
  get name() {
    return this.attachment.name;
  }
  set name(name) {
    this.attachment.name = name;
  }

  @computed
  get description() {
    return this.attachment.description;
  }
  set description(description) {
    this.attachment.description = description;
  }

  @computed
  get filename() {
    return this.attachment.filename;
  }
  set filename(filename) {
    this.attachment.filename = filename;
  }

  @computed
  get uuidFilename() {
    return this.attachment.uuidFilename;
  }
  set uuidFilename(uuidFilename) {
    this.attachment.uuidFilename = uuidFilename;
  }

  @computed
  get type() {
    return this.attachment.type;
  }
  set type(type) {
    this.attachment.type = type;
  }

  @computed
  get createdBy() {
    return this.attachment.createdBy;
  }
  set createdBy(createdBy) {
    this.attachment.createdBy = createdBy;
  }

  @computed
  get createdOn() {
    return this.attachment.createdOn;
  }
  set createdOn(createdOn) {
    this.attachment.createdOn = createdOn;
  }

  @computed
  get mimeType() {
    return this.attachment.mimeType;
  }
  set mimeType(mimeType) {
    this.attachment.mimeType = mimeType;
  }

  @computed
  get url() {
    return this.attachment.url;
  }
  set url(url) {
    this.attachment.url = url;
  }

  @computed
  get previewImageUrl() {
    return this.attachment.previewImageUrl;
  }
  set previewImageUrl(previewImageUrl) {
    this.attachment.previewImageUrl = previewImageUrl;
  }

  @computed
  get shownInCj() {
    return this.attachment.shownInCj;
  }
  set shownInCj(shownInCj) {
    this.attachment.shownInCj = shownInCj;
  }

  @computed
  get stream() {
    return this.attachment.stream;
  }
  set stream(stream) {
    this.attachment.stream = stream;
  }

  @computed
  get file() {
    return this.attachment.file;
  }
  set file(file) {
    this.attachment.file = file;
  }

  @computed
  get userLevelRights() {
    return this.attachment.userLevelRights;
  }
  set userLevelRights(userLevelRights) {
    this.attachment.userLevelRights = userLevelRights;
  }

  @computed
  get tags() {
    return this.attachment.tags;
  }
  set tags(tags) {
    this.attachment.tags = tags;
  }

  public toJSON() {
    const { attachment, file, ...output } = this;
    const { ...publicAttachment } = this.attachment;
    delete publicAttachment.file;
    return {
      ...publicAttachment,
      ...output
    };
  }
}

export default AttachmentDecorator;
