import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--custom-colorLoadingBackground);
`;

const Spinner = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--custom-colorLoadingSpinner);
`;

interface ProgressProps {
  progressProps?: any;
  icon?: boolean;
  [x: string]: any;
}

const Progress = ({ progressProps, icon, width = '20px', ...props }: ProgressProps) => (
  <Wrapper {...props}>
    <Spinner>
      <CircularProgress
        id='progress-spinner'
        {...progressProps}
        style={icon ? { width, height: width } : undefined}
      />
    </Spinner>
  </Wrapper>
);

export default Progress;
