import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import useStyles from './ActionButton.styles';

export interface ActionButtonProps extends ButtonProps {
  loading?: boolean;
  loadingCaption?: string;
  onClick: (e: React.SyntheticEvent) => void;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { loading, children, onClick, loadingCaption, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      variant='contained'
      color='primary'
      className={`${classes.button} ${loading ? 'loading' : ''}`}
      disabled={disabled}
      {...rest}
    >
      {loading && (
        <>
          {loadingCaption && (
            <span className={classes.stateCaption}>{loadingCaption.toUpperCase()}</span>
          )}
          <CircularProgress className='progress' />
        </>
      )}
      <div className='children'>{children}</div>
    </Button>
  );
};

export default ActionButton;
