import { observable, computed } from 'mobx';
import { getFormattedDateBasedOnLocale } from '../../utils/dates';
import DateFormat from '../../store/enums/DateFormat';

export interface ApiCalendarEvent {
  id: number;
  name: string;
  description: string | null;
  endTime: string | null;
}

export class CalendarEvent {
  @observable public id: number;
  @observable public name: string;
  @observable public description: string | null;
  @observable public endTime: string | null;

  constructor({ id, name, description, endTime }: ApiCalendarEvent) {
    this.name = name;
    this.id = id;
    this.description = description;
    this.endTime = endTime;
  }

  @computed
  get eventDate(): string {
    return this.endTime ? getFormattedDateBasedOnLocale(this.endTime, DateFormat.COMPACT) : '';
  }
}
