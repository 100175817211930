// @ts-ignore
import mimeType from 'mimetype'; // eslint-disable-line
import moment from 'moment';
import Attachment from './Attachment';
import AttachmentInterface from '../interfaces/AttachmentInterface';
import LegacyAttachmentInterface from '../interfaces/LegacyAttachmentInterface';
import OfferAttachmentDecorator from './OfferAttachmentDecorator';
import { SectionInterface } from '../interfaces/SectionInterface';
import ApartmentAttachmentDecorator from './ApartmentAttachmentDecorator';
import ProjectAttachmentDecorator from './ProjectAttachmentDecorator';
import MessageAttachmentDecorator from './MessageAttachmentDecorator';
import AttachmentDataInterface from '../interfaces/AttachmentDataInterface';
import UserLevelRight from './UserLevelRight';
import ItemTypeEnum from '../enums/ItemTypeEnum';
import { TagInterface } from './Tag';

class AttachmentFactory {
  public createFromLegacyData(source: LegacyAttachmentInterface): AttachmentInterface {
    const attachment = new Attachment({
      id: source.id,
      name: source.displayName,
      description: source.comment,
      filename: source.filename,
      uuidFilename: source.uuidFilename,
      createdOn: moment(source.uploadDate, 'DD.MM.YYYY').utc().toDate(),
      createdBy: { firstName: source.user, lastName: '' },
      url: source.attachment_url,
      mimeType: mimeType.lookup(source.attachment_url)
    });

    return this.decorateAttachment(attachment, source);
  }

  public createFromAttachmentData(source: AttachmentDataInterface) {
    const userLevelRightData = source.userLevelRights || {};
    const attachment = new Attachment({
      id: source.id,
      name: source.name,
      description: source.description,
      filename: source.filename,
      uuidFilename: source.uuidFilename,
      createdOn: moment(source.createdOn).utc().toDate(),
      createdBy: source.createdBy,
      url: source.url,
      previewImageUrl: source.previewImageUrl,
      tags: source.tags as TagInterface[],
      shownInCj: source.shownInCj,
      mimeType: source.mimeType || mimeType.lookup(source.filename),
      userLevelRights: Object.keys(UserLevelRight.userLevelRights).reduce((carry, userLevel) => {
        const userLevelId = Number(userLevel);
        const userLevelRight = UserLevelRight.createUserLevelRight({
          id: Number(userLevel),
          userLevelKey: UserLevelRight.userLevelRights[userLevel].userLevelKey,
          canDelete: false,
          canRead: false,
          canWrite: false
        });
        if (userLevel in userLevelRightData) {
          userLevelRight.canDelete = !!userLevelRightData[userLevelId].canDelete;
          userLevelRight.canRead = !!userLevelRightData[userLevelId].canRead;
          userLevelRight.canWrite =
            !!userLevelRightData[userLevelId].canCreate ||
            !!userLevelRightData[userLevelId].canUpdate;
        }

        carry[userLevelId] = userLevelRight;
        return carry;
      }, {})
    });

    return this.decorateAttachment(attachment, source);
  }

  public createFromFileUploadData(tempId: number, file: File): AttachmentInterface {
    return new Attachment({
      id: tempId,
      name: file.name,
      stream: file.stream(),
      filename: file.name,
      uuidFilename: file.name,
      mimeType: file.type,
      url: null,
      previewImageUrl: null,
      createdOn: new Date(),
      file
    });
  }

  public copy(attachment: AttachmentInterface) {
    const coreAttachment = new Attachment(attachment);
    return this.decorateAttachment(coreAttachment, attachment);
  }

  private decorateAttachment(
    attachment: AttachmentInterface,
    source: AttachmentInterface | AttachmentDataInterface | LegacyAttachmentInterface
  ) {
    if ('messageId' in source) {
      attachment.type = ItemTypeEnum.MESSAGE;
      return new MessageAttachmentDecorator(attachment, {
        messageId: source.messageId!,
        projectId: source.projectId!,
        apartmentId: source.apartmentId
      });
    }
    if ('apartmentId' in source) {
      attachment.type = ItemTypeEnum.APARTMENT;
      return new ApartmentAttachmentDecorator(attachment, {
        apartmentId: source.apartmentId!
      });
    }
    if ('projectId' in source) {
      attachment.type = ItemTypeEnum.PROJECT;
      return new ProjectAttachmentDecorator(attachment, {
        projectId: source.projectId!
      });
    }
    if ('offerId' in source) {
      attachment.type = ItemTypeEnum.OFFER;
      return new OfferAttachmentDecorator(attachment, {
        offerId: source.offerId!,
        offerRowId: source.offerRowId!,
        targetSection: (source.targetSection as SectionInterface) || null
      });
    }

    throw new Error('Attachment type not recognized');
  }
}

const attachmentFactory = new AttachmentFactory();
export default attachmentFactory;
