// NavigationHandler.tsx
import { useEffect } from 'react';
import { text } from './utils';
import confirmationDialogStore from './store/ConfirmationDialogStore';

const continueNavigation = () => {
  window.parent.postMessage({ type: 'NavigationDecision', allowNavigation: true }, '*');
};

const showModal = () => {
  confirmationDialogStore.open(
    {
      confirmationTitle: text('offerModal.attention'),
      confirmationText: text('offerModal.modalText'),
      okText: text('offerModal.viewOffer'),
      cancelText: text('offerModal.closeOffer')
    },
    () => {
      window.parent.postMessage({ type: 'NavigationDecision', allowNavigation: false }, '*');
      return null;
    },
    () => {
      continueNavigation();
    }
  );
};

export const useCJ3NavigationHandler = () => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'exitingCJ2') {
        if (document.getElementById('preventUnload')) {
          showModal();
        } else {
          continueNavigation();
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
};
