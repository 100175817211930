import Bundle from './models/Bundle';
import Room from './models/Room';

export const bundlesFilteredByShowRooms =
  (bundlesToFilter: Bundle[], showRooms?: boolean) => (roomBundleIds: number[]) => {
    return showRooms
      ? bundlesToFilter.filter((b) => roomBundleIds.includes(b.id))
      : bundlesToFilter;
  };

export const sortBundles = (bundles: Bundle[], selectedRoom?: Room) => {
  return bundles.sort((a: Bundle, b: Bundle) => {
    // 1st: General appearance should be last if room is selected
    if (selectedRoom) {
      if (!a.isApartmentLevel && b.isApartmentLevel) return -1;
      if (a.isApartmentLevel && !b.isApartmentLevel) return 1;
    }

    // 2nd: bundles should be order by orderNumber (ascending order from 1 to 999.., 0 is last)
    if (a.orderNumber && b.orderNumber === undefined) return -1;
    if (a.orderNumber === undefined && b.orderNumber) return 1;
    if (a.orderNumber && b.orderNumber) return a.orderNumber > b.orderNumber ? 1 : -1;

    // 3rd: Order by name
    if (a.name < b.name) return -1;
    else return 1;
  });
};
