import React from 'react';

import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import VersionsView from './VersionsView';
import { changeLocation } from '../../store/RoutingStore';
import Modal from '../../components/Modal/Modal';

const StyledLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const Admin = () => {
  return (
    <Modal closeBtnOffset='3rem' onClose={() => changeLocation('/')}>
      <StyledLayout>
        <VersionsView />
      </StyledLayout>
    </Modal>
  );
};

export default observer(Admin);
