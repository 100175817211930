import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

const ModalEmbed = styled.embed`
  width: 100%;
  height: calc(100vh - 3rem);

  @media ${mediaSmallMax} {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    margin: 0;
  }
`;

export default ModalEmbed;
