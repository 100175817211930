import styled from 'styled-components/macro';
import fileIcon from '../../components/Icons/file.svg';
import penIcon from '../../components/Icons/pen-paper.svg';
import pictureIcon from '../../components/Icons/picture.svg';
import { text, limitStringLength } from '../../utils';
import React from 'react';
import { mediaSmallMax } from '../Styled/media';
import { Attachment, SignableAttachment } from '../../store/interfaces/Attachment';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import { FormattedTextSection } from '../Styled/FormattedTextSection';
import { getFormattedDateBasedOnLocale } from '../../utils/dates';
import DateFormat from '../../store/enums/DateFormat';
import userRightsStore from '../../store/UserRightsStore';
import { UserRight } from '../../store/enums/UserRights';
import CircleBadge from '../CircleBadge/CircleBadge';
import attachmentsStore from '../../store/AttachmentsStore';
import { useTrackers } from '../../hooks/useTrackers';

const StyledAttachmentRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 0;
  position: relative;

  @media ${mediaSmallMax} {
    flex-wrap: wrap;
  }
`;

const AttachmentRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & > img {
    width: 35px;
  }

  @media ${mediaSmallMax} {
    & > img {
      width: 24px;
    }
  }
`;

const AttachmentInformation = styled(FormattedTextSection)`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  p {
    &.date {
      font-weight: 400;
      font-family: var(--custom-fontName);
      padding-bottom: 4px;
    }
    &.name {
      font-weight: 400;
      font-family: var(--custom-fontName);
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 8px;
    }
    &.description {
      font-size: 14px;
    }
  }

  @media ${mediaSmallMax} {
    padding-left: 1rem;
    p {
      &.date {
        font-size: 12px;
        line-height: 13px;
      }
      &.name {
        font-size: 14px;
        line-height: 15px;
      }
      &.description {
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
`;

const AttachmentLinkContainer = styled.div`
  @media ${mediaSmallMax} {
    padding-left: 2.5rem;
    width: 100%;
  }
`;

const AttachmentLink = styled.a`
  cursor: pointer;
  @media ${mediaSmallMax} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const NotificationBadge = styled(CircleBadge)`
  position: absolute;
  top: 9px;
  left: -5px;
  --circle-size: 17px;
  background-color: var(--custom-colorNotif1);

  @media ${mediaSmallMax} {
    top: 12px;
    --circle-size: 12px;
  }
`;

interface AttachmentRowContainerProps {
  attachment: Attachment;
}

const AttachmentRowContainer = ({ attachment }: AttachmentRowContainerProps) => {
  const { triggerEvent } = useTrackers();
  const signableAttachment = attachment as SignableAttachment;
  const url = signableAttachment.url ? signableAttachment.url : attachment.link;
  const date = signableAttachment.createdOn ? signableAttachment.createdOn : attachment.uploadDate;
  const signable = signableAttachment.signable && userRightsStore.check(UserRight.SIGN_DOCUMENT);

  const handleClick = () => {
    if (signable || signableAttachment.signed) {
      attachmentsStore.signableDocument = signableAttachment;
      return;
    }

    triggerEvent({
      action: EventAction.LOAD_DOCUMENT,
      category: EventCategory.USER,
      label: 'link'
    });

    window.open(url, '_blank');
  };

  return (
    <StyledAttachmentRowContainer>
      <AttachmentRow>
        {signableAttachment.signable && <NotificationBadge data-testid='notification-badge' />}
        <img
          src={url.endsWith('.pdf') ? (!signableAttachment.url ? fileIcon : penIcon) : pictureIcon}
          alt='attachment-icon'
        />
        <AttachmentInformation>
          <p className='date'>{getFormattedDateBasedOnLocale(date, DateFormat.COMPACT)}</p>
          <p className='name' data-testid='test-name'>
            {attachment.name ? limitStringLength(attachment.name, 70, 68) : ''}
          </p>
          <p className='description' data-testid='test-description'>
            {attachment.description ? limitStringLength(attachment.description, 70, 68) : ''}
          </p>
        </AttachmentInformation>
      </AttachmentRow>
      <AttachmentLinkContainer>
        <AttachmentLink onClick={handleClick}>
          {!signable ? `${text('open')}` : `${text('sign')}`}
        </AttachmentLink>
      </AttachmentLinkContainer>
    </StyledAttachmentRowContainer>
  );
};

export default AttachmentRowContainer;
