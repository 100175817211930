import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = ({ fitContainer }: any) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: fitContainer ? '100%' : 'auto',
        cursor: 'pointer',
        padding: '0 0.8rem',
        display: 'flex',
        flexDirection: 'row',
        minWidth: '89px',
        borderRadius: theme.palette.primary.main === '#FFE800' ? '99999px' : '8px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        '&:hover': {
          background: theme.palette.secondary.main,
          color:
            theme.palette.primary.main === '#FFE800' ? '#212427' : theme.palette.description?.main
        },

        '& span': {
          fontFamily:
            theme.palette.primary.main === '#FFE800'
              ? 'FK Grotesk Neue Medium,sans-serif'
              : 'theme.typography.fontFamily'
        }
      },
      rootMobile: {
        cursor: 'pointer',
        padding: '0 0.8rem',
        display: 'flex',
        flexDirection: 'row',
        minWidth: '89px',
        borderRadius: '8px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        '-webkit-tap-highlight-color': 'transparent'
      },
      outlined: {
        background: '#FFFFFF',
        border: '1px solid #D0D5DD',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        color: theme.palette.primary.main === '#FFE800' ? '#212427' : '#344054',
        '&:hover': {
          background:
            theme.palette.primary.main === '#FFE800' ? '#f7f7f7' : theme.palette.secondary.main,
          color: theme.palette.primary.main === '#FFE800' ? '#212427' : '#344054'
        }
      },
      contained: {
        background: theme.palette.primary.main,
        border: theme.palette.primary.main === '#FFE800' ? '1px solid #e8d000' : 'none',
        color: theme.palette.primary.main === '#FFE800' ? '#212427' : 'white',
        '&:hover': {
          background:
            theme.palette.primary.main === '#FFE800' ? '#fff500' : theme.palette.secondary.main,
          color: theme.palette.primary.main === '#FFE800' ? '#212427' : '#344054'
        }
      },
      content: {
        display: 'flex',
        margin: 'auto'
      },
      small: {
        height: '36px'
      },
      medium: {
        height: '40px'
      },
      large: {
        height: '44px',
        fontSize: '1rem'
      },
      caption: {
        margin: 'auto'
      }
    })
  );
};
