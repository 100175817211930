import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import store from '../../store/ConfirmationDialogStore';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import Button from '../Button/Button';
import { mediaSmallMax } from '../Styled/media';
import appStore from '../../store/AppStore';
import { ButtonType } from '@groupbuilderoy/gb-components-library';
import AuxiliarButton from '../../auxiliaries/AuxiliarButton';

const StyledConfirmationDialog = styled.div`
  background-color: white;

  padding: 3.5rem 3.5rem 6rem 3.5rem;
  max-height: 90vh;
  max-width: 90vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mediaSmallMax} {
    padding: 1.5rem;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    width: 100vw;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  min-width: 10rem;
  max-width: 15rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

const ContentContainer = styled.div`
  min-width: 10rem;
  max-width: 30rem;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  text-align: center;

  h3 {
    white-space: pre-wrap;
  }
`;

const ConfirmationDialog = () =>
  store.isOpen ? (
    <Backdrop onClick={store.close}>
      <StyledConfirmationDialog>
        <ContentContainer>
          {store.confirmationTitle && (
            <h2 className='font-medium' style={{ marginBottom: '2rem' }}>
              {store.confirmationTitle}
            </h2>
          )}
          <h3 className='font-normal'>{store.confirmationText}</h3>
        </ContentContainer>
        <ButtonContainer>
          {<AuxiliarButton caption={store.okText} onClick={store.ok} />}
          {store.onCancel && (
            <AuxiliarButton
              caption={store.cancelText}
              onClick={store.cancel}
              type={ButtonType.outlined}
            />
          )}
        </ButtonContainer>
      </StyledConfirmationDialog>
    </Backdrop>
  ) : null;

export default observer(ConfirmationDialog);
