import Bundle from '../../store/models/Bundle';
import { text, isLocked } from '../../utils';
import { RoomsWithDl } from '../../store/MaterialSelectorStore';
import { getFormattedDateBasedOnLocale } from '../../utils/dates';
import DateFormat from '../../store/enums/DateFormat';
import Decimal from 'decimal.js';

interface RoomObj {
  deadline: string;
  bundles: Bundle[];
  lockedBundles: Bundle[];
  deadlineGoneBundles: Bundle[];
  openBundles: Bundle[];
}

export const getLockedApartmentBundles: (bundles: Bundle[]) => Bundle[] = (bundles) => {
  return bundles.filter(isLocked);
};

export const getOpenApartmentBundles: (bundles: Bundle[]) => Bundle[] = (bundles) => {
  return bundles.filter((b) => !isLocked(b));
};

const isSameDeadline = (deadline1?: string | null, deadline2?: string | null) => {
  return deadline1 === deadline2 || (!deadline1 && !deadline2);
};
export const getBundleRooms: (roomsWithDl: RoomsWithDl) => RoomObj[] = (roomsWithDl) => {
  return roomsWithDl.rooms.reduce((pre, cur) => {
    pre.push({
      deadline: roomsWithDl.deadline,
      bundles: cur.bundles.filter((b) => isSameDeadline(b.deadline, roomsWithDl.deadline)),
      lockedBundles: cur.lockedBundles.filter((b) =>
        isSameDeadline(b.deadline, roomsWithDl.deadline)
      ),
      openBundles: cur.openBundles.filter((b) => isSameDeadline(b.deadline, roomsWithDl.deadline)),
      deadlineGoneBundles: cur.deadlineGoneBundles.filter((b) =>
        isSameDeadline(b.deadline, roomsWithDl.deadline)
      )
    });

    return pre;
  }, [] as RoomObj[]);
};

export const createConfirmedInOfferText: (offerName: string) => string = (offerName) => {
  return text('confirmedInOffer', { offerName });
};

export const createConfirmByText: (
  deadlineGone: boolean,
  bundlesLocked: boolean,
  deadline: string
) => string = (deadlineGone, bundlesLocked, deadline) => {
  const confirmByText =
    deadlineGone && !bundlesLocked
      ? 'confirmGone'
      : bundlesLocked
      ? 'orderConfirmed'
      : 'confirmBefore';

  return text(confirmByText, {
    date: getFormattedDateBasedOnLocale(deadline, DateFormat.COMPACT)
  });
};

export const getTotalPrice: (bundles: Bundle[]) => Decimal = (bundles) => {
  return bundles.reduce((pre, cur) => {
    return cur.selectedMaterialOption?.price ? pre.add(cur.selectedMaterialOption.price) : pre;
  }, new Decimal(0));
};
