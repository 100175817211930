import { observable, computed } from 'mobx';
import { OfferRowChange } from './OfferRowChange';
import OfferRowState from '../../store/enums/OfferRowState';

export interface OfferRowData {
  id: number;
  name: string;
  description: string | null;
  state: OfferRowState;
  totalGrossPrice: string | null;
  changes: OfferRowChange[];
}

export class OfferRow {
  @observable public id: number;
  @observable public name: string;
  @observable public description: string | null;
  @observable public state: OfferRowState;
  @observable public totalGrossPrice: string | null;
  @observable public changes: OfferRowChange[];

  constructor({ id, name, description, state, totalGrossPrice, changes }: OfferRowData) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.state = state;
    this.totalGrossPrice = totalGrossPrice;
    this.changes = changes;
  }

  @computed
  get isAccepted() {
    return this.state === OfferRowState.ACCEPTED || this.state === OfferRowState.APPLIED_TO_DATABASE
      ? true
      : false;
  }

  @computed
  get isRejected() {
    return this.state === OfferRowState.REJECTED ? true : false;
  }

  @computed
  get isOpen() {
    return this.state === OfferRowState.OPEN ? true : false;
  }
}
