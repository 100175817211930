import React from 'react';
import Bundle from '../../store/models/Bundle';
import { observer } from 'mobx-react-lite';
import { MaterialImage } from '../Materials/styledComponents';
import lightBoxStore from '../../store/LightBoxStore';
import { text, getFormattedMaterialPrice } from '../../utils';
import { modalView } from '../../services';
import { ModalView } from '../../services/ModalView';
import {
  StyledBundlesConfirmation,
  ConfirmationBundle,
  ImageContainer,
  StyledImageWrapper,
  SelectedMaterialPrice,
  BundleInfo
} from './styledComponents';

interface BundlesConfirmationProps {
  bundles: Bundle[];
}

const BundlesConfirmation = ({ bundles }: BundlesConfirmationProps) => (
  <StyledBundlesConfirmation>
    {bundles.map((b) => {
      const materialOption = b.selectedMaterialOption;

      const materialPrice =
        b.hasAppliedToDbOffer && b.offers[0].formatedPrice
          ? b.offers[0].formatedPrice
          : materialOption
          ? materialOption.price
          : null;

      return (
        <ConfirmationBundle key={b.id}>
          <ImageContainer>
            <StyledImageWrapper>
              {materialOption && (
                <MaterialImage
                  style={{ cursor: 'pointer' }}
                  src={materialOption.imageUrlSmall}
                  onClick={() => {
                    if (materialOption) {
                      modalView(ModalView.MATERIAL_IMAGE);
                      lightBoxStore.open([materialOption.imageUrlLarge]);
                    }
                  }}
                />
              )}
            </StyledImageWrapper>
            {materialPrice && (
              <SelectedMaterialPrice askForPrice={materialPrice.equals(0.01)}>
                {getFormattedMaterialPrice(materialPrice)}
              </SelectedMaterialPrice>
            )}
          </ImageContainer>
          <BundleInfo>
            <p className='font-light'>{b.name}</p>
            <p className='font-medium'>
              {materialOption ? materialOption.name : text('noSelectionText')}
            </p>
            <p className='font-normal'>
              {materialOption && materialOption.sku && `${text('sku')}: ${materialOption.sku}`}
            </p>
            <p className='font-normal'>{materialOption && materialOption.description}</p>
          </BundleInfo>
          {materialPrice && (
            <SelectedMaterialPrice
              className='visible-on-desktop'
              askForPrice={materialPrice.equals(0.01)}
            >
              {getFormattedMaterialPrice(materialPrice)}
            </SelectedMaterialPrice>
          )}
        </ConfirmationBundle>
      );
    })}
  </StyledBundlesConfirmation>
);

export default observer(BundlesConfirmation);
