import React from 'react';
import Zoom from '../Icons/Zoom';
import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

const StyledZoom = styled(Zoom)`
  fill: var(--custom-colorAccent);
  transition: all 0.2s ease-in-out;

  @media ${mediaSmallMax} {
    width: 22px;
    height: 22px;
  }
`;

const ZoomContainer = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  overflow: visible;
  min-width: 3.25rem;
  min-height: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.45);

  &:hover ${StyledZoom} {
    fill: var(--custom-colorHover);
  }

  @media ${mediaSmallMax} {
    min-width: 30px;
    min-height: 30px;
  }
`;

interface ZoomButtonProps {
  [x: string]: any;
}

const ZoomButton = (props: ZoomButtonProps) => (
  <ZoomContainer {...props}>
    <StyledZoom />
  </ZoomContainer>
);

export default ZoomButton;
