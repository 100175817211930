import { observable, action } from 'mobx';
import FilterListableElement from '../interfaces/FilterListableElement';

export interface TagInterface {
  id: number;
  name: string;
  description: string | null;
  systemTag: string | null;
  categoryId: number | null;
  selected?: boolean;
  isPriceTag?: boolean;
}

export default class Tag implements TagInterface, FilterListableElement {
  @observable public id: number;
  @observable public name: string = '';
  @observable public description: string | null = '';
  @observable public systemTag: string | null;
  @observable public categoryId: number | null;
  @observable public selected: boolean = false;
  @observable public selectionStats?: any;
  @observable public isPriceTag?: boolean;

  constructor({
    id,
    name,
    description,
    systemTag,
    categoryId,
    selected,
    selectionStats,
    isPriceTag
  }: TagInterface & Partial<FilterListableElement>) {
    this.id = id;
    this.name = name || '';
    this.description = description || '';
    this.systemTag = systemTag;
    this.categoryId = categoryId;
    this.selected = !!selected;
    this.selectionStats = selectionStats;
    this.isPriceTag = isPriceTag;
  }

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };

  @action
  public select() {
    this.selected = true;
  }
  @action
  public deselect() {
    this.selected = false;
  }

  @action
  public toggleSelect() {
    this.selected ? this.deselect() : this.select();
  }
}
