import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

export default styled.button`
  background-color: var(--color-lightGray);
  border: 0;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  width: 3.25rem;
  height: 3.25rem;

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: var(--custom-colorHover);
  }

  @media ${mediaSmallMax} {
    width: 30px;
    height: 30px;
  }
`;
