import en from './en/main.json';
import en_GB from './en-GB/main.json'
import fi from './fi/main.json';
import de from './de/main.json';
import ru from './ru/main.json';
import et from './et/main.json';
import nb from './nb/main.json';
import es from './es/main.json';
import cs from './cs/main.json';
import sk from './sk/main.json';
import sv from './sv/main.json';
import lt from './lt/main.json';
import fr from './fr/main.json';
import nl from './nl/main.json';
import ar from './ar/main.json';

// eslint-disable-next-line
export default { "en-GB": en_GB, en, fi, de, ru, et, nb, es, cs, sk, sv, lt, fr, nl, ar};
