import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { ThemeName, ThemeDescription } from './components/ThemeCarousel/ThemeCarousel';
import { ThemeCardName } from './components/Themes/ThemeCard';
import { ThemeSetName } from './components/Themes/ThemeSetCard';

const Hartela = createGlobalStyle`
  ${ThemeName} {
    font-family: EksellDisplayWeb-Medium;
  }

  ${ThemeDescription} {
    font-family: EksellDisplayWeb-Medium;
  }

  ${ThemeCardName} {
    font-family: EksellDisplayWeb-Medium;
  }

  ${ThemeSetName} {
    font-family: EksellDisplayWeb-Medium;
  }
`;

const organisationStyles = {
  hartela: Hartela
};

const OrganisationStyle: React.FC<{ organisation?: string }> = ({ organisation }) => {
  const Styles = organisation && organisationStyles[organisation];

  return Styles ? <Styles /> : null;
};

export default OrganisationStyle;
