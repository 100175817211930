import styled from 'styled-components/macro';
import arrow from '../Icons/WhiteArrowDown';
import { mediaSmallMax } from './media';

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: 400;
    font-family: var(--custom-fontName);
  }
  p {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  @media ${mediaSmallMax} {
    p {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    max-width: calc(100vw - 5.5rem);
  }
`;

interface SectionTitleDividerProps {
  open?: boolean;
  onClick?: any;
  integratedCj2?: boolean;
}

export const SectionTitleDivider = styled.div<SectionTitleDividerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 2.6rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  user-select: none;
  background-color: ${({ open, onClick, integratedCj2 }) =>
    onClick
      ? open
        ? integratedCj2
          ? 'none'
          : 'white'
        : 'var(--color-lightGray)'
      : integratedCj2
      ? 'none'
      : 'white'};

  &:before {
    display: ${({ open, onClick }) => (onClick ? (open ? 'block' : 'none') : 'none')};
    left: 2.6rem;
    width: calc(100% - 5.2rem);
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: var(--custom-colorAccent3);
  }

  transition: all 0.2s ease-in-out;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ onClick }) => onClick && 'var(--custom-colorHover)'};
    }
  }

  @media ${mediaSmallMax} {
    padding: 1rem;

    &:before {
      display: none;
    }
  }

  &.page-title {
    @media ${mediaSmallMax} {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }
  }
`;

export const SectionArrow = styled(arrow)<{ open: boolean }>`
  align-self: center;
  transition: transform 0.4s ease-in-out;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  fill: var(--color-accentColor);
  min-height: 16px;
  min-width: 26px;

  @media ${mediaSmallMax} {
    min-height: 24px;
    min-width: 14px;
    max-height: 24px;
    max-width: 14px;
  }
`;
