import { action } from 'mobx';
import { fetchUserRights, getProjectsWithApartments } from '../axios';
import appStore from './AppStore';
import shiroTrie from 'shiro-trie';

// TODO: this should be done in GB-API.
const getMaxUserLevelFromProjectsAndApartments = (projectsAndApartments: any): number => {
  const projectUserLevel = Math.max(...projectsAndApartments.map((p: any) => p.userLevel));

  const apartmentUserLevels: number[] = [];
  projectsAndApartments.forEach((p: any) => {
    p.apartments.forEach((a: any) => {
      apartmentUserLevels.push(a.userLevel);
    });
  });
  const apartmentUserLevel = Math.max(...apartmentUserLevels.map((userLevel: any) => userLevel));

  return Math.max(projectUserLevel, apartmentUserLevel);
};

class UserRightsStore {
  public shiroTrie: shiroTrie.ShiroTrie = shiroTrie.newTrie();
  public userRights: string[] = [];
  public userRightsFetched: boolean = false;
  public userLevel: number = -1;
  public fetchingUserLevel = false;

  public fetchUserRights = async () => {
    if (!this.userRightsFetched) {
      this.shiroTrie.reset();

      const { data } = await fetchUserRights(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId
      );

      this.shiroTrie.add(data.userRights);

      this.userRightsFetched = true;
    }
  };

  // This should be refactored after gb-api has this new endpoint available -> CU-2y8utm7.
  public getUserLevel = async () => {
    this.fetchingUserLevel = true;
    try {
      const response = await getProjectsWithApartments();
      this.userLevel = getMaxUserLevelFromProjectsAndApartments(response);
    } catch (e) {
      console.error(e);
    } finally {
      this.fetchingUserLevel = false;
    }
  };

  @action
  public check = (permission: string) => {
    return this.shiroTrie.check(permission);
  };
}

const userRightsStore = new UserRightsStore();

export default userRightsStore;
