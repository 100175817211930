import React, { useState } from 'react';
import { SectionTitleDivider } from '../Styled/SectionTitleDivider';
import styled from 'styled-components/macro';
import BundlesConfirmation from './BundlesConfirmation';
import Bundle from '../../store/models/Bundle';
import { observer } from 'mobx-react-lite';
import { mediaSmallMax } from '../Styled/media';
import { Accordion } from '@groupbuilderoy/gb-components-library';
import { marketplaceRooms } from '../../store/enums/MarketplaceRoomEnum';

export const RoomDivider = styled(SectionTitleDivider)`
  margin: 0 3rem;
  padding: 0;
  background: none;
  height: 3rem;

  &:hover {
    background: none;
  }

  &:before {
    left: 0;
    width: 100%;
    display: block;
  }

  @media ${mediaSmallMax} {
    margin: 0 1rem;
    padding: 1rem 0;

    &:before {
      opacity: 0.5;
    }
  }
`;

interface ConfirmationRoomProps {
  bundles: Bundle[];
  roomName: string;
  openAtStart?: boolean;
}

const ConfirmationRoom = ({ bundles, roomName, openAtStart }: ConfirmationRoomProps) => {
  const [isOpen] = useState(!!openAtStart);

  return marketplaceRooms.includes(roomName) ? (
    <BundlesConfirmation bundles={bundles} />
  ) : (
    <Accordion
      caption={roomName}
      customContent={<BundlesConfirmation bundles={bundles} />}
      type={'base' as any}
      isExpanded={isOpen}
      unlimitedHeight={true}
      style={{ margin: '0 3rem' }}
    />
  );
};

export default observer(ConfirmationRoom);
