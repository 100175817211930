import React from 'react';
import Modal from '../Modal/Modal';
import { changeParams } from '../../store/RoutingStore';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { createLink, supportMailLink } from './MarketplaceFAQ';

const Content = styled.div`
  padding: 3rem;
`;

const MarketplaceTermsAndConditions = () => {
  return (
    <Modal
      closeBtnOffset='3rem'
      onClose={() => changeParams({ marketplaceTermsAndConditions: undefined })}
      overflow='auto'
      style={{ height: '93vh !important' }}
    >
      <Content>
        <Typography>Group Builder Oy:n yleiset sopimusehdot kuluttajille</Typography>
        <br />
        <Typography variant='h6'>1. Asiakas</Typography>
        <Typography>
          Näissä sopimusehdoissa sovelletaan Group Builder Oy:n ja sen kuluttaja-asiakkaiden
          (jäljempänä “Asiakas”) välisessä kauppasuhteessa, johon soveltuu myös Suomen ja EU:n
          lainsäädäntöä kuluttajakaupan, markkinoinnin ja mainonnan{' '}
          {createLink('osalta', 'https://www.finlex.fi/fi/laki/ajantasa/1978/19780038')}. GBHomebook
          verkkokaupalla on oikeus päivittää näitä sopimusehtoja ilman ennakkoilmoitusta.
        </Typography>
        <br />
        <Typography variant='h6'>2. Asiakkuus ja tietosuoja</Typography>
        <Typography>
          Asiakkailta vaaditaan nimi, osoite, puhelinnumero ja sähköpostiosoite.
        </Typography>
        <br />
        <Typography variant='h6'>3. Tuotevalikoima</Typography>
        <Typography>
          Saatavuustiedot perustuvat maahantuojien ja valmistajien ilmoittamiin toimitusaikoihin.
          GBHomebook verkkokauppa tai sen käyttämät toimittajat eivät ole vastuussa vaikutuspiirinsä
          ulkopuolelta tulevien ennalta arvaamattomien muutosten aiheuttamista viivästyksistä eikä
          GBHomebook verkkokauppa takaa sellaisten tilattujen tuotteiden toimitusta, jotka eivät ole
          heti lähetettävissä.
          <br />
          GBHomebook verkkokauppa ei vastaa vahingosta, joka aiheutuu ylivoimaisesta esteestä.
          Ylivoimaisena esteenä pidetään sellaista ennalta arvaamatonta seikkaa tai olosuhteiden
          muutosta, joka on GBHomebook verkkokaupan vaikutusmahdollisuuksien ulkopuolella.
          GBHomebook verkkokauppa ilmoittaa asiakkaalle ylivoimaisesta esteestä viivytyksettä.
        </Typography>
        <br />
        <Typography variant='h6'>4. Toimittaminen</Typography>
        <Typography>
          Lähtökohtaisesti yksittäisen tilauksen tuotteet toimitetaan yhdellä kerralla. Tarvittaessa
          useita tuotteita sisältävät tilaukset lähetetään useammassa paketissa, jolloin on
          mahdollista, että kaikki tuotteet eivät saavu perille yhtä aikaa.
          <br />
          Mikäli kaikki tilauksen tuotteista eivät ole heti toimitettavissa, asiakas voi pyytää
          jakamaan tilauksen, jolloin puuttuvat tuotteet siirtyvät uuteen tilaukseen. GBHomebook
          verkkokauppa varaa oikeuden jakaa tilauksia yksipuolisesti, jolloin uuden tilauksen
          lähetys on maksuton.
        </Typography>
        <br />
        <Typography variant='h6'>5. Hinnoittelu ja maksutavat</Typography>
        <Typography>
          Tilaukset laskutetaan erikseen GBuilderin toimesta 14 päivän maksuehdolla tilauksen
          vahvistus hetkestä. Hinnat sisältävät arvonlisäveron ja toimituskulut, ellei toisin ole
          mainittu. GBuilder pidättää oikeuden perua tilauksen, jos tuote on myyty virheellisellä
          hinnalla hinnoitteluvirheen, järjestelmähäiriön tai muun vastaavan virhetilanteen vuoksi.
          <br />
          Hinnat tarkistetaan käsittelyhetkellä. Hinnan noustessa ennen tilauksen käsittelyä,
          myydään tuote tilauksentekohetken hinnalla.
        </Typography>
        <br />
        <Typography variant='h6'>6. Palauttaminen</Typography>
        <Typography>
          GBuilder tarjoaa asiakkailleen 14 päivän palautusoikeuden. Palautettavat tuotteet tulee
          pakata huolellisesti.
          <br />
          GBuilder palauttaa palautettuja tuotteita koskien saadut suoritukset viimeistään 14 päivän
          kuluttua siitä, kun palautetut tuotteet on saatu takaisin tai kunnes asiakas on
          todistettavasti lähettänyt tuotteet takaisin.
          <br />
          Palautusoikeuden rajoitukset määräytyvät voimassa olevan lainsäädännön mukaisesti.
          Kuluttajansuojalain 6. luvun 16 pykälän nojalla esimerkiksi seuraavilla tuotteilla ei ole
          palautusoikeutta:
          <ul>
            <li>
              Peleillä, elokuvilla, ohjelmistoilla tai muilla sinetöidyillä tuotteilla ei ole
              palautusoikeutta, kun tuotepakkaus on avattu.
            </li>
            <li>
              Hygienia- ja intiimituotteilla ei ole palautusoikeutta pakkauksen avaamisen jälkeen,
              koska tuote ei ole palautettavissa myyntiin luonteensa vuoksi. Hygienia- ja
              intiimituotteisiin kuuluvat muun muassa epilaattorit, partakoneet, ihokarvatrimmerit
              ja nenä/korvakarvatrimmerit, hiustenhoitolaitteet ja kotiparturilaitteet,
              sähköhammasharjat ja suuhygienialaitteet, kuume-, verenpaine- ja verensokerimittarit
              sekä alkometrit, nappikuulokkeet ja hands-free–laitteet sekä vaipat ja muut vastaavat
              lasten- ja lemmikkienhoitotuotteet.
            </li>
          </ul>
        </Typography>
        <br />
        <Typography variant='h6'>7. Takuu</Typography>
        <Typography>
          Takuu määräytyy valmistajan määrittelemien takuuehtojen mukaisesti. Takuu ei rajoita
          kuluttajansuojalaissa tarkoitettua myyjän virhevastuuta. Asiakkaan tulee tutustua tuotteen
          mukana toimitettuihin takuuehtoihin ennen tuotteen käyttöönottoa. Takuuaika ilmoitetaan
          ostokuitissa. Takuuehdot löytyvät käyttöohjeista, erillisestä takuulomakkeesta tai
          valmistajan osoittamasta verkko-osoitteesta. Takuu kattaa ainoastaan tuotteessa olevat
          valmistus- ja materiaalivirheet. Takuukorjaus tehdään aina valmistajan tai maahantuojan
          takuuehtojen mukaisesti. Takuun ulkopuoliset kustannukset ja selvästi aiheettoman
          huoltokäynnin kustannukset voidaan periä kokonaisuudessaan asiakkaalta.
          <br />
          GBuilder ei takaa, että kolmannen osapuolen ohjelmistot tai digitaaliset elementit
          olisivat käytettävissä koko tuotteen käyttöiän.
        </Typography>
        <br />
        <Typography variant='h6'>8. Erimielisyyksien ratkaiseminen</Typography>
        <Typography>
          Osapuolet pyrkivät neuvottelemaan erimielisyyksistä, mutta mikäli osapuolten välillä ei
          synny sopua, on kuluttaja-asiakkaalla oikeus saattaa tästä sopimuksesta syntyvät
          erimielisyydet{' '}
          {createLink(
            'kuluttajaneuvonnan',
            'https://www.kkv.fi/kuluttaja-asiat/kuluttajaneuvonta/'
          )}{' '}
          tietoon tai riita-asian{' '}
          {createLink('kuluttajariitalautakunnan', 'https://www.kuluttajariita.fi/fi/')}{' '}
          ratkaistavaksi. Kuluttajalla on lisäksi mahdollisuus käyttää Euroopan komission
          ylläpitämää verkkovälitteistä{' '}
          {createLink(
            'riidanratkaisufoorumia',
            'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FI'
          )}
          . Nämä menettelyt ovat kirjallisia Asiamieskuluja ei korvata, koska kuluttajaneuvonta ja
          kuluttajariitalautakunta tarjoaa veloituksetta apua erimielisyyksien ratkaisemiseen.
          Asiakas voi nostaa sopimuksen velkasuhteesta aiheutuvia riitaisuuksia koskevan kanteen
          Group Builder Oy:tä vastaan vakituisen asuinpaikkansa käräjäoikeudessa.
        </Typography>
        <br />
        <br />
        <Typography variant='h6'>Tietosuojalauseke</Typography>
        <br />
        <Typography variant='h6'>Yleistä</Typography>
        <Typography>
          GB Homebook on sitoutunut suojaamaan asiakkaidensa yksityisyyttä ja tarjoaa mahdollisuuden
          vaikuttaa tietojen käsittelyyn.
          <br />
          Tällä tietosuojaselosteella informoimme asiakkaitamme heidän henkilötietojensa
          käsittelystä. Asiakkaan on luettava ja ymmärrettävä tämän tietosuojaselosteen ehdot
          käyttääkseen GB Homebookin palveluita.
          <br />
          Keräämämme tiedot voidaan jakaa käyttäjän antamiin, verkkopalveluiden käytöstä
          havannoituihin sekä analytiikan avulla johdettuihin tietoihin.
          <br />
          Käytämme tietoja:
          <ul>
            <li>Helppokäyttöisen ja turvallisen palvelun toimittamiseen</li>
            <li>Hyvän asiakaskokemuksen tarjoamiseen</li>
            <li>Tuotesuositusten ja markkinoinnin parantamiseen</li>
            <li>Asiakaspalvelun ja verkkokaupan kehittämiseen</li>
          </ul>
        </Typography>
        <br />
        <Typography variant='h6'>Rekisterinpitäjä ja sen yhteystiedot</Typography>
        <Typography>
          Käsiteltävien henkilötietojen rekisterinpitäjänä toimii:
          <br />
          Group Builder Oy 2498051-7, Isokatu 82, 90120 Oulu
        </Typography>
        <br />
        <Typography variant='h6'>Mitä tietoja minusta voidaan kerätä?</Typography>
        <Typography>
          <ul>
            <li>Käyttäjän itsensä antamat tai henkilön tunnistavat tiedot </li>
            <ul>
              <li>Tunnistamistiedot, kuten nimi</li>
              <li>Yhteystiedot, kuten osoite, sähköpostiosoite ja puhelinnumero</li>
              <li>Maksutiedot, mukaan lukien luottosopimukset ja muut laskutustiedot</li>
              <li>
                Asiakkaan suostumuksella sijaintitiedot, joita käytetään toimitusajan arviointiin
              </li>
            </ul>
            <li>Palvelujen käytöstä havainnoidut ja analytiikan avulla johdetut tiedot</li>
            <ul>
              <li>Ostoshistoria, mm. tilatut tuotteet ja niiden hintatiedot</li>
              <li>Toimitustiedot, kuten valittu toimitustapa ja toimitusosoite</li>
              <li>Tuotearvostelut</li>
              <li>Verkkokaupan käyttö- ja selaustiedot sekä päätelaitteen tunnistetiedot</li>
              <li>Tuotesuositus- ja muut kohdennettuun sisältöön käytetyt tiedot ja tunnisteet</li>
            </ul>
          </ul>
          Tunniste-, yhteys- ja maksutietojen antaminen on pakollista. Tietojen pääasiallinen lähde
          on käyttäjä itse, minkä lisäksi saatamme saada lisätietoja yhteistyökumppaneiltamme,
          esimerkiksi luottopalvelujen tarjoajalta. Kolmansilta osapuolilta saamistamme
          henkilötiedoista ilmoitamme tietosuoja-asetuksen määräysten mukaisesti ensimmäisessä
          yhteydenotossa asiakkaaseen tai viimeistään kuukauden sisällä henkilötietojen
          vastaanotosta.
        </Typography>
        <br />
        <Typography variant='h6'>Mihin henkilötietojani käytetään?</Typography>
        <Typography>
          Henkilötietoja käytetään
          <ul>
            <li>Asiakkuuden ylläpitämiseen</li>
            <li>Tilausten toimitukseen, käsittelyyn ja arkistointiin</li>
            <li>GB Homebookin toiminnan ja palveluiden kehittämiseen</li>
            <li>Asiakaskokemuksen parantamiseen</li>
            <li>Analytiikkaan ja tilastollisiin tarkoituksiin</li>
            <li>Henkilökohtaisemman kohdennetun sisällön ja markkinoinnin tuottamiseen</li>
            <li>Väärinkäytösten estämiseen</li>
            <li>Paremman asiakaspalvelun tarjoamiseen</li>
          </ul>
          Tietoja käsitellään asiakkaan ja GB Homebookin väliseen asiakassuhteeseen, sopimukseen,
          sivuston käyttöön, asiakkaan erilliseen nimenomaiseen suostumukseen tai lainsäädännön
          velvoitteisiin perustuen.
        </Typography>
        <br />
        <Typography variant='h6'>Miten tietojani säilytetään ja suojataan?</Typography>
        <Typography>
          Kaikki henkilötiedot ovat suojattu asiattomalta pääsyltä ja vahingossa tai laittomasti
          tapahtuvalta tietojen hävittämiseltä, muuttamiselta, luovuttamiselta, siirtämiseltä tai
          muulta laittomalta käsittelemiseltä.
          <br />
          GB Homebook säilyttää asiakastietoja EU-alueella. Konesalit ja kaupan järjestelmien
          tekninen sekä prosessien tietoturva ovat erittäin korkealla tasolla. Palvelimet on
          suojattu tietomurtoja ja palvelunestohyökkäyksiä vastaan.
          <br />
          Henkilötietojen käsittelyssä ja teknisissä ratkaisuissa noudatamme hyviä
          tietosuojakäytäntöjä mm. tiedon konsolidointi, minimointi, pseudonymisointi, anonymisointi
          ja enkryptaus. Henkilötietojen käsittelyssä on huomioitu 25.5.2018 alkaen sovellettavan
          EU:n tietosuoja-asetuksen vaatimukset. Kaikkea pääsyä henkilötietoon valvotaan hyvien
          käytäntöjen mukaisesti.
        </Typography>
        <br />
        <Typography variant='h6'>Kuka käsittelee henkilötietojani?</Typography>
        <Typography>
          Asiakasrekisteriin on pääsy vain GB Homebookin omilla työntekijöillä ****ja
          henkilökuntamme on koulutettu käyttämään tietoja turvallisesti ja eettisesti. Kukin
          henkilökunnastamme näkee asiakastietoja vain niiltä osin kuin se on työtehtävien
          suorittamiselle välttämätöntä.
          <br />
          Käytämme luotettuja sopimuskumppaneita, jolloin tietoa voidaan siirtää kolmannelle
          osapuolelle. Kaikkien kumppanien kanssa on sopimuksissa huomioitu EU:n
          tietosuoja-asetuksen ja muun lainsäädännön asettamat vaatimukset.
          <br />
          Tietojen käsittelystä vastuussa oleva yritys:
          <br />
          Group Builder Oy Isokatu 82, 90120 Oulu
        </Typography>
        <br />
        <Typography variant='h6'>Kuinka kauan tietojani säilytetään?</Typography>
        <Typography>
          Säilytämme henkilökohtaisia tietojasi vain tarvittavan ajan, jotta voimme täyttää tässä
          selosteessa kuvatut käyttötarkoitukset. Lisäksi joitain tietoja voidaan säilyttää kauemmin
          niiltä osin kuin se on tarpeen laissa asetettujen velvollisuuksien, kuten kirjanpitoa ja
          kuluttajakauppaa koskevien vastuiden, toteuttamiseksi ja niiden asianmukaisen
          toteuttamisen osoittamiseksi.
          <br />
          Kun teet tilauksen tallennetaan antamasi tiedot GB Homebookin järjestelmään, jotta voimme
          pitää sinut ajan tasalla tilauksestasi.
          <br />
          Asiakkaan pyynnöstä häntä koskeva henkilötieto voidaan poistaa tai anonymisoida GB
          Homebookin järjestelmistä. Poisto ja anonymisointi-toimenpide on peruuttamaton. Osalle
          tietoa lainsäädäntö asettaa velvoitteita tiedon pidempiaikaiselle tallentamiselle, mm.
          seuraaviin tarkoituksiin:
          <ul>
            <li>
              Kirjanpitolaki määrittelee tiedolle pidempiä säilytysaikoja riippumatta siitä
              sisältääkö aineisto henkilötietoja tai ei
            </li>
            <li>Kuluttajakauppaa koskevien vastuiden täyttäminen</li>
            <li>
              Järjestelmien lokitietoa kerätään ja säilytetään lain edellyttämällä tavalla, jotta
              voimme tarjota lainmukaisen ja turvallisen verkkokaupan asiakkaillemme
            </li>
            <li>
              Riittävien varmuuskopioiden ottaminen kaupan tietokannoista ja järjestelmistä tietojen
              turvaamiseksi, virhetilanteiden korjaamiseksi ja tietoturvan sekä jatkuvuuden
              varmentamiseksi
            </li>
          </ul>
        </Typography>
        <br />
        <Typography variant='h6'>Millaisia oikeuksia minulla on?</Typography>
        <Typography>
          Asiakkaana sinulla on oikeus:
          <br />
          <ul>
            <li>
              Saada pääsy itseäsi koskeviin henkilötietoihin, mukaan lukien oikeus saada jäljennös
              sinua koskevista henkilötiedoista
            </li>
            <li>Pyytää itseäsi koskevien henkilötietojen oikaisemista tai poistamista</li>
            <li>
              Tietyin edellytyksin pyytää käsittelyn rajoittamista tai vastustaa henkilötietojen
              käsittelyä
            </li>
          </ul>
          Lisäksi, jos käsittely perustuu erilliseen suostumukseen, sinulla on oikeus peruuttaa
          suostumuksesi milloin tahansa. Huomaathan, ettei tämä vaikuta ennen suostumuksen
          peruuttamista suoritettuun tietojen käsittelyn lainmukaisuuteen.
          <br />
          Voit tehdä oikeuksiesi käyttämistä koskevan pyynnön ottamalla yhteyttä asiakaspalveluumme.
          Pyynnön tulee olla riittävällä tavalla yksilöity, jotta asiakaspalvelumme voi todentaa
          henkilöllisyytesi. Ilmoitamme sinulle, mikäli emme joiltain osin pysty toteuttamaan
          pyyntöäsi, kuten poistamaan kaikkia tietoja, joiden säilyttämiseen meillä on lakisääteinen
          velvollisuus (esimerkiksi luotonantotiedot) tai oikeus.
          <br />
          Jos huomaat, että käsittelyssä on puutteita tai se on lainvastaista, sinulla on oikeus
          tehdä asiasta valitus{' '}
          {createLink('tietosuojaviranomaiselle', 'https://tietosuoja.fi/etusivu')}.
        </Typography>
        <br />
        <Typography variant='h6'>
          Kuinka saan järjestelmään itsestäni tallennetut tiedot?
        </Typography>
        <Typography>
          Voit pyytää GBHomebookin järjestelmiin tallennetut tiedot ottamalla yhteyttä
          sähköpostilla: {supportMailLink}.
        </Typography>
        <br />
        <Typography variant='h6'>
          Luovutetaanko henkilötietojani kolmansille osapuolille?
        </Typography>
        <Typography>
          Voimme luovuttaa joitakin välttämättömiä tietoja kolmansille osapuolille toimituksen
          takaamiseksi sekä markkinointia varten. Tietosi välittyvät myös luottopäätöksen yhteydessä
          luottoa myöntävälle taholle.
          <br />
          Käytämme asiakastietoja kolmansien osapuolten kanssa myös analytiikka- ja
          personointitarkoituksiin. Hyödynnämme ostokäyttäytymis- ja selaustietoja
          yhteistyökumppanien kanssa, jotta voimme paremmin tarjota sinua kiinnostavia tuotteita ja
          tarjouksia. Analytiikka- ja personointitarkoituksiin käytetty tieto on anonymisoitua tai
          pseudonymisoitua aina mahdollisuuksien mukaan. Vain me pystymme yhdistämään käytetyn
          pseudonymisoidun tiedon nimeesi.
          <br />
          Tarvittaessa luovutamme tietoja myös viranomaisille. Informoimme tietopyynnöistä aina myös
          asiakasta, jos se on lain puitteissa sallittua.
          <br />
          Varaamma oikeuden välitämme tietoja seuraaville kolmansille osapuolille:
          <ul>
            <li>Analytiikka- ja tilastointikumppaneille</li>
            <li>Tuotesuositus- ja personointikumppaneille</li>
            <li>
              Sähköpostimarkkinointikumppaneille, jos asiakas on sallinut viikkokirjeen,
              selaushistoriaan perustuvan viestinnän tai tuotearvostelupyynnöt
            </li>
            <li>SMS-kumppanille, kun tekstiviestien lähetys on sallittu</li>
            <li>
              Kuljetusliikkeelle, jos valittu toimitustapa on kuljetus noutopisteeseen, lähimpään
              postiin tai perille asti
            </li>
            <li>Maksunvälittäjälle maksukortilla maksaessa</li>
            <li>
              Luotonmyöntäjälle, asiakkaan valitessa maksutavaksi luottoyhtiön laskun tai osamaksun
            </li>
            <li>Laskuoperaattorille, kun maksutavaksi valitaan GB Homebookin oma lasku</li>
            <li>
              Tuotteen toimittajalle, sähköisiä lisenssejä tai suoratoimitustuotteita tilattaessa
            </li>
            <li>
              Osalle huoltoyhtiöistä välitetään tilaukseen liittyviä tietoja huollon yhteydessä
            </li>
            <li>Tullille, tuotteita verottomana ostettaessa</li>
            <li>Perintäyhtiölle, laskujen erääntyessä ja siirtyessä perintään</li>
          </ul>
          GB Homebook huolehtii tietoja siirrettäessä ja käsiteltäessä tietoturvan ja -suojan
          korkeasta tasosta EU:n tietosuoja-asetuksen mukaisesti. Kolmansien osapuolten
          tietojenkäsittelyn tason varmistuskeinoina ovat Euroopan komission hyväksymät
          mallisopimuslausekkeet, konsernia sitovat tietosuojasäännökset (BCR-järjestely) tai muu
          tietosuoja-asetuksen mukainen hyväksytty tapa.
        </Typography>
        <br />
        <Typography variant='h6'>Voidaanko tätä tietosuojaselostetta muuttaa?</Typography>
        <Typography>
          Palveluiden kehityksen ja läinsäädännön muutosten johdosta pidätämme oikeuden muuttaa
          tietosuojaselostetta. Merkittävistä muutoksista tietosuojaselosteeseen ilmoitetaan
          rekisteröityneille asiakkaille ehtojen päivityksen yhteydessä.
        </Typography>
        <br />
        <Typography variant='h6'>Mihin voin ottaa yhteyttä?</Typography>
        <Typography>
          Voit ottaa yhteyttä yksityisyyteen, tietojen käsittelyyn ja tietosuojaselosteeseen
          liittyvissä kysymyksissä {supportMailLink}.
        </Typography>
      </Content>
    </Modal>
  );
};

export default observer(MarketplaceTermsAndConditions);
