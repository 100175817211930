import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import styled from 'styled-components/macro';
import appStore from '../../store/AppStore';
import { text, isLocked, isOpen, decimalToLocale } from '../../utils';
import moment from 'moment';
import Modal from '../Modal/Modal';
import { Link, NavLink } from 'react-router-dom';
import CheckSolid from '../Icons/CheckSolid';
import themeStore from '../../store/ThemeStore';
import ConfirmationPhase from './ConfirmationPhase';
import { getTotalPrice } from './utils';
import { mediaSmallMax } from '../Styled/media';
import {
  getLockedApartmentBundles,
  getOpenApartmentBundles,
  createConfirmByText,
  getBundleRooms
} from './utils';
import { FormattedTextSection } from '../Styled/FormattedTextSection';
import Bundle from '../../store/models/Bundle';
import { OrderConfirmationButton } from './styledComponents';
import { marketplaceRooms } from '../../store/enums/MarketplaceRoomEnum';
import offersStore from '../../store/OffersStore';
import OffersConfirmation from '../OffersConfirmation/OffersConfirmation';
import roomsStore from '../../store/RoomsStore';
import { ButtonType, IconKeys, InfoBox } from '@groupbuilderoy/gb-components-library';
import { Header } from '../Header/headerStyledComponents';
import HeaderText from '../HeaderText';
import { Typography } from '@material-ui/core';
import MarketplaceInformation from '../Marketplace/MarketplaceInformation';
import confirmationDialogStore from '../../store/ConfirmationDialogStore';
import AuxiliarButton from '../../auxiliaries/AuxiliarButton';

const StyledConfirmationPhases = styled.div<{ hide?: boolean }>`
  margin-bottom: 3rem;
  display: flex;
  visibility: ${({ hide }) => {
    return hide ? 'hidden' : 'visible';
  }};
  flex-direction: column;

  @media ${mediaSmallMax} {
    margin-bottom: 1.5rem;
  }
`;

const OrderConfirmation = styled(FormattedTextSection)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  & > * {
    max-width: 25rem;
    margin-bottom: 1rem;
  }

  img {
    margin-bottom: 3rem;
  }

  a {
    margin-top: 2rem;
  }
`;

const StyledCheck = styled(CheckSolid)`
  fill: #294754;
  border: 5px solid #294754;
  border-radius: 100%;
  width: 76px;
  height: 76px;
  margin-bottom: 3rem;
  padding: 15px;
`;

const OrderConfirmationDescription = styled.p`
  line-height: 1.5rem;
`;

interface ConfirmationPhasesProps {
  hide?: boolean;
}

const ConfirmationPhases = ({ hide }: ConfirmationPhasesProps) => {
  const {
    roomsByDeadlines,
    marketplaceRoomsByDeadlines,
    confirmBundles,
    roomsWithoutDeadlinesExcludingOffers,
    apartmentBundlesExcludingOffers,
    orderConfirmed,
    setOrderConfirmed,
    apartmentBundlesWithoutDLExcludingOffers,
    roomsWithOpenAndLockableBundlesExcludingOffers,
    roomsWithOpenAndLockableBundlesWithoutDlExcludingOffers,
    roomsWithOpenAndLockableBundlesWithDlExcludingOffers,
    roomsWithLockedBundlesExcludingOffers,
    marketplaceRoomsWithoutDeadlinesExcludingOffers,
    marketplaceRoomsWithLockedBundlesExcludingOffers,
    someBundlesAreOpenExcludingOffers,
    someBundlesWithoutDlAreOpenExcludingOffers,
    someBundlesAreLockedExcludingOffers
  } = materialSelectorStore;
  const { themesFetched, fetchThemeSets } = themeStore;
  const { apartment } = appStore;
  const lockingByDeadline = appStore.tenantSettings.customerJourney2_isLockingByDeadlineEnabled;
  const showConfirmAllSelectionsButton =
    appStore.tenantSettings.customerJourney2_alwaysShowConfirmAllSelections;

  useEffect(() => {
    if (!themesFetched && apartment) {
      fetchThemeSets();
    }
  }, [apartment, themesFetched, fetchThemeSets]);

  const showUnconfirmed =
    (!lockingByDeadline && someBundlesAreOpenExcludingOffers) ||
    roomsWithoutDeadlinesExcludingOffers.some(
      (r) => !marketplaceRooms.includes(r.name) && !!r.openBundlesWithNoDlExcludingOffers.length
    ) ||
    apartmentBundlesWithoutDLExcludingOffers.some((b) => isOpen(b) && !b.isPartOfOffer);

  const showUnconfirmedWithoutDl =
    someBundlesWithoutDlAreOpenExcludingOffers &&
    !!roomsWithOpenAndLockableBundlesWithDlExcludingOffers.filter(
      (r) => !marketplaceRooms.includes(r.name)
    ).length &&
    lockingByDeadline;

  const showConfirmed =
    (!lockingByDeadline && someBundlesAreLockedExcludingOffers) ||
    roomsWithoutDeadlinesExcludingOffers.some(
      (r) => !marketplaceRooms.includes(r.name) && !!r.lockedBundlesWithNoDl.length
    ) ||
    apartmentBundlesWithoutDLExcludingOffers.some((b) => isLocked(b));

  const lockedApartmentBundlesWithoutDLExcludingOffers =
    apartmentBundlesWithoutDLExcludingOffers.filter(
      (ab) => isLocked(ab) && !ab.hasAppliedToDbOffer && !ab.hasOpenOffer
    );

  const openApartmentBundlesWithoutDLExcludingOffers =
    apartmentBundlesWithoutDLExcludingOffers.filter(
      (ab) => !isLocked(ab) && !ab.hasAppliedToDbOffer && !ab.hasOpenOffer
    );

  const openApartmentBundlesExcludingOffers = apartmentBundlesExcludingOffers.filter(
    (b) => !isLocked(b)
  );
  const lockedApartmentBundlesExcludingOffers = apartmentBundlesExcludingOffers.filter((b) =>
    isLocked(b)
  );

  const openAtStartIndex = roomsByDeadlines.findIndex((dl) => {
    return (
      !(dl.deadline && moment(dl.deadline).isBefore(moment())) &&
      dl.rooms.some(
        (r) => !!(r.openBundles && r.openBundles.some((b) => b.deadline === dl.deadline))
      )
    );
  });

  const allOpenBundles = roomsWithOpenAndLockableBundlesExcludingOffers
    .reduce((pre, cur) => {
      pre.push(
        ...cur.openBundles.filter((b) => {
          return !b.isGeneralAppearanceMaterial && !b.isPartOfOffer;
        })
      );

      return pre;
    }, [] as Bundle[])
    .concat(openApartmentBundlesExcludingOffers);

  /*  First Block: We iterate through the roomsByDeadlines array
      For each deadline we show a confirmationPhase (this block can render up to 2 confirmationPhases, in case that there is a deadline
      that has some bundles confirmed but others open). 

      Second Block: We iterate through the AppliedOffers array and for each offer, we show those bundles that belong to that appliedToDbOffer.
      This block is always confirmed. (Open offers are not shown on confirmation view, by design).

      Third Block: By default, we show the open apartmentBundles without deadline. But when lockingByDeadline is set to false, we show all open
      bundles, as user should be able to use this block to confirm all open bundles.

      Fourth Block: Same as third block but for locked ones.
      
  */

  const marketplaceExists = roomsStore.rooms.some((r) => marketplaceRooms.includes(r.name));

  return (
    <>
      {appStore.integratedApp && (
        <Header integratedCj2={appStore.integratedApp} style={{}}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NavLink to={'/materials'} style={{ borderBottom: 'none' }} id='navigateToMaterials'>
              <AuxiliarButton
                style={{ margin: '1rem 0' }}
                type={ButtonType.outlined}
                caption={text('returnToMaterials')}
                startIcon={IconKeys.longArrowLeft}
                onClick={(event) => {
                  if (document.getElementById('preventUnload')) {
                    event.preventDefault();
                    confirmationDialogStore.open(
                      {
                        confirmationTitle: text('offerModal.attention'),
                        confirmationText: text('offerModal.modalText'),
                        okText: text('offerModal.viewOffer'),
                        cancelText: text('offerModal.closeOffer')
                      },
                      () => {
                        return null;
                      },
                      () => {
                        confirmationDialogStore.close();
                        document.getElementById('navigateToMaterials')?.click();
                      }
                    );
                  }
                }}
              />
            </NavLink>
          </div>
        </Header>
      )}

      {marketplaceExists && (
        <div style={{ marginBottom: '3rem' }}>
          <HeaderText
            style={{ margin: '1rem auto 2rem auto' }}
            heading={text('marketplace.confirmationHeader')}
          />
          <InfoBox
            variant={'contained' as any}
            icon={IconKeys.shoppingCart}
            title={undefined}
            color={'rgba(56, 194, 251, 0.2)'}
            customContent={
              <>
                <Typography>
                  {text('marketplace.confirmationPageText')}{' '}
                  {
                    <a href='mailto:support@gbhomebook.com?subject = Feedback&body = Message'>
                      <span>support@gbhomebook.com</span>
                    </a>
                  }
                </Typography>
                <br />
                <MarketplaceInformation />
              </>
            }
          />

          {lockingByDeadline &&
            marketplaceRoomsByDeadlines.map((r, i) => {
              const apartmentBundlesWithDl = apartmentBundlesExcludingOffers.filter(
                (b) => b.deadline === r.deadline
              );

              const deadlineIsNear = r.deadline
                ? moment(r.deadline).add(-2, 'weeks').isBefore(moment())
                : false;

              const deadlineGone = r.deadline
                ? moment(r.deadline).isBefore(moment()) &&
                  r.rooms.some((room) =>
                    room.bundles
                      ? room.bundles.some((b) => (b.deadline === r.deadline ? !isLocked(b) : false))
                      : false
                  )
                : false;

              const lockedApartmentBundlesWithDlExcludingOffers = getLockedApartmentBundles(
                apartmentBundlesWithDl
              ).filter((b) => !b.isPartOfOffer);
              const openApartmentBundlesWithDlExcludingOffers = getOpenApartmentBundles(
                apartmentBundlesWithDl
              ).filter((b) => !b.isPartOfOffer);
              const bundleRoomsExcludingOffers = getBundleRooms(r);
              const hasLockedRoomBundlesExcludingOffers = bundleRoomsExcludingOffers.some(
                (br) =>
                  !!br.lockedBundles.filter((b) => !b.isPartOfOffer).length ||
                  !!br.deadlineGoneBundles.filter((b) => !b.isPartOfOffer).length
              );
              const hasOpenRoomBundlesExcludingOffers = bundleRoomsExcludingOffers.some(
                (br) => !!br.openBundles.filter((b) => !b.isPartOfOffer).length
              );

              return (
                <Fragment key={`room-${r.deadline}`}>
                  {(!!lockedApartmentBundlesWithDlExcludingOffers.length ||
                    hasLockedRoomBundlesExcludingOffers) && (
                    <ConfirmationPhase
                      key={`${r.deadline}-locked`}
                      rooms={r.rooms.filter((r) => marketplaceRooms.includes(r.name))}
                      deadline={r.deadline}
                      apartmentBundles={[]}
                      confirm={confirmBundles}
                      confirmBy={createConfirmByText(deadlineGone, true, r.deadline)}
                      deadlineGone={deadlineGone}
                      bundlesLocked
                      deadlineIsNear={deadlineIsNear}
                      isMarketplacePhase={true}
                    />
                  )}
                  {(!!openApartmentBundlesWithDlExcludingOffers.length ||
                    hasOpenRoomBundlesExcludingOffers) && (
                    <ConfirmationPhase
                      key={`${r.deadline}-open`}
                      rooms={r.rooms.filter((r) => marketplaceRooms.includes(r.name))}
                      deadline={r.deadline}
                      apartmentBundles={[]}
                      confirm={confirmBundles}
                      confirmBy={createConfirmByText(deadlineGone, false, r.deadline)}
                      openAtStart={i === openAtStartIndex}
                      deadlineGone={deadlineGone}
                      deadlineIsNear={deadlineIsNear}
                      isMarketplacePhase={true}
                    />
                  )}
                </Fragment>
              );
            })}
          <ConfirmationPhase
            key={'confirmed'}
            apartmentBundles={[]}
            rooms={
              lockingByDeadline
                ? marketplaceRoomsWithoutDeadlinesExcludingOffers
                : marketplaceRoomsWithLockedBundlesExcludingOffers
            }
            confirmBy={text('confirmed')}
            openAtStart={false}
            bundlesLocked={true}
          />
        </div>
      )}

      <HeaderText
        style={{ margin: '1rem auto 2rem auto' }}
        heading={text('orderConfirmationPageTitle')}
        description={text('confirmationGuidance')}
      />

      <OffersConfirmation offers={offersStore.acceptedAndOpenOffers} />

      <StyledConfirmationPhases hide={hide}>
        {/* First Block */}
        {lockingByDeadline &&
          roomsByDeadlines.map((r, i) => {
            const apartmentBundlesWithDl = apartmentBundlesExcludingOffers.filter(
              (b) => b.deadline === r.deadline
            );

            const deadlineIsNear = r.deadline
              ? moment(r.deadline).add(-2, 'weeks').isBefore(moment())
              : false;

            const deadlineGone = r.deadline
              ? moment(r.deadline).isBefore(moment()) &&
                r.rooms.some((room) =>
                  room.bundles
                    ? room.bundles.some((b) => (b.deadline === r.deadline ? !isLocked(b) : false))
                    : false
                )
              : false;

            const lockedApartmentBundlesWithDlExcludingOffers = getLockedApartmentBundles(
              apartmentBundlesWithDl
            ).filter((b) => !b.isPartOfOffer);
            const openApartmentBundlesWithDlExcludingOffers = getOpenApartmentBundles(
              apartmentBundlesWithDl
            ).filter((b) => !b.isPartOfOffer);
            const bundleRoomsExcludingOffers = getBundleRooms(r);
            const hasLockedRoomBundlesExcludingOffers = bundleRoomsExcludingOffers.some(
              (br) =>
                !!br.lockedBundles.filter((b) => !b.isPartOfOffer).length ||
                !!br.deadlineGoneBundles.filter((b) => !b.isPartOfOffer).length
            );
            const hasOpenRoomBundlesExcludingOffers = bundleRoomsExcludingOffers.some(
              (br) => !!br.openBundles.filter((b) => !b.isPartOfOffer).length
            );

            return (
              <Fragment key={`room-${r.deadline}`}>
                {(!!lockedApartmentBundlesWithDlExcludingOffers.length ||
                  hasLockedRoomBundlesExcludingOffers) && (
                  <ConfirmationPhase
                    key={`${r.deadline}-locked`}
                    rooms={r.rooms.filter((r) => !marketplaceRooms.includes(r.name))}
                    deadline={r.deadline}
                    apartmentBundles={lockedApartmentBundlesWithDlExcludingOffers}
                    confirm={confirmBundles}
                    confirmBy={createConfirmByText(deadlineGone, true, r.deadline)}
                    deadlineGone={deadlineGone}
                    bundlesLocked
                    deadlineIsNear={deadlineIsNear}
                  />
                )}
                {(!!openApartmentBundlesWithDlExcludingOffers.length ||
                  hasOpenRoomBundlesExcludingOffers) && (
                  <ConfirmationPhase
                    key={`${r.deadline}-open`}
                    rooms={r.rooms.filter((r) => !marketplaceRooms.includes(r.name))}
                    deadline={r.deadline}
                    apartmentBundles={openApartmentBundlesWithDlExcludingOffers}
                    confirm={confirmBundles}
                    confirmBy={createConfirmByText(deadlineGone, false, r.deadline)}
                    openAtStart={i === openAtStartIndex}
                    deadlineGone={deadlineGone}
                    deadlineIsNear={deadlineIsNear}
                  />
                )}
              </Fragment>
            );
          })}

        {/* Second Block */}
        {showUnconfirmedWithoutDl && (
          <ConfirmationPhase
            key={'later'}
            apartmentBundles={openApartmentBundlesWithoutDLExcludingOffers}
            rooms={roomsWithOpenAndLockableBundlesWithoutDlExcludingOffers.filter(
              (r) => !marketplaceRooms.includes(r.name)
            )}
            confirm={confirmBundles}
            confirmBy={text('unconfirmedWithoutDl')}
            openAtStart={true}
            lockingByDeadline={true}
            withoutDeadline={true}
          />
        )}

        {/* Third Block */}
        {showUnconfirmed && (
          <ConfirmationPhase
            key={'later'}
            apartmentBundles={openApartmentBundlesExcludingOffers}
            rooms={roomsWithOpenAndLockableBundlesExcludingOffers.filter(
              (r) => !marketplaceRooms.includes(r.name)
            )}
            confirm={confirmBundles}
            confirmBy={showUnconfirmedWithoutDl ? text('allUnconfirmed') : text('unconfirmed')}
            openAtStart={true}
            lockingByDeadline={false}
          />
        )}

        {/* Fourth Block */}
        {showConfirmed && (
          <ConfirmationPhase
            key={'confirmed'}
            apartmentBundles={
              lockingByDeadline
                ? lockedApartmentBundlesWithoutDLExcludingOffers
                : lockedApartmentBundlesExcludingOffers
            }
            rooms={
              lockingByDeadline
                ? roomsWithoutDeadlinesExcludingOffers.filter(
                    (r) => !marketplaceRooms.includes(r.name)
                  )
                : roomsWithLockedBundlesExcludingOffers.filter(
                    (r) => !marketplaceRooms.includes(r.name)
                  )
            }
            confirmBy={text('confirmed')}
            openAtStart={roomsByDeadlines.length === 0 && !showUnconfirmed}
            bundlesLocked={true}
          />
        )}

        {showConfirmAllSelectionsButton && !!allOpenBundles.length && (
          <OrderConfirmationButton
            className='larger-mobile'
            disabled={materialSelectorStore.fetchingBundles}
            onClick={() =>
              confirmBundles(
                allOpenBundles.map((b) => b.id),
                `${decimalToLocale(getTotalPrice(allOpenBundles))} ${appStore.currencySymbol}`
              )
            }
          >
            {text('confirmAllSelections')}
          </OrderConfirmationButton>
        )}
        {orderConfirmed && (
          <Modal
            onClose={() => setOrderConfirmed(false)}
            contentProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }
            }}
          >
            <OrderConfirmation>
              <StyledCheck />
              <p>{text('thankyou')}</p>
              <OrderConfirmationDescription>
                {appStore.tenantSettings.esign_requireForMaterialLocking
                  ? text('thankyouEsignDescription')
                  : text('thankyouDescription')}
                {appStore.organisationId === 'bonava-de' && (
                  <>
                    <br />
                    <a href='https://forms.office.com/e/UwUxKMtmdK' target='blank'>
                      Hier geht’s zur Umfrage
                    </a>
                  </>
                )}
              </OrderConfirmationDescription>
              <Link onClick={() => setOrderConfirmed(false)} to='/materials'>
                {text('returnLink')}
              </Link>
            </OrderConfirmation>
          </Modal>
        )}
      </StyledConfirmationPhases>
    </>
  );
};

export default (withRouter as any)(observer(ConfirmationPhases));
