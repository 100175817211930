import { observable } from 'mobx';
import AttachmentDecorator from './AttachmentDecorator';
import AttachmentInterface from '../interfaces/AttachmentInterface';
import { SectionInterface } from '../interfaces/SectionInterface';

class OfferAttachmentDecorator extends AttachmentDecorator {
  @observable public offerId: number;
  @observable public offerRowId: number | null;
  @observable public targetSection: SectionInterface | null;

  public constructor(
    attachment: AttachmentInterface,
    source: {
      offerId: number;
      offerRowId: number | null;
      targetSection: SectionInterface | null;
    }
  ) {
    super(attachment);
    this.offerId = source.offerId;
    this.offerRowId = source.offerRowId;
    this.targetSection = source.targetSection;
  }
}

export default OfferAttachmentDecorator;
