import { AxiosInstance } from 'axios';
import instance, { baseURL } from '../axios';

abstract class GbApiRepository {
  protected axios: AxiosInstance;
  protected baseUrl: string;

  public constructor() {
    this.axios = instance;
    this.baseUrl = baseURL;
  }
}

export default GbApiRepository;
