import React, { useState } from 'react';
import { text, scrollTo } from '../../utils';
import styled from 'styled-components';
import { mediaMediumMin } from '../Styled/media';
import RoomsList from './RoomsList';
import Room from '../../store/models/Room';
import SectionResizer from '../SectionResizer/SectionResizer';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { Element } from 'react-scroll';
import { scrollToMaterialSelector } from './Materials';

const SelectedRoom = styled.h2``;

const RoomsOpener = styled.p`
  text-decoration: underline;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;

  &.open {
    // triangle
    &:after {
      content: '';
      bottom: -0.5rem;
      left: calc(50% - 0.5rem);
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 13.9px 8px; // 1rem width
      border-color: transparent transparent var(--color-lightGray) transparent;
    }
  }
`;

const StyledRoomSelectorMobile = styled(Element)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem 1rem 0.5rem 1rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    height: 1px;
    opacity: 0.5;
    background-color: var(--custom-colorAccent3);
  }

  @media ${mediaMediumMin} {
    display: none;
  }
`;

const RoomsListWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  background-color: var(--color-lightGray);
  margin-bottom: 1rem;

  .rooms-list {
    padding: 1.5rem 1rem 0 1rem;
    margin: 0;

    &:after {
      content: unset;
    }
  }

  @media ${mediaMediumMin} {
    display: none;
  }
`;

interface RoomSelectorMobileProps {
  rooms: Room[];
  onSelect: (roomId?: number) => void;
  generalDeadlineNear: boolean;
  selectedRoomName: string;
}

const RoomSelectorMobile = ({
  rooms,
  onSelect,
  generalDeadlineNear,
  selectedRoomName
}: RoomSelectorMobileProps) => {
  const [roomsListHeight, setRoomsListHeight] = useState('0px');
  const [isOpen, setIsOpen] = useState(false);

  const onSelectRoom = (roomId?: number) => {
    setIsOpen(false);
    onSelect(roomId);
    scrollToMaterialSelector();
  };

  const onOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen) scrollTo('mobile-rooms');
  };

  return (
    <>
      <StyledRoomSelectorMobile name='mobile-rooms'>
        <SelectedRoom className='font-bold'>{selectedRoomName}</SelectedRoom>
        <RoomsOpener
          className={classnames({
            'font-normal': true,
            open: isOpen
          })}
          onClick={onOpen}
        >
          {text('changeRoom')}
        </RoomsOpener>
      </StyledRoomSelectorMobile>
      <RoomsListWrapper style={{ maxHeight: roomsListHeight, minHeight: roomsListHeight }}>
        <SectionResizer effectListeners={[rooms.length, isOpen]} setNewHeight={setRoomsListHeight}>
          {isOpen && (
            <RoomsList
              className='rooms-list'
              rooms={rooms}
              onSelect={onSelectRoom}
              generalDeadlineNear={generalDeadlineNear}
            />
          )}
        </SectionResizer>
      </RoomsListWrapper>
    </>
  );
};

export default observer(RoomSelectorMobile);
