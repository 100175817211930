import React from 'react';
import styled from 'styled-components/macro';
import Bundle from '../../store/models/Bundle';
import { text, isLocked } from '../../utils';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import ThemeSetCard from '../Themes/ThemeSetCard';
import MaterialCard from './MaterialCard';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import roomsStore from '../../store/RoomsStore';
import themeStore from '../../store/ThemeStore';
import Notification from '../Notification/Notification';
import InfoIcon from '../Icons/Info';
import { mediaSmallMax, mediaXSmallMax } from '../Styled/media';
import { modalView } from '../../services';
import { ModalView } from '../../services/ModalView';
import InfoBox from '../InfoBox/InfoBox';
import appStore from '../../store/AppStore';
import { tenantsUsingInfoBoxes } from '../../utils/tenantsUtils';
import { marketplaceRooms } from '../../store/enums/MarketplaceRoomEnum';
import { IconKeys, InfoBox as InfoBoxGB } from '@groupbuilderoy/gb-components-library';
import { Typography } from '@material-ui/core';
import MarketplaceInformation from '../Marketplace/MarketplaceInformation';

const StyledMaterialsGrid = styled.div<{ integratedCj2?: boolean }>`
  margin-left: ${({ integratedCj2 }) => (integratedCj2 ? '3rem' : '1.5rem')};
  display: flex;
  flex-direction: column;

  @media ${mediaSmallMax} {
    margin: 0 1rem;
  }
`;

const MaterialRow = styled.div<{ integratedCj2?: boolean }>`
  display: grid;
  grid-template-columns: ${({ integratedCj2 }) =>
    integratedCj2
      ? 'repeat(auto-fill, 11rem);'
      : 'repeat(auto-fill, 11.25rem);'}; repeat(auto-fill, 11.25rem);
  grid-auto-rows: 19.25rem;
  grid-gap: 1rem;
  justify-content: flex-start;

  @media screen and (min-width: 1350px) {
    justify-content: space-between;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  @media ${mediaSmallMax} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 14.25rem;
  }

  @media ${mediaXSmallMax} {
    grid-template-columns: 1fr 1fr;
  }
`;

const MaterialRowTitle = styled.p`
  font-size: 20px;
  line-height: 60px;
  margin-bottom: 0.5rem;

  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: black;
    width: 100%;
    content: '';
  }

  &:not(:first-child) {
    margin-top: 2rem;
  }

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 54px;
  }
`;

const ThemeSetCardWrapper = styled.div`
  grid-column: span 2;
`;

const StyledNotification = styled(Notification)`
  margin: 0rem 5rem 2rem;
  border: 0.5px solid var(--custom-colorNotif4);
`;

const NotificationContent = styled.div`
  width: 100%;

  & > p {
    margin-bottom: 1rem;

    &:not(.font-medium) {
      font-size: 15px;
    }
  }

  a {
    font-size: 15px;
  }
`;

interface MaterialsGridProps {
  bundles: Bundle[];
  accessoryBundles: Bundle[];
  showGeneralMaterials: boolean;
  showRooms: boolean;
}

const MaterialsGrid = ({
  bundles = [],
  accessoryBundles = [],
  showGeneralMaterials,
  showRooms
}: MaterialsGridProps) => {
  const { themeSelectionOpen, changeDisplayedTheme, changeThemeSelectionOpen } = themeStore;

  const onThemeCardClick = (index: number) => {
    changeDisplayedTheme(index);
    if (!themeSelectionOpen) changeThemeSelectionOpen(true);
  };

  return (
    <StyledMaterialsGrid integratedCj2={appStore.integratedApp}>
      {!showRooms ? ( // tempòrary fix for Bonava
        <MaterialRow integratedCj2={appStore.integratedApp}>
          {themeStore.subThemeSets.map((sT) => {
            return sT.themes.some((t) => t.canSelectComputed) ? (
              <ThemeSetCardWrapper key={sT.id}>
                <ThemeSetCard
                  {...sT}
                  image={sT.image}
                  showTick={sT.showTick}
                  deadlineAsDate={sT.deadlineAsDate}
                  selectedThemes={sT.selectedThemes}
                  selected={false}
                  onClick={() => {
                    modalView(ModalView.PACKAGE_SELECTOR);
                    onThemeCardClick(
                      !!sT.selectedThemes.length
                        ? themeStore.selectableThemes.findIndex(
                            (t) => t.id === sT.selectedThemes[0].id
                          )
                        : 0
                    );
                  }}
                />
              </ThemeSetCardWrapper>
            ) : null;
          })}
          {bundles.map((b: Bundle) => (
            <MaterialCard
              key={b.id}
              bundle={b}
              material={b.selectedMaterialOption}
              locked={isLocked(b)}
              onClick={
                b.canSelectionModalBeOpened
                  ? () => {
                      modalView(ModalView.MATERIAL_SELECTOR);
                      materialSelectorStore.selectBundle(roomsStore.selectedRoomId, b.id);
                    }
                  : undefined
              }
            />
          ))}
        </MaterialRow>
      ) : (
        <>
          {roomsStore.selectedRoom && (
            <>
              {marketplaceRooms.includes(roomsStore.selectedRoom.name) ? (
                <InfoBoxGB
                  variant={'contained' as any}
                  icon={IconKeys.shoppingCart}
                  title={''}
                  color={'rgba(56, 194, 251, 0.2)'}
                  cutAtLength={250}
                  customContent={
                    <div style={{ padding: '0px 0.5rem' }}>
                      <Typography
                        variant='h5'
                        style={{ marginBottom: '0.5rem', fontWeight: 400, textAlign: 'center' }}
                      >
                        {text('marketplace.bannerTitle')}
                      </Typography>
                      <Typography style={{ marginBottom: '0.5rem' }}>
                        {text('marketplace.bannerDescription')}
                      </Typography>
                      <div style={{ display: 'flex', marginBottom: '1rem' }}>
                        {/* <div>
                          <Icon
                            color="var(--custom-colorAccent2)"
                            size={25}
                            iconKey={IconKeys.info}
                          />
                        </div> */}
                        <div>
                          <Typography style={{ fontStyle: 'italic' }}>
                            {text('marketplace.informativeText')}
                          </Typography>
                        </div>
                      </div>
                      <MarketplaceInformation showMail={true} />
                    </div>
                  }
                />
              ) : roomsStore.selectedRoom.comment ? (
                <StyledNotification
                  color='var(--custom-colorNotif4)'
                  icon={<InfoIcon fill='white' />}
                >
                  <NotificationContent>{roomsStore.selectedRoom.comment}</NotificationContent>
                </StyledNotification>
              ) : null}
            </>
          )}
          {roomsStore.selectedRoom && (
            <MaterialRow integratedCj2={appStore.integratedApp}>
              {themeStore.subThemeSets.map((sT) => {
                return roomsStore?.selectedRoom?.id &&
                  sT.themesByRoom
                    .get(roomsStore.selectedRoom.id)
                    ?.some((t) => t.canSelectComputed) ? (
                  <ThemeSetCardWrapper>
                    <ThemeSetCard
                      {...sT}
                      key={sT.id}
                      image={sT.image}
                      showTick={sT.showTick}
                      deadlineAsDate={sT.deadlineAsDate}
                      selectedThemes={sT.selectedThemes}
                      selected={false}
                      onClick={() => {
                        modalView(ModalView.PACKAGE_SELECTOR);
                        onThemeCardClick(
                          !!sT.selectedThemes.length
                            ? themeStore.selectableThemes.findIndex(
                                (t) => t.id === sT.selectedThemes[0].id
                              )
                            : 0
                        );
                      }}
                    />
                  </ThemeSetCardWrapper>
                ) : null;
              })}
              {bundles.some((b) => b.showInRoomLevel) &&
                bundles.map((b) => {
                  return b.showInRoomLevel && !b.isApartmentLevel ? (
                    <MaterialCard
                      key={b.id}
                      bundle={b}
                      material={b.selectedMaterialOption}
                      locked={isLocked(b)}
                      onClick={
                        b.canSelectionModalBeOpened
                          ? () => {
                              modalView(ModalView.MATERIAL_SELECTOR);
                              materialSelectorStore.selectBundle(roomsStore.selectedRoomId, b.id);
                            }
                          : undefined
                      }
                    />
                  ) : null;
                })}
            </MaterialRow>
          )}
          {roomsStore.selectedRoom &&
            bundles.some((b: Bundle) => b.showInRoomLevel && b.isApartmentLevel) && (
              <>
                <MaterialRowTitle>{text('generalAppearanceMaterials')}</MaterialRowTitle>
                <MaterialRow integratedCj2={appStore.integratedApp}>
                  {bundles.map((b: Bundle) => {
                    return b.showInRoomLevel && b.isApartmentLevel ? (
                      <MaterialCard
                        key={b.id}
                        bundle={b}
                        material={b.selectedMaterialOption}
                        locked={isLocked(b)}
                        onClick={
                          b.canSelectionModalBeOpened
                            ? () => {
                                modalView(ModalView.MATERIAL_SELECTOR);
                                materialSelectorStore.selectBundle(roomsStore.selectedRoomId, b.id);
                              }
                            : undefined
                        }
                      />
                    ) : null;
                  })}
                </MaterialRow>
              </>
            )}
          {!roomsStore.selectedRoom && (
            <MaterialRow integratedCj2={appStore.integratedApp}>
              {!roomsStore.selectedRoom &&
                themeStore.subThemeSets.map((sT) =>
                  sT.themesByRoom.get(null)?.some((t) => t.canSelectComputed) ? (
                    <ThemeSetCardWrapper>
                      <ThemeSetCard
                        {...sT}
                        key={sT.id}
                        image={sT.image}
                        showTick={sT.showTick}
                        deadlineAsDate={sT.deadlineAsDate}
                        selectedThemes={sT.selectedThemes}
                        selected={false}
                        onClick={() => {
                          modalView(ModalView.PACKAGE_SELECTOR);
                          onThemeCardClick(
                            !!sT.selectedThemes.length
                              ? themeStore.selectableThemes.findIndex(
                                  (t) => t.id === sT.selectedThemes[0].id
                                )
                              : 0
                          );
                        }}
                      />
                    </ThemeSetCardWrapper>
                  ) : null
                )}
              {bundles.some((b: Bundle) => b.showInApartmentLevel) &&
                bundles.map((b: Bundle) => {
                  return b.showInApartmentLevel === true ? (
                    <MaterialCard
                      key={b.id}
                      bundle={b}
                      material={b.selectedMaterialOption}
                      locked={isLocked(b)}
                      onClick={
                        b.canSelectionModalBeOpened
                          ? () => {
                              modalView(ModalView.MATERIAL_SELECTOR);
                              materialSelectorStore.selectBundle(roomsStore.selectedRoomId, b.id);
                            }
                          : undefined
                      }
                    />
                  ) : null;
                })}
            </MaterialRow>
          )}
        </>
      )}
      {!!accessoryBundles.length && (
        <>
          <MaterialRowTitle>{text('accessories')}</MaterialRowTitle>
          <MaterialRow integratedCj2={appStore.integratedApp}>
            {accessoryBundles.map((b) => (
              <MaterialCard
                key={b.id}
                bundle={b}
                material={b.selectedMaterialOption}
                locked={isLocked(b)}
                onClick={
                  b.canSelectionModalBeOpened
                    ? () => {
                        modalView(ModalView.MATERIAL_SELECTOR);
                        materialSelectorStore.selectBundle(roomsStore.selectedRoomId, b.id);
                      }
                    : undefined
                }
              />
            ))}
          </MaterialRow>
        </>
      )}
      {tenantsUsingInfoBoxes.includes(appStore.organisationId) &&
        !marketplaceRooms.includes(roomsStore.selectedRoom?.name || '') && (
          <InfoBox styles={{ marginTop: '1rem' }} description={text('materialsDisclaimerInfo')} />
        )}
    </StyledMaterialsGrid>
  );
};

export default (withRouter as any)(observer(MaterialsGrid));
