import { observable } from 'mobx';
import AttachmentInterface from '../interfaces/AttachmentInterface';
import AttachmentDecorator from './AttachmentDecorator';

class MessageAttachmentDecorator extends AttachmentDecorator {
  @observable public messageId: number;
  @observable public projectId: number;
  @observable public apartmentId?: number | null;

  public constructor(
    attachment: AttachmentInterface,
    source: {
      messageId: number;
      projectId: number;
      apartmentId?: number | null;
    }
  ) {
    super(attachment);
    this.messageId = source.messageId;
    this.projectId = source.projectId;
    this.apartmentId = source.apartmentId;
  }
}

export default MessageAttachmentDecorator;
