import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import defaultHeader from './defaultHeader.png';

const useStyles = ({ imgSrc }: any) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: '200px',
        backgroundImage: imgSrc ? `url(${imgSrc})` : `url(${defaultHeader})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center '
      }
    })
  );
};

export default useStyles;
