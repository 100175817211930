import { observable } from 'mobx';
import AttachmentInterface from '../interfaces/AttachmentInterface';
import { Readable } from 'stream';
import UserLevelRightInterface from '../interfaces/UserLevelRightInterface';
import Tag from './Tag';

class Attachment implements AttachmentInterface {
  @observable public id: number;
  @observable public name: string;
  @observable public description?: string;
  @observable public filename: string;
  @observable public uuidFilename: string;
  @observable public createdOn?: Date;
  @observable public createdBy?: { firstName: string; lastName: string };
  @observable public type?: string | null;
  @observable public mimeType: string;
  @observable public url: string | null;
  @observable public previewImageUrl?: string | null;
  @observable public stream?: Readable | ReadableStream | null;
  @observable public userLevelRights?: Record<number, UserLevelRightInterface> | null;
  @observable public tags?: Tag[] = [];
  @observable public shownInCj?: boolean;
  public file?: File | null;

  public constructor(source: AttachmentInterface) {
    this.id = source.id;
    this.name = source.name;
    this.description = source.description;
    this.filename = source.filename;
    this.uuidFilename = source.uuidFilename;
    this.createdOn = source.createdOn ? new Date(source.createdOn) : new Date();
    this.createdBy = source.createdBy;
    this.type = source.type || null;
    this.mimeType = source.mimeType;
    this.url = source.url;
    this.previewImageUrl = source.previewImageUrl || null;
    this.stream = source.stream;
    this.file = source.file || null;
    this.userLevelRights = source.userLevelRights || null;
    this.tags = source.tags?.map((t) => new Tag(t)) || [];
    this.shownInCj = source.shownInCj;
  }

  public toJSON() {
    const { file, stream, ...output } = this;
    return {
      ...output
    };
  }
}

export default Attachment;
