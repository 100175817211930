import React, { useEffect } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import styled from 'styled-components/macro';
import { ResponsiveCloseIcon } from '../Icons/Close';
import CircleButton from '../Button/CircleButton';
import { mediaSmallMax } from '../Styled/media';
import { Element } from 'react-scroll';
import modalStore from '../../store/ModalStore';
import { EventCategory } from '../../services/EventCategory';
import { EventAction } from '../../services/EventAction';
import { useTrackers } from '../../hooks/useTrackers';

const StyledModal = styled.div`
  position: relative;
  width: 75rem;
  max-width: calc(100vw - 3rem);
  height: calc(100vh - 3rem);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);

  @media ${mediaSmallMax} {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
  }
`;

const StyledClose = styled(CircleButton)<{ closeBtnOffset?: string }>`
  z-index: 100;
  position: absolute;
  top: ${(props) => (props.closeBtnOffset ? `calc(1rem + ${props.closeBtnOffset})` : '1rem')};
  right: 1rem;
  fill: var(--custom-colorAccent);
`;

const ModalContent = styled(Element)<{ overflow?: string }>`
  overflow-y: ${({ overflow }) => (overflow ? overflow : 'unset')};
  max-height: 100%;
  min-height: 100%;
  position: relative;
`;

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
  overflow?: string;
  contentProps?: object;
  closeBtnOffset?: string;
  disableOutsideClick?: boolean;
  [x: string]: any;
}

const Modal = ({
  onClose,
  children,
  overflow,
  contentProps,
  closeBtnOffset,
  disableOutsideClick,
  backdropProps,
  ...rest
}: ModalProps) => {
  const { triggerEvent } = useTrackers();
  useEffect(() => {
    modalStore.changeOpen(true);
    return () => modalStore.changeOpen(false);
  }, []);

  const close = (from: string) => () => {
    triggerEvent({
      category: EventCategory.USER,
      action: EventAction.CLOSE_MODAL,
      label: from
    });
    onClose();
  };

  return (
    <Backdrop
      onClick={!disableOutsideClick ? close('from backdrop') : undefined}
      {...backdropProps}
    >
      <StyledModal onClick={(e) => e.stopPropagation()} {...rest}>
        <StyledClose
          className='modal-close-btn'
          onClick={close('from close button')}
          closeBtnOffset={closeBtnOffset}
        >
          <ResponsiveCloseIcon />
        </StyledClose>
        <ModalContent name='modal-content' id='modal-content' overflow={overflow} {...contentProps}>
          {children}
        </ModalContent>
      </StyledModal>
    </Backdrop>
  );
};

export default Modal;
