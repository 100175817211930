/**
 * Add more modals if needed
 */
export enum ModalView {
  APARTMENT_IMAGES = 'apartment_image',
  FLOOR_PLAN = 'floor_plan',
  PACKAGE_SELECTOR = 'package_selector',
  PACKAGE_IMAGES = 'package_images',
  MATERIAL_SELECTOR = 'material_selector',
  MATERIAL_IMAGE = 'material_image'
}
