import { createTheme } from '@material-ui/core';
import appStore from './store/AppStore';

const theme = createTheme({
  palette: {
    primary: { main: appStore.organisationCustomisation?.colorMain },
    secondary: { main: appStore.organisationCustomisation?.colorAccent },
    action: {
      disabledBackground: 'gray',
      disabled: 'white'
    }
  },
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: appStore.organisationCustomisation?.colorMain
      }
    }
  }
});

export default theme;
