import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import {
  Header,
  HeaderContent,
  HeaderContentWrapper,
  HeaderRow,
  HeaderLogo,
  HeaderBg
} from './headerStyledComponents';
import appStore from '../../store/AppStore';
import { mediaMediumMax, mediaSmallMax } from '../Styled/media';
import { observer } from 'mobx-react-lite';
import EventBox from '../EventBox/EventBox';
import calendarStore from '../../store/CalendarStore';
import { CalendarEventInterface } from '../../store/interfaces/CalendarEvent';
import offersStore from '../../store/OffersStore';
import { gbDefaultLogo } from '../../store/models/TenantSettings';
import HeaderText from '../HeaderText';

import routingStore, { changeParams } from '../../store/RoutingStore';
import { Offer } from '../../store/models/Offer';
import AuxiliarNotification from '../../auxiliaries/AuxiliarNotification';

const StyledMaterialsPageHeader = styled.div`
  // color: white;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media ${mediaMediumMax} {
    max-width: 30rem;
  }

  @media ${mediaSmallMax} {
    margin-top: 28px;
  }

  p {
    min-height: 8.5rem;

    @media ${mediaSmallMax} {
      min-height: 6rem;
    }
  }

  h1 {
    color: 'black',
    margin-bottom: 2rem;

    @media ${mediaSmallMax} {
      font-size: 24px;
      line-height: 26px;
    }
  }
`;

const MaterialsPageHeader = () => {
  useEffect(() => {
    calendarStore.fetchCalendarEvents();
  }, []);
  const [deadlines, setDeadlines] = useState<CalendarEventInterface[]>(
    calendarStore.getEventDeadLinesForHeader()
  );
  const [openOffers, setOpenOffers] = useState<Offer[]>(offersStore.openOffers);

  useEffect(() => {
    setOpenOffers(offersStore.openOffers);
    // eslint-disable-next-line
  }, [offersStore.openOffers]);

  const closeDeadline = (name: string) => {
    setDeadlines(deadlines.filter((d) => d.name !== name));
  };

  const closeOffer = (offerId: number) => {
    setOpenOffers(openOffers.filter((o) => o.id !== offerId));
  };

  if (appStore.integratedApp) {
    return (
      <>
        <Header integratedCj2={appStore.integratedApp}>
          <HeaderText heading={text('headerTitle')} description={text('headerText')} />
        </Header>
        {appStore.tenantSettings.customerJourney2_showEventsBox &&
          (!!deadlines.length || !!openOffers.length) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                margin: '2rem auto',
                padding: '0px 24px'
              }}
            >
              {deadlines.map((deadline, key) => (
                <AuxiliarNotification
                  variant={'srv'}
                  style={{ margin: '0' }}
                  id={key}
                  key={`notification-${key}`}
                  badgeCaption={text('selectionsModal.attention')}
                  caption={`${deadline.name} - ${deadline.description}`}
                  description={''}
                  deadline={deadline.eventDate}
                  containerColor={appStore.organisationId === 'srv' ? 'white' : '#FFFCF5'}
                  borderColor={'#FEC84B'}
                  badgeColors={{
                    badgeColor: appStore.organisationId === 'srv' ? 'white' : '#DC6803',
                    captionColor: appStore.organisationId === 'srv' ? '#212427' : 'white',
                    descriptionColor: appStore.organisationId === 'srv' ? '#212427' : '#B54708',
                    containerColor: appStore.organisationId === 'srv' ? 'white' : '#fdf3d4'
                  }}
                  actionCaption={text('seeMore')}
                  actionOnClick={() => {
                    changeParams({ messages: 'true' });
                  }}
                  onClose={
                    appStore.organisationId === 'srv'
                      ? undefined
                      : () => closeDeadline(deadline.name)
                  }
                  actionAsButton={appStore.organisationId === 'srv'}
                />
              ))}
              {appStore.tenantSettings.customerJourney2_showEventsBox &&
                !!openOffers.length &&
                openOffers.map((offer, key) => (
                  <AuxiliarNotification
                    style={{ margin: '0' }}
                    id={key}
                    key={`notification-${key}`}
                    badgeCaption={`${text('openOffer')}${
                      appStore.organisationId === 'srv' ? ':' : ''
                    }`}
                    caption={`${offer.name}`}
                    description={''}
                    deadline={offer.formatedDeadline}
                    containerColor={appStore.organisationId === 'srv' ? 'white' : '#FCFCFD'}
                    borderColor={'#D0D5DD'}
                    badgeColors={{
                      badgeColor: appStore.organisationId === 'srv' ? 'white' : '#344054',
                      captionColor: appStore.organisationId === 'srv' ? '#212427' : 'white',
                      descriptionColor: appStore.organisationId === 'srv' ? '#212427' : '#344054',
                      containerColor: appStore.organisationId === 'srv' ? 'white' : '#d7e7f7'
                    }}
                    actionCaption={text('seeOffer')}
                    actionOnClick={() => {
                      routingStore.push('/order-confirmation');
                    }}
                    onClose={
                      appStore.organisationId === 'srv' ? undefined : () => closeOffer(offer.id)
                    }
                    actionAsButton={appStore.organisationId === 'srv'}
                  />
                ))}
            </div>
          )}
      </>
    );
  }
  return (
    <Header>
      <HeaderBg src={appStore.headerImg} alt='header' />
      <HeaderContent>
        <HeaderContentWrapper>
          <HeaderRow>
            {appStore.tenantSettings.logoUrls?.organisationLogoLightUrl &&
              !appStore.tenantSettings.customerJourney2_showHeader && (
                <HeaderLogo
                  src={appStore.tenantSettings.logoUrls?.organisationLogoLightUrl}
                  alt='header-logo'
                  onError={(e) => {
                    if (e.currentTarget.src !== gbDefaultLogo) {
                      e.currentTarget.src = gbDefaultLogo;
                    }
                  }}
                />
              )}
          </HeaderRow>
          <StyledMaterialsPageHeader>
            <h1>{text('headerTitle')}</h1>
            <p className='font-normal'>{text('headerText')}</p>
          </StyledMaterialsPageHeader>
        </HeaderContentWrapper>
        {(!!deadlines.length || !!openOffers.length) &&
          appStore.tenantSettings.customerJourney2_showEventsBox && (
            <EventBox
              deadlines={{
                items: deadlines,
                showMax: 3
              }}
              offers={{
                items: openOffers,
                showMax: 2
              }}
            />
          )}
      </HeaderContent>
    </Header>
  );
};

export default observer(MaterialsPageHeader);
