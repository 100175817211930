import Decimal from 'decimal.js';
import i18next from 'i18next';
import routingStore, { getParams } from '../store/RoutingStore';
import appStore from '../store/AppStore';
import { modalParametersArray } from '../store/enums/UrlParameters';
import themeStore from '../store/ThemeStore';
import materialSelectorStore from '../store/MaterialSelectorStore';
import { scroller } from 'react-scroll';
import { Section, SectionType } from '../store/interfaces/Section';
import Bundle from '../store/models/Bundle';
import BundleState from '../store/enums/BundleState';

/** Utility function to create a new Decimal
 * @param num number to turn into Decimal
 */
export const d = (num: string | number) => new Decimal(num);

export const isValidDecimal = (num: string | number | Array<string | number>) => {
  let isValid = true;
  try {
    if (Array.isArray(num)) num.map((n) => d(n));
    else d(num);
  } catch (e) {
    isValid = false;
  }

  return isValid;
};

export const text = (str: string, options?: any) => {
  const translated = i18next.t([`${appStore.organisationId}.${str}`, `main.${str}`], options);

  return translated === '-' ? '' : translated;
};

/** Load images to browser cache
 * @param images array of image urls to preload
 */
export const preloadImages = (images: string[]) => {
  images.forEach((i) => {
    const image = new Image();
    image.src = i;
  });
};

/** Checks whether the path is in the current location pathname
 * @param path path to check
 */
export const isCurrentLocation = (path: string) => {
  return routingStore.location.pathname.includes(path);
};

export const isValidUrl = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const defaultScrollOptions = {
  duration: 800,
  delay: 0,
  smooth: 'easeInOutQuart'
};

export const scrollTo = (elementName: string, force?: boolean, options?: any) => {
  const params = getParams();
  const canScroll =
    modalParametersArray.every((p) => !params[p]) &&
    !themeStore.themeSelectionOpen &&
    !materialSelectorStore.selectedBundle;

  if (canScroll || force) {
    scroller.scrollTo(elementName, {
      ...defaultScrollOptions,
      ...options
    });
  }
};

export const decimalToLocale = (num: Decimal) =>
  num.toNumber().toLocaleString(appStore.parseLanguage(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

export const getSubsections = (sections: Section[], path: string) => {
  const subSections = sections.reduce((pre: Section[], cur: Section) => {
    cur.path = `${path}${cur.name}`;
    if (cur.type === SectionType.Folder) {
      if (cur.sections) pre.push(...getSubsections(cur.sections, `${cur.path} :: `));
    } else {
      pre.push(cur);
    }
    return pre;
  }, [] as Section[]);

  return subSections;
};

export const isLocked = (bundle: Bundle) => {
  return (
    bundle.state === BundleState.LOCKED ||
    bundle.state === BundleState.CONFIRMED ||
    bundle.state === BundleState.DEADLINE_GONE
  );
};

export const isOpen = (bundle: Bundle) => {
  return (
    bundle.state === BundleState.OPEN ||
    bundle.state === BundleState.NOT_DEFINED ||
    bundle.state === null
  );
};

export const parseSize = (size: string, unit: string) => {
  const indexOfUnit = size.indexOf(unit);

  if (indexOfUnit === -1) {
    throw new Error('Unit does not match the provided size.');
  }

  return Number(size.substring(0, indexOfUnit));
};

export const addSizes = (a: string, b: string, unit: string) => {
  return `${parseSize(a, unit) + parseSize(b, unit)}${unit}`;
};

export const intlCurrencyFormat = (
  input: number | string | Decimal,
  currencyCode: string,
  locale: string
): string => {
  if (input === '0.01') {
    return text('askForPrice');
  }
  const formatter = new Intl.NumberFormat(locale?.replace('_', '-'), {
    style: 'currency',
    currency: currencyCode
  });
  return formatter.format(Number(input.toString())).normalize('NFKC');
};

export const limitStringLength = (str: string, maxLength: number, cutIn: number): string => {
  return str.length > maxLength ? str.substring(0, cutIn) + '..' : str;
};

export const getFormattedMaterialPrice = (price: Decimal) => {
  return price.equals(0.01)
    ? text('askForPrice')
    : `${decimalToLocale(price.toDP(2, 2))} ${appStore.currencySymbol}`;
};
