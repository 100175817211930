import React from 'react';
// eslint-disable-next-line
export default (props: any) => (
  <svg
    width='26'
    height='16'
    viewBox='0 0 26 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M12.1382 15.2026L0.61542 3.521C0.148554 3.05494 0.147908 2.30065 0.613975 1.83378L1.31804 1.12851C1.78411 0.661647 2.53839 0.661002 3.00526 1.12707L12.9785 11.2717L22.9344 1.11994C23.4004 0.653068 24.1547 0.652422 24.6216 1.11849L25.3268 1.82255C25.7937 2.28862 25.7944 3.04291 25.3283 3.50977L13.8255 15.2111C13.3594 15.668 12.6051 15.6687 12.1382 15.2026Z' />
  </svg>
);
