/**
 * Add more actions if needed
 */
export enum EventAction {
  CLOSE_MODAL = 'Close modal',
  CLOSE_LIGHTBOX = 'Close lightbox',
  OPEN_LANGUAGE_SELECTOR = 'Open language selector',
  SELECT_LANGUAGE = 'Select language',
  VIEW_IN_3D = 'View in 3D',
  OPEN_PACKAGES = 'Open packages',
  CLOSE_PACKAGES = 'Close packages',
  BROWSE_PACKAGE_SELECTOR = 'Browse package selector',
  BROWSE_CAROUSEL_IMAGES = 'Browse carousel images',
  CONTINUE_EDITING_PACKAGE = 'Continue editing package',
  SELECT_PACKAGE = 'Select package',
  SELECT_ROOM = 'Select room',
  BROWSE_MATERIAL = 'Browse material',
  SELECT_MATERIAL = 'Select material',
  OPEN_MESSAGE_ROOM = 'Open message room',
  SEND_MESSAGE = 'Send message',
  OPEN_BULLETIN = 'Open bulletin',
  OPEN_CONTACT_PHASE = 'Open contact phase',
  LOAD_DOCUMENT = 'Load document',
  CLICK_UNSELECTABLE_PACKAGE = 'Click unselectable package',
  CONFIRM_PACKAGE_MATERIALS = 'Confirm package materials',
  READY_TO_ORDER_CLICKED = 'Ready to order clicked',
  SUMMARY_PAGE_CLICKED = 'Summary page clicked',
  FOOTER_CONFIRMATION_CLICKED = 'Confirmation page accessed from footer',
  OPEN_MARKETPLACE_ROOM = 'Open marketplace room',
  OPEN_MARKETPLACE_CATEGORY = 'Open marketplace category',
  BROWSE_MARKETPLACE_MATERIAL_SELECTIONS = 'Browse marketplace material selections',
  SELECT_MARKETPLACE_ITEM = 'Select marketplace item',
  SELECT_MARKETPLACE_NO_SELECTION = 'Select marketplace no selection item',
  CONFIRM_MARKETPLACE_SELECTIONS = 'Confirm marketplace selections'
}
