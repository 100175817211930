import React from 'react';
import makeStyles from './HeaderImg.styles';

export interface HeaderImgProps {
  imgSrc?: string;
  [x: string]: any;
}

const HeaderImg: React.FC<HeaderImgProps> = ({ imgSrc }: HeaderImgProps) => {
  const classes = makeStyles({ imgSrc })();
  return <div data-testid='headerImage' className={`${classes.root}`} />;
};

export default HeaderImg;
