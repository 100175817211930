import React, { useRef, useLayoutEffect, ReactNode } from 'react';
import debounce from 'lodash.debounce';

interface SectionResizerProps {
  setNewHeight: (newHeight: string) => void;
  children: ReactNode;
  adjustment?: string;
  effectListeners: any[];
}

/**
 * When effect listener value changes, the component calculates new height required by children and calls set new height with it.
 * Include adjustment prop to adjust the new height.
 */
const SectionResizer = (props: SectionResizerProps) => {
  const { setNewHeight, adjustment, effectListeners, children, ...rest } = props;
  const refEl = useRef(null);

  const handleResize = () => {
    setTimeout(() => {
      // timeout of 1ms fixes unexpected behaviour with some late updates.
      const height: number = refEl.current ? (refEl.current as any).offsetHeight : 0;

      setNewHeight(adjustment ? `calc(${height}px + ${adjustment})` : `${height}px`);
    }, 1);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', debounce(handleResize, 200));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 200));
    };
  }, [...effectListeners, adjustment, setNewHeight]); // eslint-disable-line

  return (
    <div ref={refEl} {...rest}>
      {children}
    </div>
  );
};

export default SectionResizer;
