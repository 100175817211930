import React from 'react';
import Modal from '../Modal/Modal';
import { changeParams } from '../../store/RoutingStore';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Content = styled.div`
  padding: 3rem;
`;

export const createLink = (caption: string, url: string) => {
  return (
    <a style={{ color: 'blue' }} href={url} target='_blank' rel='noreferrer'>
      {caption}
    </a>
  );
};
export const supportMailLink = (
  <a
    style={{ color: 'blue' }}
    href='mailto:support@gbhomebook.com?subject = Feedback&body = Message'
    rel='noreferrer'
  >
    <span>support@gbhomebook.com</span>
  </a>
);

const MarketplaceFAQ = () => {
  return (
    <Modal
      closeBtnOffset='3rem'
      onClose={() => changeParams({ marketplaceFAQ: undefined })}
      overflow='auto'
      style={{ height: '93vh !important' }}
    >
      <Content>
        <Typography variant='h5'>Asiakasohjeet</Typography>
        <br />
        <Typography variant='h6'>GBHomebook</Typography>
        <Typography>
          GBHomebook on Group Builder Oy:n ja sen yhteistyökumppaneiden toteuttama
          tietomallipohjainen markkinapaikka. Se tarjoaa pienkoneet kilpailukykyiseen hintaan ja
          toimittaa ne uuteen kotiin jo ennen muuttoa.
        </Typography>
        <br />
        <Typography variant='h6'>Asiakaspalvelu</Typography>
        <Typography>
          Jos teillä on kysymyksiä, kohtaatte ongelmia tai tarvitsette apua, älkää epäröikö ottaa
          yhteyttä asiakastukitiimiimme osoitteessa {supportMailLink}.
        </Typography>
        <br />
        <Typography variant='h6'>Tilaaminen</Typography>
        <Typography>
          Valitse tuote/tuotteet. Vahvista tilaus painamalla “tarkista ja vahvista” -painiketta.
          Tämän jälkeen voit tarkastella vielä tilaustasi yhteenvedossa ja vahvistaa ostokset. Saat
          allekirjoitettavan tilausvahvistuksen sähköpostiin heti vahvistuksen jälkeen. GBHomebook
          tarkastaa tilauksen ja luo laskun 1-3 päivän sisällä tilauksen vahvistamisesta.
        </Typography>
        <br />
        <Typography variant='h6'>Laskutus</Typography>
        <Typography>
          GBHomebook toimittaa laskun ja kopion tilausvahvistuksesta Studion liitteisiin ja
          sähköpostiin. Laskussa on 14 päivän maksuaika. Lasku toimitetaan osoitteesta{' '}
          {supportMailLink}.
        </Typography>
        <br />
        <Typography variant='h6'>Toimitus</Typography>
        <Typography>
          Studion kautta tilatut tuotteet toimitetaan avaamattomissa alkuperäispaketeissaan ja 14
          päivän täydellä palautusoikeudella (muuttopäivästä alkaen) kohteeseen GBHomebookin ja
          yhteistyökumppanin toimesta. Tilauksen mukana toimitetaan ohjeet kuinka toimia
          takuu/palautus -asioissa, sekä tiedot mahdollisista jälkitoimituksista. Ohjeiden mukana A4
          tulosteessa palautusosoite mahdollista palautusta varten. Jos tilauksen kanssa on
          ongelmia, voit myös olla suoraa yhteydessä 24/7 tukeemme osoitteessa {supportMailLink}.
        </Typography>
        <br />
        <Typography variant='h5'>Vauriot ja viat</Typography>
        <br />
        <Typography variant='h6'>Käyttö- ja asennusvirheet</Typography>
        <Typography>
          Uuden tuotteen haluaa toki heti käyttöön, mutta aluksi kannattaa lukea käyttö- ja
          asennusohjeet huolella läpi. Takuu ei kata vaurioita, jotka ovat aiheutuneet
          virheellisestä käytöstä tai asennuksesta. <br />
          <b>Esimerkki:</b>{' '}
          <i>
            Vedenkeittimestä ei muisteta ottaa pakkausmuovia sisältä pois, ja se sulaa keittimen
            sisään ja aiheuttaa vaurion. Pakkausmuovi tulisi poistaa ennen käyttöä, joten vaurio on
            käyttövirhe, jota takuu ei kata.
          </i>
        </Typography>
        <br />
        <Typography variant='h6'>Näyttöjen vauriot/viat</Typography>
        <Typography>
          Takuu ei kata haljennutta näyttöä tai iskuja näytössä. Näyttöihin saattaa tulla ns.
          kuolleita tai kuumia pikseleitä. Valmistajilla on omat ja vaihtelevat ehtonsa, milloin
          näyttö voidaan huoltaa pikselivirheiden vuoksi. Tähän yleensä vaikuttaa pikseleiden määrä
          ja/tai sijainti.
        </Typography>
        <br />
        <Typography variant='h6'>Kuluvat osat, normaali kuluminen ja korroosio</Typography>
        <Typography>
          Monissa tuotteissa on kuluvia osia, jotka tulee säännöllisesti vaihtaa. Takuu ei kata
          tällaisia vaihdettavia ja kuluvia osia, vaan varaosat on ostettava itse. Myöskään
          säännöllisen puhdistamisen laiminlyönnistä johtuva rikkoutuminen ei kuulu takuun piiriin.
          <br />
          <b>Esimerkki:</b>{' '}
          <i>
            Kahvinkeitin on vuosien aikana kalkkeunut niin pahasti, ettei se enää keitä kahvia
            toivotulla tavalla.
          </i>
        </Typography>
        <br />
        <Typography variant='h6'>Muut fyysiset vauriot</Typography>
        <Typography>
          Tapaturmat ja ulkoisen voiman aiheuttamat vauriot eivät kuulu takuun piiriin. <br />
          <b>Esimerkki:</b> <i>Kavinkeittimen pannu putoaa tiskialtaaseen ja hajoaa.</i>
        </Typography>
        <br />
        <Typography variant='h6'>Datan pelastaminen ja ohjelmistot</Typography>
        <Typography>
          Takuun piikkiin ei pelasteta laitteista sinne tallennettua dataa, esimerkiksi puhelimesta
          valokuvia tai tietokoneesta dokumentteja. Ohjelmistoja ei asenneta uudelleen, joten
          suosittelemme pitämään erikseen ostettujen ohjelmistojen lisenssit hyvässä tallessa. Ja
          muistathan varmuuskopiot datasta!
          <br /> Jos varmuuskopiot ovat jääneet ottamatta, GB Homebook ei tarjoa datan pelastamista.
          Maksullisen palvelun voi tilata huoltoyhtiöltä tai joltain kolmannen osapuolen
          yritykseltä.
          <br />
          <b>Esimerkki:</b>{' '}
          <i>
            Pelikonsoli ei enää käynnisty, ja huollossa siihen vaihdetaan osia ja palautetaan
            tehdasasetukset. Dataa ei pelasteta takuun piikkiin.
          </i>
        </Typography>
        <br />
        <Typography variant='h6'>Vikaa tuotteessa?</Typography>
        <Typography>
          Ole takuuseen kuuluvissa tai kuulumattomissa asioissa yhteyttä merkin huoltoliikkeeseen
          (huolto-ohjeet on listattu{' '}
          {createLink(
            'tänne',
            'https://asiakaspalvelu.verkkokauppa.com/hc/fi/sections/360005673560--Tuki-ja-huolto-ohjeet-merkeitt%C3%A4in'
          )}
          ).
          <br /> Huoltoliike tekee aina päätöksen, onko vika takuunalainen vai ei. Jos vika ei kuulu
          takuuseen, huoltoyhtiö antaa kustannusarvion korjauksesta.
          <br />
          <b>Suurin osa tuotteista huolletaan aina valmistajan valtuuttamassa huoltoliikkeessä. </b>
          <br />
          Keskimääräinen huoltoaika on 10-20 arkipäivää, mutta valmistumista tässä ajassa ei voida
          taata. Takuuhuollon kesto riippuu korjaustoimenpiteestä ja mm. tarvittavien varaosien
          saatavuudesta. Huolto valmistuu kuitenkin nopeimmin, kun toimitat tuotteen suoraan
          huoltoliikkeelle - tarkistathan siis merkkikohtaiset ohjeet ennen huoltoon toimittamista!
          <br />
          <br />
          <b>Toimi näin</b>
          <br />
          <ul>
            <li>
              Hae oikeat ohjeet valmistajan nimellä{' '}
              {createLink(
                'merkkikohtaisista huolto-ohjeista.',
                'https://asiakaspalvelu.verkkokauppa.com/hc/fi/sections/360005673560--Tuki-ja-huolto-ohjeet-merkeitt%C3%A4in'
              )}
            </li>
            <li>Toimita tuote huoltoon valmistajan ohjeiden mukaan.</li>
            <li>
              Mikäli valmistajalla ei ole omia huolto-ohjeita, voit toimittaa tuotteen{' '}
              {createLink(
                'näillä ohjeilla.',
                'https://asiakaspalvelu.verkkokauppa.com/hc/fi/articles/4409774479378'
              )}
            </li>
          </ul>
          Mikäli et löydä valmistajaa ylläolevan linkin kautta, niin tarvittaessa saat apua meidän
          asiakaspalvelusta {supportMailLink}.
        </Typography>
        <br />
        <Typography variant='h6'>Kuitti hukassa?</Typography>
        <Typography>
          Tilausvahvistus toimitetaan sähköisen allekirjoituksen jälkeen asiakkaan sähköpostiin sekä
          asiakaspolun dokumentit ja liitteet -osioon. Myös tilausten laskut toimitetaan samoihin
          kanaviin.
          <br />
          Jos sinulla on lisäkysymyksiä takuu/huolto/palautusasioissa, voit olla myös suoraa
          yhteydessä GB Homebook:n asiakaspalveluun osoitteessa {supportMailLink}.
        </Typography>
        <br />
        <br />
        <br />
        <Typography variant='h5'>Palautusoikeus ja tuotteen palautus – usein kysytyt </Typography>
        <br />
        <Typography variant='h6'>kysymykset</Typography>
        <Typography>
          Tuotteille <b>14 päivän palautusoikeus.</b>
          <br />
          Osalla tuotteista on käyttötarkoituksensa vuoksi rajoitettu palautusoikeus, eli tuotetta
          ei voi palauttaa pakkauksen avaamisen jälkeen.
        </Typography>
        <br />
        <Typography variant='h6'>Palautusoikeus</Typography>
        <Typography>
          Sinulla on oikeus tutustua tuotteeseen 14 päivän ajan muuttopäivästä alkaen. Asiakas on
          vastuussa tuotteen kunnosta ja sen mukana toimitettavista tarvikkeista. Mahdollisesta
          kulumisesta tai pakkausten puutteista voi koitua arvonalentuma.
        </Typography>
        <br />
        <Typography variant='h6'>Rajoitettu palautusoikeus</Typography>
        <Typography>
          Osalla tuotteista on rajoitettu palautusoikeus, eli tuotteen palautusoikeus päättyy, jos
          pakkaus on avattu. Tällaisia tuotteita ovat mm. pelit, elokuvat, ohjelmistot, hygienia- ja
          intiimituotteet, elintarvikkeet ja räätälöidyt tuotteet.
        </Typography>
        <br />
        <Typography variant='h6'>Kuinka tuote palautetaan?</Typography>
        <Typography>
          Palautus Postin kautta
          <br />
          <ul>
            <li>
              Pakkaa tuote oheistarvikkeineen hyvin lähetystä varten, jotta se ei vaurioidu
              matkalla. Huom. lähetyksen maksimipaino on 25 kg.
            </li>
            <li>
              Liitä mukaan tilauksesi mukana tullut{' '}
              {createLink(
                'palautus- ja takuuhuoltolomake',
                'https://cdn-a.verkkokauppa.com/files/61921/0bbea/c1b85/18a2d/5f65.pdf'
              )}
              . Lomakkeen tai siinä pyydettyjen tietojen tulee olla mukana, jotta palautus voidaan
              käsitellä.
            </li>
            <li>
              Kirjaa lähetys palautetuksi jo etukäteen{' '}
              {createLink('OmaPostissa', 'https://www.posti.fi/fi/henkiloille/omaposti')} tai vie
              paketti suoraan Postin palvelupisteen tiskille ja anna sopimustunnus 600170. Palautus
              on maksuton, kun käytät sopimustunnusta.
            </li>
          </ul>
          <br />
          Palautusosoite {createLink(
            'Verkkokauppa.com',
            'https://www.verkkokauppa.com/fi/etusivu'
          )}{' '}
          / Palautukset
          <br /> Tyynenmerenkatu 11, 3. kerros00220 Helsinki
          <br />
          Voit palauttaa tilauksesi maksutta Postin pakettiautomaatin kautta, jos paketti on alun
          perin lähetetty pakettiautomaattiin.{' '}
          {createLink(
            'Postin palauta paketti palvelussa',
            'https://www.posti.fi/fi/henkiloille/paketit-ja-seuranta/palauta-paketti'
          )}{' '}
          on valinta “Palautus sopimusnumerolla” jonka kautta palautus onnistuu Postin ohjeita
          seuraamalla. Sopimusnumero on 600170.
        </Typography>
        <br />
        <Typography variant='h6'>Tarvitsenko kuitin tuotteen palauttamista varten?</Typography>
        <Typography>
          Tilausvahvistus toimitetaan sähköisen allekirjoituksen jälkeen asiakkaan sähköpostiin sekä
          asiakaspolun dokumentit ja liitteet -osioon. Myös tilausten laskut toimitetaan samoihin
          kanaviin. Voit myös etsiä oikeaa tilausta tilausnumerolla ja tuotteen nimellä sähköpostisi
          hakukentän kautta.
        </Typography>
        <br />
        <Typography variant='h6'>Palautin tuotteen, miten saan rahani takaisin?</Typography>
        <Typography>
          Palauttaessasi tuotteen sen ostohinta palautetaan, kunhan sekä itse tuote että pakkaus ja
          tarvikkeet ovat siistissä kunnossa. Voit tutustua ostamaasi tuotteeseen palautusaikana.
          <br />
          <b>Rahojen palautus</b>
          <br />
          Group Builder Oy palauttaa rahat tilille, josta maksu on suoritettu, 14 päivän sisällä.
        </Typography>
        <br />
        <Typography variant='h6'>Voinko avata pakkauksen ja silti palauttaa tuotteen?</Typography>
        <Typography>
          Kyllä, palautusoikeuden puitteissa tuotteita on mahdollista kokeilla, eli tuotteeseen
          pääsee tutustumaan rauhassa. Pakkauksen voi avata ja tämän jälkeen voit edelleen palauttaa
          tuotteen. Huolehdi tuotteesta ja sen mukana toimitettavista tarvikkeista, ohjeista ja
          tuotepakkauksesta, kunnes olet päättänyt pitää tuotteen. Ostaja vastaa lain mukaisesti
          arvon alentumisesta, jos tuote on otettu käyttöön tai esimerkiksi tuotepakkaus on
          hävitetty. Muista kuitenkin tarkistaa onko tuote merkitty rajoitetulla palautusoikeudella.
          Rajattu palautusoikeus tarkoittaa, että tuotetta ei voi palauttaa avaamisen jälkeen.
        </Typography>
        <br />
        <Typography variant='h6'>Voinko palauttaa tuotteen, jos se on mennyt rikki?</Typography>
        <Typography>
          Tuotetta ei voi palauttaa, jos se on mennyt rikki ostohetken jälkeen esimerkiksi
          onnettomuuden tai huolimattomuuden seurauksena, eikä rikkoutuminen johdu materiaali- tai
          valmistusvirheestä. Rikkoutunutta tuotetta ei voi myydä enää eteenpäin, joten tuotetta ei
          voi palauttaa. Tällaisessa tapauksessa kannattaa olla yhteydessä omaan vakuutusyhtiöön.
          <br />
          Esimerkkejä:
          <br />
          <ul>
            <li>
              Jos tuotteeseen on ilmestynyt ostohetken jälkeen naarmu, palautuskäsittelijämme
              harkitsee tapauskohtaisesti, voiko tuotteen hinnasta hyvittää osan, ja voiko tuotetta
              ylipäätään palauttaa.
            </li>
          </ul>
        </Typography>
        <br />
        <Typography variant='h6'>
          Voinko palauttaa tuotteen, jos se oli rikki jo saapuessaan?
        </Typography>
        <Typography>
          Jos tuote on rikki heti saapuessaan tai vika kuuluu takuuseen, sen voi palauttaa.
          Poikkeuksena kuitenkin tuotteet, joilla on rajoitettu palautusoikeus. Näitä tuotteita ei
          voi palauttaa, mutta ne voi toimittaa huolto-osastollemme, jossa rikkinäinen tuote
          vaihdetaan uuteen tai huolletaan.
          <br />
          Usein päätös palautuksesta tehdään saman tien, mutta joissain tapauksissa laite pitää
          tarkistuttaa asiantuntevassa huollossa, ennen kuin palautus hyväksytään. Näissä
          tapauksissa palautuskäsittelyssä voi kestää 2-3 viikkoa.
        </Typography>
        <br />
        <Typography variant='h6'>
          Olen hukannut tuotepakkauksen, voinko palauttaa tuotteen?
        </Typography>
        <Typography>
          Osan tuotteista voi palauttaa, vaikka tuotepakkaus ei olisikaan mukana, mutta huomaathan,
          että täyttä hintaa ei siinä tapauksessa hyvitetä.
          <br />
          Tuote on tärkeää pakata niin, että se ei pääse liikkumaan pakkauksen sisällä. Voit pakata
          tuotteen kotoa löytyvillä materiaaleilla, esimerkiksi pahvilaatikoksi käy mikä tahansa
          kotoa löytyvä paketti ja pehmusteeksi käy rypistetty sanomalehti, kuplamuovi, muovipussi
          tms.
        </Typography>
        <br />
        <Typography variant='h6'>Ostettua tuotetta ei löydy asunnosta tai väärä tuote.</Typography>
        <Typography>
          Olethan yhteydessä asiakaspalveluumme osoitteessa {supportMailLink}, ja selvitämme asian
          välittömästi!
        </Typography>
      </Content>
    </Modal>
  );
};

export default observer(MarketplaceFAQ);
