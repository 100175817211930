import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontFamily: 'inherit'
    },
    description: {
      fontFamily: 'inherit',
      color: '#212427',
      marginTop: '4px'
    }
  })
);
