import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = ({ containerColor, borderColor, badgeColors }: any) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        maxWidth: 'none',
        width: 'auto !important',
        padding: '4px',
        background: `${containerColor ? containerColor : '#FCFCFD'} !important`,
        borderRadius: '16px',
        boxShadow: 'none !important',
        border: `1px solid ${borderColor ? borderColor : '#D0D5DD'} !important`
      },
      mainContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        overflow: 'auto',
        justifyContent: 'space-between',
        gap: '1rem'
      },
      infoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '1rem'
      },
      descriptionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: '1rem'
      },
      description: {
        color: badgeColors?.descriptionColor,
        fontWeight: 'lighter',
        lineBreak: 'anywhere'
      },
      actionsContainer: {
        display: 'flex',
        flexWrap: 'wrap'
      },
      action: { display: 'flex', flexWrap: 'wrap', cursor: 'pointer' },
      actionText: {
        margin: 'auto 0 auto auto',
        color: badgeColors?.descriptionColor,
        whiteSpace: 'nowrap'
      },
      actionIcon: { margin: 'auto 0 auto 0.4rem', verticalAlign: 'middle' },
      closeIcon: { margin: '-0.3rem -0.2rem auto 0.4rem', cursor: 'pointer' }
    });
  });

export default useStyles;
