import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

export const Header = styled.header<{ integratedCj2?: boolean }>`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: ${({ integratedCj2 }) => (integratedCj2 ? 'start' : 'center')};
  position: relative;
  overflow: hidden;
  ${({ integratedCj2 }) => integratedCj2 && 'padding: 24px; padding-bottom: 0px'}
`;

export const HeaderContent = styled.div`
  position: relative;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.45);
  max-width: 1440px;
  width: 100%;
  padding: 0 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${mediaSmallMax} {
    padding: 0 1rem;
  }
`;

export const HeaderContentWrapper = styled.div`
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 1rem;
  color: white;
`;

export const HeaderLogo = styled.img`
  max-height: 10rem;
  max-width: 10rem;

  @media ${mediaSmallMax} {
    max-height: 1.5rem;
    max-width: 4rem;
  }
`;

export const HeaderRow = styled.div`
  margin-top: 2.25rem;

  @media ${mediaSmallMax} {
    margin-top: 1.5rem;
  }
`;

export const HeaderBg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
`;
