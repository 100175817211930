import { observable, action } from 'mobx';
import { text } from '../utils';

export interface ConfirmationDialogTexts {
  confirmationText: string;
  confirmationTitle?: string;
  okText: string;
  cancelText?: string;
}

class ConfirmationDialogStore {
  @observable public isOpen = false;
  @observable public confirmationText: string = '';
  @observable public confirmationTitle?: string = '';
  @observable public okText: string = text('ok');
  @observable public cancelText: string = text('cancel');
  @observable public okColor?: string;
  @observable public cancelColor?: string;
  @observable public onCancel?: () => void;

  private onOk?: () => void;
  private onClose?: () => void;

  @action public open = (
    texts: ConfirmationDialogTexts,
    onOk?: () => void,
    onCancel?: () => void,
    onClose?: () => void,
    okColor?: string,
    cancelColor?: string
  ) => {
    this.isOpen = true;
    this.onOk = onOk;
    this.onCancel = onCancel;
    this.onClose = onClose;

    this.confirmationText = texts.confirmationText;
    this.confirmationTitle = texts.confirmationTitle;
    this.okText = texts.okText;
    this.cancelText = texts.cancelText || this.cancelText;
    this.okColor = okColor;
    this.cancelColor = cancelColor;
  };

  @action public ok = () => {
    if (this.onOk) this.onOk();
    this.hide();
  };

  @action public close = () => {
    if (this.onClose) this.onClose();
    this.hide();
  };

  @action public cancel = () => {
    if (this.onCancel) this.onCancel();
    this.hide();
  };

  @action private hide = () => {
    this.isOpen = false;
    this.onOk = undefined;
    this.onCancel = undefined;
    this.onClose = undefined;
    this.confirmationText = '';
    this.okText = text('ok');
    this.cancelText = text('cancel');
    this.okColor = undefined;
    this.cancelColor = undefined;
  };
}

const confirmationDialogStore = new ConfirmationDialogStore();

export default confirmationDialogStore;
