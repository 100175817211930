import React, { useRef, useLayoutEffect, useState } from 'react';
import Material from '../../store/models/Material';
import styled from 'styled-components/macro';
import classnames from 'classnames';
import { NoImage, MaterialImageWrapper, MaterialImage, MaterialPrice } from './styledComponents';
import { observer } from 'mobx-react-lite';
import { text, getFormattedMaterialPrice } from '../../utils';
import Plus from '../Icons/Plus';
import Lock, { StyledLockContainer } from '../Icons/Lock';
import { mediaSmallMax } from '../Styled/media';
import routingStore from '../../store/RoutingStore';
import offersStore from '../../store/OffersStore';
import { FormattedTextSection } from '../Styled/FormattedTextSection';
import CalendarIcon from '../Icons/deadline.svg';
import MultipleIcon from '../Icons/multiple.svg';
import Bundle from '../../store/models/Bundle';
import BundleState from '../../store/enums/BundleState';
import appStore from '../../store/AppStore';
import { triggerEvent } from '../../services';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';

const NoMaterialContainer = styled.div`
  width: 6rem;
  height: 6rem;
  background-color: var(--color-lightGray);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NoMaterial = styled(Plus)`
  transform: ${({ deadlineGone }) => (deadlineGone ? 'rotate(45deg) ' : 'none')};
  width: 3rem;
  height: 3rem;
  fill: white;
`;

const ImgWrap = styled.div`
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameTooltipContainer = styled.div`
  visibility: hidden;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: 21%;
  padding: 8px;
  text-align: center;
  z-index: 1;
  margin: auto;
`;
const NameTooltip = styled.span`
  width: auto;
  text-align: center;

  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  margin-bottom: 1rem;
  max-width: 100%;
  font-family: var(--custom-fontNameBold);


  @media ${mediaSmallMax} {
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 0.5rem;
`;

const StyledMaterialCard = styled.div<{ openModal?: boolean; integratedCj2?: boolean }>`
  ${({ integratedCj2 }) => integratedCj2 && 'border-radius: 8px;'}
  ${({ integratedCj2 }) => integratedCj2 && 'background: white;'}
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 11rem 6rem;
  border: 1px solid var(--color-gray);
  overflow: hidden;
  position: relative;
  cursor: ${({ openModal }) => (!!openModal ? 'pointer' : 'auto')};
  height: fit-content;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: ${({ openModal }) => (!!openModal ? '3px solid rgba(255, 255, 255, 0)' : 'auto')};
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }

  &:hover {
    border-color: ${({ openModal }) => (!!openModal ? 'var(--custom-colorHover);' : 'auto')};

    &:after {
      border-color: ${({ openModal }) => (!!openModal ? 'var(--custom-colorHover);' : 'auto')};
    }

    ${NoMaterialContainer} {
      background-color: ${({ openModal }) => (!!openModal ? 'var(--custom-colorHover);' : 'auto')};
    }

    ${NameTooltipContainer} {
      visibility: visible;
    }
  }

  &.targeted {
    border-color: var(--custom-colorAccent);

    &:after {
      border-color: var(--custom-colorAccent);
    }
  }

  @media ${mediaSmallMax} {
    grid-template-rows: auto 8.25rem 4rem;
  }
`;

const MaterialDescriptionWrapper = styled(FormattedTextSection)`
  margin: 0.5rem 0.75rem 1rem 0.75rem;
  overflow: hidden;
  grid-row: 3;

  @media ${mediaSmallMax} {
    margin: 0.5rem;
  }
`;

const MaterialName = styled.p`
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  margin-bottom: 1rem;
  max-width: 100%;
  font-family: var(--custom-fontNameBold);

  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;

  @media ${mediaSmallMax} {
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 0.5rem;
  }
`;

const MaterialDescription = styled.p`
  font-size: 14px;
  line-height: 15px;
  font-weight: 300;
  height: 33px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media ${mediaSmallMax} {
    font-size: 12px;
    line-height: 13px;
  }
`;

const BundleName = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: var(--color-darkGray);
  padding: 0 0.75rem;
  line-height: 34px;
  width: auto;
  position: relative;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  @media ${mediaSmallMax} {
    font-size: 14px;
    line-height: 30px;
    padding: 0 0.5rem;
  }
`;

const StyledMaterialImageWrapper = styled(MaterialImageWrapper)`
  grid-row: 2;

  &:after {
    content: none;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`;

interface MaterialDeadlineProps {
  highlight?: boolean;
  noSelection?: boolean;
}
const MaterialDeadline = styled.div<MaterialDeadlineProps>`
  background-color: ${({ highlight }) =>
    highlight ? 'var(--custom-colorNotif1)' : 'var(--custom-colorAccent)'};
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
  font-family: var(--custom-fontName);
  max-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${mediaSmallMax} {
    font-size: 12px;
    padding: 2px 4px;
  }
`;

const StyledMaterialImage = styled(MaterialImage)`
  position: relative;
  transform: none;
  top: 0;
  left: 0;
`;

const OfferHeader = styled.p<{ pending?: boolean }>`
  background-color: ${({ pending }) => (pending ? '#db4d69' : 'var(--custom-colorAccent)')};
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
  font-family: var(--custom-fontName);
  max-height: 60px;
  @media ${mediaSmallMax} {
    font-size: 12px;
    padding: 2px 4px;
  }
  cursor: pointer;

  &:hover {
    filter: opacity(50%);
  }
`;

const NotificationsHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

interface MaterialCardProps {
  bundle?: Bundle;
  material?: Material;
  locked?: boolean;
  targeted?: boolean;
  onClick?: () => void;
}

const MaterialCard = ({ bundle, material, locked, targeted, onClick }: MaterialCardProps) => {
  const { integratedApp } = appStore;
  const {
    name: bundleName,
    deadlineAsDate: deadline,
    deadlineNear,
    offers,
    isPartOfNonDraftOffer: isPartOfOffer,
    hasPendingOffers
  } = bundle || {};
  const deadlineGone = bundle ? bundle.state === BundleState.DEADLINE_GONE : undefined;
  const hasOptions = bundle ? bundle.materialOptions.length > 1 : undefined;

  const refEl = useRef(null);
  const [nameWidth, setNameWidth] = useState(0);

  useLayoutEffect(() => {
    setNameWidth(refEl.current ? (refEl.current as any).scrollWidth : 0);
  }, []);

  return (
    <StyledMaterialCard
      integratedCj2={integratedApp}
      openModal={onClick !== undefined ? true : false}
      onClick={() => {
        if (onClick) {
          triggerEvent({
            action: EventAction.OPEN_MARKETPLACE_CATEGORY,
            category: EventCategory.USER
          });
          onClick();
        }
      }}
      className={classnames({ targeted })}
    >
      {bundleName && <BundleName>{bundleName}</BundleName>}
      {material ? (
        <>
          <StyledMaterialImageWrapper>
            <NotificationsHeader>
              {deadline && !locked && !deadlineGone && (
                <>
                  <MaterialDeadline highlight={deadlineNear}>
                    <p>{deadline}</p>
                    <img src={CalendarIcon} alt='schedule-icon' />
                  </MaterialDeadline>
                </>
              )}
              {isPartOfOffer && !hasPendingOffers && (
                <OfferHeader
                  pending={false}
                  onClick={() => {
                    if (!!offers) offersStore.selectedOffer = offers[0].id;
                    routingStore.push('/order-confirmation');
                  }}
                >
                  {text('includedInOffer')}
                </OfferHeader>
              )}
              {isPartOfOffer && hasPendingOffers && (
                <OfferHeader
                  pending={true}
                  onClick={() => {
                    if (!!offers) offersStore.selectedOffer = offers[0].id;
                    routingStore.push('/order-confirmation');
                  }}
                >
                  {text('waitingForOffer')}
                </OfferHeader>
              )}
            </NotificationsHeader>
            {material.imageUrlSmall ? (
              <ImgWrap>
                <StyledMaterialImage src={material.imageUrlSmall} />
              </ImgWrap>
            ) : (
              <NoImage />
            )}
            {(locked || deadlineGone) && (
              <StyledLockContainer>
                <Lock fill='var(--custom-colorAccent)' />
              </StyledLockContainer>
            )}
            {!locked && hasOptions && (
              <StyledLockContainer data-testid='optionsIcon'>
                <img src={MultipleIcon} alt='multipleOptions-icon' />
              </StyledLockContainer>
            )}
            {material.price && !material.price.equals(0) && (
              <MaterialPrice>{getFormattedMaterialPrice(material.price)}</MaterialPrice>
            )}
          </StyledMaterialImageWrapper>
          <MaterialDescriptionWrapper>
            <MaterialName ref={refEl}>{material.name}</MaterialName>
            {nameWidth > 154 && (
              <NameTooltipContainer>
                <NameTooltip>{material.name}</NameTooltip>
              </NameTooltipContainer>
            )}
            <MaterialDescription>{material.description}</MaterialDescription>
          </MaterialDescriptionWrapper>
        </>
      ) : (
        <>
          <StyledMaterialImageWrapper>
            <NotificationsHeader>
              {deadline && !locked && !deadlineGone && (
                <>
                  <MaterialDeadline noSelection highlight={deadlineNear}>
                    <p>{deadline}</p>
                    <img src={CalendarIcon} alt='schedule-icon' />
                  </MaterialDeadline>
                </>
              )}
              {isPartOfOffer && <OfferHeader>{text('includedInOffer')}</OfferHeader>}
            </NotificationsHeader>

            <NoMaterialContainer>
              <NoMaterial />
            </NoMaterialContainer>
            {(locked || deadlineGone) && (
              <>
                <StyledLockContainer>
                  <Lock fill='var(--custom-colorAccent)' />
                </StyledLockContainer>
              </>
            )}
          </StyledMaterialImageWrapper>
          {!(isPartOfOffer && offers && offers.length === 0) && (
            <MaterialDescriptionWrapper>
              <MaterialName>{text('noSelectionText')}</MaterialName>
            </MaterialDescriptionWrapper>
          )}
        </>
      )}
    </StyledMaterialCard>
  );
};

export default observer(MaterialCard);
