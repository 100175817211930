import React from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../Modal/Modal';
import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';
import Button from '../Button/Button';
import { text, limitStringLength } from '../../utils';
import ThemeSlider from './ThemeSlider';
import Theme from '../../store/models/Theme';
import { Carousel } from 'react-responsive-carousel';
import lightBoxStore from '../../store/LightBoxStore';
import ZoomButton from '../ZoomButton/ZoomButton';
import { modalView } from '../../services';
import { ModalView } from '../../services/ModalView';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import appStore from '../../store/AppStore';
import { useTrackers } from '../../hooks/useTrackers';
import { ButtonType } from '@groupbuilderoy/gb-components-library';
import AuxiliarButton from '../../auxiliaries/AuxiliarButton';

const themeSelectorMobileHeight = '15rem';
const masterImageMobileHeight = '12rem';

const ThemeThumbImage = styled.img`
  max-height: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  width: unset; // This is also set in CarouselContainer!

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const ImagesCarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }

  .carousel .slide {
    background: none;

    ${ThemeThumbImage} {
      width: unset;
    }
  }
`;

const ThemeImages = styled.div`
  min-height: calc(100vh - 3rem);

  @media ${mediaSmallMax} {
    min-height: 100vh;
  }
`;

const ThemeMasterImageContainer = styled.div<{ onClick?: any }>`
  position: relative;
  overflow: hidden;
  min-height: 31.75rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  @media ${mediaSmallMax} {
    width: 100%;
    height: ${masterImageMobileHeight};
    min-height: ${masterImageMobileHeight};

    margin-bottom: ${themeSelectorMobileHeight};
  }
`;

const ThemeMasterImage = styled.img`
  min-width: 100%;
  max-width: 100%;
`;

const ThemeSecondaryImagesContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 51.5rem;
  min-height: 23.75rem;
  max-height: 31.75rem;
  max-width: 100%;
  float: right;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mediaSmallMax} {
    min-height: 0;
  }
`;

const ThemeSecondaryImage = styled.img`
  min-width: 100%;
  max-width: 100%;
`;

const ThemeThumbImagesContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5rem;
  max-height: 5rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
`;

const ThemeSelector = styled.div<{ integratedCj2?: boolean }>`
  height: 31.5rem;
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 5rem;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateY(-50%);
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  width: 19.5rem;
  display: flex;
  flex-direction: column;

  @media ${mediaSmallMax} {
    transform: translateY(0);
    left: 0;
    top: ${masterImageMobileHeight};
    width: 100vw;
    min-height: ${themeSelectorMobileHeight};
    max-height: fit-content;
  }
`;

const InfoCarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  .carousel-root .slider-wrapper .slider {
    height: 100%;
  }

  .carousel-root {
    height: 100%;
  }

  .carousel-slider {
    height: 100%;
  }

  .slider-wrapper {
    height: 100%;
  }

  .carousel-slider {
    height: 100%;
  }

  .carousel .slide {
    height: 100%;
    background: none;
    text-align: left;
  }
`;

const ThemeInformation = styled.div`
  height: 100%;
  padding: 1rem;
  min-width: 19.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ThemeDescription = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: var(--custom-colorAccent2);
  margin: auto;
  min-height: 13rem;
  max-height: 100%;
  overflow-y: auto;
  font-weight: 400;
  font-family: var(--custom-fontName);
  word-break: break-word;

  @media ${mediaSmallMax} {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 1rem;
  }
`;

const ThemeNameContainer = styled.div`
  max-height: 6.5rem;
  border-bottom: 1px solid black;
  @media ${mediaSmallMax} {
    display: flex;
    justify-content: center;
    border: none;
  }
`;

const ThemeDescriptionContainer = styled.div`
  height: 70%;
  display: flex;
  margin: auto;
  @media ${mediaSmallMax} {
    min-height: 4.5rem;

    font-size: 12px;
    line-height: 14px;
    margin-bottom: 1rem;
  }
`;

export const ThemeName = styled.h1`
  font-size: 30px;
  line-height: 2.25rem;
  color: var(--custom-colorAccent2);
  position: relative;
  margin: 0;
  overflow: hidden;
  height: auto;
  max-height: 6.5rem;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;

  @media ${mediaSmallMax} {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 2.5rem;
    margin-top: 0.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 4.5rem;
    bottom: -1.5rem;
    left: 0.5rem;
    background-color: var(--custom-colorAccent3);

    @media ${mediaSmallMax} {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const ChooseThemeBtn = styled(Button)`
  width: 100%;

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

interface ThemeCarouselProps {
  onClose: () => void;
  themes: Theme[];
  selectableThemes: Theme[];
  changeDisplayedTheme: (themeId: number) => void;
  displayedThemeIndex: number;
  onChoose: (theme: Theme) => void;
}

const ThemeCarousel = ({
  onClose,
  themes,
  selectableThemes,
  onChoose,
  changeDisplayedTheme,
  displayedThemeIndex
}: ThemeCarouselProps) => {
  const { triggerEvent } = useTrackers();
  const onSliderClick = (direction: 'left' | 'right') => {
    triggerEvent({
      action: EventAction.BROWSE_PACKAGE_SELECTOR,
      category: EventCategory.USER,
      label: direction
    });
    const oldIndex = displayedThemeIndex;
    const minIndex = 0;
    const maxIndex = selectableThemes.length - 1;
    const newIndex = direction === 'left' ? oldIndex - 1 : oldIndex + 1;

    const indexToChangeTo =
      newIndex < minIndex ? maxIndex : newIndex > maxIndex ? minIndex : newIndex;
    changeDisplayedTheme(indexToChangeTo);
  };

  const disableCarousel = false; // selectableThemes.length === 1;

  const openImages = () => modalView(ModalView.PACKAGE_IMAGES);

  return (
    <Modal onClose={onClose} disableOutsideClick overflow='auto'>
      <ImagesCarouselContainer>
        <Carousel
          selectedItem={displayedThemeIndex}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          centerSlidePercentage={30}
          useKeyboardArrows
          onChange={changeDisplayedTheme}
        >
          {selectableThemes.map((theme) => {
            return (
              <ThemeImages key={theme.id || 0}>
                {theme.masterImage && (
                  <ThemeMasterImageContainer
                    onClick={() => {
                      openImages();
                      lightBoxStore.open(theme.imageUrls);
                    }}
                  >
                    <ThemeMasterImage
                      src={theme.masterImage ? theme.masterImage.url : ''}
                      alt={theme.name}
                    />
                    <ZoomButton
                      onClick={() => {
                        openImages();
                        lightBoxStore.open(theme.imageUrls);
                      }}
                    />
                  </ThemeMasterImageContainer>
                )}
                {theme.images.length > 1 && (
                  <ThemeSecondaryImagesContainer
                    onClick={() => {
                      openImages();
                      lightBoxStore.open(theme.imageUrls, 1);
                    }}
                  >
                    <ThemeSecondaryImage src={theme.imageUrls[1]} />
                    {theme.thumbImages && (
                      <ThemeThumbImagesContainer>
                        {theme.thumbImages.map((image, i) => (
                          <ThemeThumbImage
                            src={image.thumbnailUrl}
                            key={image.thumbnailUrl}
                            onClick={(e) => {
                              e.stopPropagation();
                              openImages();
                              lightBoxStore.open(theme.imageUrls, i + 2);
                            }}
                          />
                        ))}
                      </ThemeThumbImagesContainer>
                    )}
                  </ThemeSecondaryImagesContainer>
                )}
              </ThemeImages>
            );
          })}
        </Carousel>
      </ImagesCarouselContainer>
      <ThemeSelector>
        <InfoCarouselContainer>
          <Carousel
            selectedItem={displayedThemeIndex}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            centerSlidePercentage={30}
            useKeyboardArrows={true}
            onChange={changeDisplayedTheme}
          >
            {selectableThemes.map((theme) => {
              return (
                <ThemeInformation key={theme.id || 0}>
                  <div style={{ height: 'fit-content' }}>
                    <ThemeNameContainer>
                      <ThemeName>{limitStringLength(theme.name, 50, 48)}</ThemeName>
                    </ThemeNameContainer>
                  </div>
                  <ThemeDescriptionContainer>
                    <ThemeDescription>{theme.description}</ThemeDescription>
                  </ThemeDescriptionContainer>
                  {
                    <AuxiliarButton
                      caption={theme.selected ? text('continue') : text('start')}
                      onClick={() => {
                        if (theme.selected)
                          triggerEvent({
                            action: EventAction.CONTINUE_EDITING_PACKAGE,
                            category: EventCategory.USER
                          });
                        else
                          triggerEvent({
                            action: EventAction.SELECT_PACKAGE,
                            category: EventCategory.USER
                          });
                        onChoose(theme);
                      }}
                      type={ButtonType.contained}
                    />
                  }
                </ThemeInformation>
              );
            })}
          </Carousel>
        </InfoCarouselContainer>
        {!disableCarousel && (
          <ThemeSlider
            onLeft={() => onSliderClick('left')}
            onRight={() => onSliderClick('right')}
          />
        )}
      </ThemeSelector>
    </Modal>
  );
};

export default observer(ThemeCarousel);
