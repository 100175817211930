import { Attachment, SignableAttachment } from './Attachment';
import { Contact } from './Contact';

export enum SectionType {
  Folder = 0,
  DocumentsAndLinks = 4,
  Contacts = 1
}
export interface Section {
  description: string;
  id: number;
  imagefilename: string | null;
  name: string;
  ordernumber: number;
  parentSectionId: number | null;
  phaseId: number | null;
  type: SectionType;
  attachments?: Attachment[];
  contacts?: Contact[];
  sections: Section[];
  path?: string;
  signableAttachments?: SignableAttachment[];
}
