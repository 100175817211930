export const Languages = [
  {
    code: 'en-US',
    title: 'English (US)'
  },
  {
    code: 'en-GB',
    title: 'English (GB)'
  },
  {
    code: 'fi',
    title: 'Suomi'
  },
  {
    code: 'de',
    title: 'Deutsch'
  },
  {
    code: 'ru',
    title: 'Русский'
  },
  {
    code: 'et',
    title: 'Estonian'
  },
  {
    code: 'nb',
    title: 'Norsk'
  },
  {
    code: 'es',
    title: 'Spanish'
  },
  {
    code: 'cs',
    title: 'Czech'
  },
  {
    code: 'sk',
    title: 'Slovak'
  },
  {
    code: 'sv',
    title: 'Svenska'
  },
  {
    code: 'lt',
    title: 'Lithuanian'
  },
  {
    code: 'fr',
    title: 'Français'
  },
  {
    code: 'nl',
    title: 'Dutch'
  },
  {
    code: 'ar',
    title: 'اَلْعَرَبِيَّةُ'
  }
];
