import { observable, computed, action } from 'mobx';
import Theme from './models/Theme';
import {
  fetchThemes,
  selectPackage,
  changeHasReviewedOptions,
  deselectPackagesFromPackageSet
} from '../axios';
import appStore from './AppStore';

import alertStore from './AlertStore';
import { text, scrollTo } from '../utils';
import ThemeSet from './models/ThemeSet';
import materialSelectorStore from './MaterialSelectorStore';
import routingStore, { changeLocation } from './RoutingStore';
import { triggerGlobalEvent, triggerGAError } from '../services';
import { EventAction } from '../services/EventAction';
import { EventCategory } from '../services/EventCategory';
import debounce from 'lodash.debounce';
import { datePassed } from '../utils/dates';

export class ThemeStore {
  @observable public displayedThemeIndex: number = 0;
  @observable public themesOpen = true;
  @observable public themeSets: ThemeSet[] = [];
  @observable public subThemeSets: ThemeSet[] = [];
  @observable public themeSelectionOpen: boolean = false;

  @observable public themesFetched = false;

  @observable public startedEditingThemeSet: boolean = false;

  @action
  public changeDisplayedTheme = (index: number) => {
    this.displayedThemeIndex = index;
  };

  @action
  public onSelectTheme = async (id?: number, firstSelection = false, scrollToMaterials = false) => {
    try {
      materialSelectorStore.showSpinnerFlag(true);

      if (
        this.selectedThemeSet &&
        (!this.selectedTheme || (this.selectedTheme && this.selectedTheme.id !== id))
      ) {
        const promises: Array<Promise<boolean>> = this.themes.map(async (theme) => {
          try {
            if (theme.id === id) {
              const resp = await selectPackage(
                appStore.organisationId,
                appStore.tenantId,
                appStore.projectId,
                appStore.apartmentId,
                theme.id
              );
              return !resp.error;
            }
            return true;
          } catch (e) {
            return false;
          }
        });

        const successes = await Promise.all(promises);
        if (successes.some((s) => !s)) throw new Error('Failed to select package.');
      }

      await appStore.fetchApartmentInfo();
      await this.fetchThemeSets();

      if (firstSelection && !routingStore.location.pathname.includes('version'))
        changeLocation('/materials');

      materialSelectorStore.showSpinnerFlag(false);
    } catch (e) {
      materialSelectorStore.showSpinnerFlag(false);
      triggerGAError('selecting theme failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.selectingThemeFailed'));
    }
  };

  public onSelectSubTheme = async (id?: number) => {
    try {
      materialSelectorStore.showSpinnerFlag(true);

      if (this.selectedSubThemeSet && id && !this.allSelectedThemeIds.includes(id)) {
        const promises: Array<Promise<boolean>> = this.subThemes.map(async (theme) => {
          try {
            if (theme.id === id) {
              materialSelectorStore.showSpinnerFlag(true);
              const resp = await selectPackage(
                appStore.organisationId,
                appStore.tenantId,
                appStore.projectId,
                appStore.apartmentId,
                theme.id
              );
              return !resp.error;
            }
            return true;
          } catch (e) {
            return false;
          }
        });

        const successes = await Promise.all(promises);
        if (successes.some((s) => !s)) throw new Error('Failed to select package.');
        await appStore.fetchApartmentInfo();
        await this.fetchThemeSets();
      }
      materialSelectorStore.showSpinnerFlag(false);
    } catch (e) {
      materialSelectorStore.showSpinnerFlag(false);
      triggerGAError('selecting theme failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.selectingThemeFailed'));
    }
  };

  @action public deselectSubThemesFromSubThemeSet = async (themeSetId: number) => {
    materialSelectorStore.showSpinnerFlag(true);
    try {
      if (this.selectedSubThemeSet?.id === themeSetId) {
        const promises: Array<Promise<boolean>> = this.subThemes.map(async (theme) => {
          try {
            const resp = await deselectPackagesFromPackageSet(
              appStore.organisationId,
              appStore.tenantId,
              appStore.projectId,
              appStore.apartmentId,
              themeSetId
            );
            return !resp.error;
          } catch (e) {
            return false;
          }
        });

        const successes = await Promise.all(promises);
        if (successes.some((s) => !s)) throw new Error('Failed to deselect packages.');
        await appStore.fetchApartmentInfo();
        await this.fetchThemeSets();
      }
      materialSelectorStore.showSpinnerFlag(false);
    } catch (e) {
      materialSelectorStore.showSpinnerFlag(false);
      triggerGAError('deselecting themes failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.selectingThemeFailed'));
    }
  };

  @action public toggleThemesOpen = (themesOpen?: boolean, clicked?: boolean) => {
    if (!themesOpen)
      triggerGlobalEvent(
        {
          action: EventAction.OPEN_PACKAGES,
          category: clicked ? EventCategory.USER : EventCategory.SYSTEM
        },
        {
          organisation: appStore.organisationName,
          project: appStore.projectId,
          apartment: appStore.apartmentId
        }
      );
    if (themesOpen)
      triggerGlobalEvent(
        {
          action: EventAction.CLOSE_PACKAGES,
          category: clicked ? EventCategory.USER : EventCategory.SYSTEM
        },
        {
          organisation: appStore.organisationName,
          project: appStore.projectId,
          apartment: appStore.apartmentId
        }
      );
    this.themesOpen = themesOpen !== undefined ? themesOpen : !this.themesOpen;
  };

  @action public fetchThemeSets = debounce(async () => {
    if (appStore.apartmentId && appStore.organisationId && appStore.tenantId) {
      materialSelectorStore.showSpinnerFlag(true);
      try {
        const response = await fetchThemes(
          appStore.organisationId,
          appStore.tenantId,
          appStore.projectId,
          appStore.apartmentId
        );

        const makeThemeSet = (ps: any) => {
          const packages = ps.packages.map((p: any) => {
            const newTheme = new Theme(p);
            if (newTheme.selected && newTheme.canSelectComputed) {
              if (
                !this.selectedThemeSet &&
                !appStore.tenantSettings.customerJourney2_usePackageBundlesConfirm
              ) {
                ps.selected = true;
              }
              newTheme.select();
            }
            return newTheme;
          });
          const newThemeSet = new ThemeSet({ ...ps, packages });
          if (
            newThemeSet.id ===
            (!ps.isSubPackageSet ? this.selectedThemeSet?.id : this.selectedSubThemeSet?.id)
          ) {
            newThemeSet.select();
          }
          return newThemeSet;
        };

        const { themeSets, subThemeSets } = response.data.packageSets.reduce(
          (prev: { themeSets: ThemeSet[]; subThemeSets: ThemeSet[] }, ps: any) => {
            if (ps.isSubPackageSet) {
              prev.subThemeSets.push(makeThemeSet(ps));
            } else {
              prev.themeSets.push(makeThemeSet(ps));
            }
            return prev;
          },
          { themeSets: [], subThemeSets: [] }
        );

        this.themeSets = themeSets;
        this.subThemeSets = subThemeSets;

        this.themesFetched = true;

        if (this.selectedTheme) {
          materialSelectorStore.fetchBundles();
          this.startEditingThemeSet();
          materialSelectorStore.toggleMaterialsOpen(true);
          if (
            !routingStore.location.pathname.includes('order-confirmation') &&
            !routingStore.location.pathname.includes('version')
          )
            changeLocation('/materials');
        }

        if (
          this.themesLocked &&
          !routingStore.location.pathname.includes('order-confirmation') &&
          !routingStore.location.pathname.includes('version')
        ) {
          changeLocation('/materials');
        }
      } catch (e) {
        triggerGAError('fetching themes failed', e.toString());
        console.error(e);
        alertStore.show(text('errors.fetchingThemesFailed'));
      }
      materialSelectorStore.showSpinnerFlag(false);
    }
  });

  @action public startEditingThemeSet = () => {
    this.startedEditingThemeSet = true;
  };

  @action public selectThemeSet = (id: number, scroll?: boolean) => {
    const toggleSelections = (ts: ThemeSet, onSelect: (themeId: number) => void) => {
      if (ts.id === id) {
        ts.select();
        if (
          ts.themes.length === 1 &&
          ts.themes[0].id !== null &&
          ts.themes[0].canSelectComputed &&
          !themeStore.selectedSubThemeSet?.canBeIgnored
        )
          onSelect(ts.themes[0].id);
      } else if (ts.selected && ts.id !== id) ts.deselect();
      return ts;
    };

    if (this.themeSets.find((ts) => ts.id === id)) {
      this.themeSets = this.themeSets.map((ts) => toggleSelections(ts, this.onSelectTheme));

      if (scroll) {
        scrollTo('themes-grid', true);
      }
    } else {
      this.subThemeSets = this.subThemeSets.map((ts) =>
        toggleSelections(ts, this.onSelectSubTheme)
      );
    }
  };

  @action public deselectThemeSets = () => {
    this.allThemeSets.forEach((ts) => {
      if (ts.selected) ts.deselect();
      return ts;
    });
  };

  @action public deselectSubThemeSets = () => {
    this.subThemeSets.forEach((ts) => {
      if (ts.selected) ts.deselect();
      return ts;
    });
  };

  @action
  public onCheckThemeSet = async () => {
    if (this.selectedTheme && this.selectedTheme.id !== null) {
      triggerGlobalEvent({
        action: EventAction.CONFIRM_PACKAGE_MATERIALS,
        category: EventCategory.USER
      });
      try {
        const response = await changeHasReviewedOptions(
          appStore.organisationId,
          appStore.tenantId,
          appStore.projectId,
          appStore.apartmentId,
          this.selectedTheme.id,
          true
        );

        if (response.data.success) {
          this.selectedTheme.hasReviewedOptions = true;
          this.deselectThemeSets();
          this.toggleThemesOpen(true, false);
        } else throw new Error('Failed to review options.');
      } catch (e) {
        triggerGAError('checking package failed', e.toString());
        console.error(e);
        alertStore.show(text('errors.checkingPackageFailed'));
      }
    }
  };

  @action
  public changeThemeSelectionOpen = (open: boolean) => {
    this.themeSelectionOpen = open;
  };

  @computed
  get allThemeSets() {
    return this.themeSets.concat(this.subThemeSets);
  }

  @computed
  get displayedTheme() {
    return this.selectableThemes[this.displayedThemeIndex];
  }

  @computed
  get selectedTheme() {
    return this.themes
      .filter(
        (t, _, ts) =>
          t.canSelectComputed !== false ||
          (ts.every((tm) => tm.canSelectComputed === false) && t.selected)
      )
      .find((theme) => theme.selected);
  }

  @computed
  get selectedSubThemes() {
    return this.subThemes.filter(
      (t, _, ts) =>
        t.canSelectComputed !== false ||
        (ts.every((tm) => tm.canSelectComputed === false) && t.selected)
    );
  }

  @computed
  get themes() {
    return this.selectedThemeSet ? this.selectedThemeSet.themes : [];
  }

  @computed
  get subThemes() {
    return this.selectedSubThemeSet ? this.selectedSubThemeSet.themes : [];
  }

  @computed
  get selectableThemes() {
    const filterSelectableThemes = (t: Theme, _: number, ts: Theme[]) =>
      t.canSelectComputed !== false ||
      (ts.every((tm) => tm.canSelectComputed === false) && t.selected);

    if (this.selectedSubThemeSet) {
      if (this.selectedSubThemeSet.canBeIgnored) {
        const noSelectionTheme = new Theme({
          id: null,
          packageSetId: this.selectedSubThemeSet.id,
          name: 'No selection',
          displayName: 'No selection',
          cost: '',
          description: '',
          images: [],
          canSelect: true,
          roomIds: [],
          selected: false
        });
        return [noSelectionTheme, ...this.subThemes.filter(filterSelectableThemes)];
      } else {
        return this.subThemes.filter(filterSelectableThemes);
      }
    }
    return this.themes.filter(filterSelectableThemes);
  }

  @computed
  get allThemes(): Theme[] {
    return this.themeSets.reduce((pre, cur) => {
      pre.push(...cur.themes);
      return pre;
    }, [] as Theme[]);
  }

  @computed
  get selectedThemeSet(): ThemeSet | undefined {
    return this.themeSets.length > 1 && appStore.tenantSettings.customerJourney2_usePackageSets
      ? this.themeSets.find((ts) => ts.selected)
      : !!this.themeSets.length
      ? this.themeSets[0]
      : undefined;
  }

  @computed
  get selectedSubThemeSet(): ThemeSet | undefined {
    return !!this.subThemeSets.length ? this.subThemeSets.find((ts) => ts.selected) : undefined;
  }

  @computed
  get themesLocked(): boolean {
    return (
      this.themesFetched && this.themes.every((t) => !t.canSelectComputed && t.selected === false)
    );
  }

  @computed
  get allSelectedThemeIds() {
    const reduceIds = (themeSets: ThemeSet[]) =>
      themeSets.reduce((selectedThemes: any[], themeSet: ThemeSet) => {
        return [...selectedThemes, ...themeSet.selectedThemes.map((t) => t.id)];
      }, []);

    return reduceIds(this.themeSets).concat(reduceIds(this.subThemeSets));
  }

  // regular and sub themes
  @computed
  get allSelectedThemeIdsFromSelectedSets() {
    const reduceIds = (themeSets: ThemeSet[]) =>
      themeSets.reduce((selectedThemes: any[], themeSet: ThemeSet) => {
        return [...selectedThemes, ...themeSet.selectedThemes.map((t) => t.id)];
      }, []);

    return reduceIds(this.selectedThemeSet ? [this.selectedThemeSet] : []).concat(
      reduceIds(this.subThemeSets)
    );
  }

  @computed
  get allSelectedRegularThemeIdsFromSelectedSets() {
    const reduceIds = (themeSets: ThemeSet[]) =>
      themeSets.reduce((selectedThemes: any[], themeSet: ThemeSet) => {
        return [...selectedThemes, ...themeSet.selectedThemes.map((t) => t.id)];
      }, []);

    return reduceIds(this.selectedThemeSet ? [this.selectedThemeSet] : []);
  }

  @computed
  get allSelectedSubThemeIdsFromSelectedSets() {
    const reduceIds = (themeSets: ThemeSet[]) =>
      themeSets.reduce((selectedThemes: any[], themeSet: ThemeSet) => {
        return [...selectedThemes, ...themeSet.selectedThemes.map((t) => t.id)];
      }, []);

    return reduceIds(this.subThemeSets);
  }

  @computed
  get someThemesAreLocked() {
    return themeStore.themes.some((t) => t.canSelectComputed === false);
  }

  @computed
  get themesAreLocked() {
    return (
      this.someThemesAreLocked && (!this.selectedThemeSet?.deadline || !this.themesPassedDeadline)
    );
  }

  @computed
  get themesPassedDeadline() {
    return this.selectedThemeSet?.deadline && datePassed(this.selectedThemeSet.deadline);
  }

  @computed
  get themesAreSelectable() {
    return !this.themesPassedDeadline && !this.themesAreLocked;
  }
}

const themeStore = new ThemeStore();

export default themeStore;
