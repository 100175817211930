import OfferRowState from '../../store/enums/OfferRowState';
import { OfferRowData } from '../../store/models/OfferRow';

export const offerRowSort = (a: OfferRowData, b: OfferRowData) => {
  if (a.state === OfferRowState.ACCEPTED || b.state === OfferRowState.ACCEPTED) {
    return a.state === OfferRowState.ACCEPTED ? -1 : 1;
  }

  if (a.state === OfferRowState.REJECTED || b.state === OfferRowState.REJECTED) {
    return a.state === OfferRowState.REJECTED ? 1 : -1;
  }

  return a.name.localeCompare(b.name);
};
