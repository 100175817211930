import { observable } from 'mobx';
import AttachmentDecorator from './AttachmentDecorator';
import AttachmentInterface from '../interfaces/AttachmentInterface';

class ProjectAttachmentDecorator extends AttachmentDecorator {
  @observable public projectId: number;

  public constructor(
    attachment: AttachmentInterface,
    source: {
      projectId: number;
    }
  ) {
    super(attachment);
    this.projectId = source.projectId;
  }
}

export default ProjectAttachmentDecorator;
