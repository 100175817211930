import React from 'react';
import Modal from '../../components/Modal/Modal';
import { changeParams } from '../../store/RoutingStore';
import appStore from '../../store/AppStore';
import { observer } from 'mobx-react-lite';
import SchedulesBackground from './SchedulesBackground.png';
import styled from 'styled-components';
import { mediaSmallMax, mediaMediumMax } from '../../components/Styled/media';
import ModalHeader, { StyledModalHeader } from '../../components/Modal/ModalHeader';
import { text } from '../../utils';
import calendarStore from '../../store/CalendarStore';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import ModalEmbed from '../../components/ModalEmbed/ModalEmbed';
import schedules from './schedules';

const StyledSchedules = styled.section`
  height: 100%;

  @media ${mediaSmallMax} {
    ${StyledModalHeader} {
      display: none;
    }
  }
`;

const Content = styled.div`
  padding: 3rem;

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const ContentTitle = styled.h2`
  @media ${mediaSmallMax} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const ContentDesc = styled.h3`
  margin-top: 2rem;
  font-size: 24px;
  line-height: 1.5;

  @media ${mediaSmallMax} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 1rem;
`;

const EventWrapper = styled.div`
  margin: 1rem 0;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 15rem;
  grid-column-gap: 5rem;

  @media ${mediaMediumMax} {
    grid-template-columns: 1fr 10rem;
    grid-column-gap: 3rem;
  }

  @media ${mediaSmallMax} {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }
`;

const EventTitle = styled.h4`
  font-size: 24px;

  @media ${mediaSmallMax} {
    font-size: 20px;
    line-height: 24px;
    margin: 4px 0;
  }
`;
const EventDescription = styled.p`
  font-size: 18px;
  line-height: 22px;
  margin: 6px 0 2px 0;
`;
const EventDate = styled.p`
  font-size: 24px;

  grid-column: 2;
  grid-row: 1 / -1;

  display: flex;
  align-items: center;

  @media ${mediaSmallMax} {
    grid-row: 2;
    grid-column: 1;

    font-size: 18px;
    line-height: 22px;
  }
`;

const OtherDeadlines = styled.div`
  display: flex;
  flex-direction: column;
`;

const OtherDeadlinesTitle = styled.h4``;

const OtherDeadline = styled.div`
  /* display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 15rem;
  grid-column-gap: 5rem;
  margin: 8px 0;
  align-items: center;

  @media ${mediaMediumMax} {
    grid-template-columns: 1fr 10rem;
    grid-column-gap: 3rem;
  } */
`;

const BundleName = styled.p`
  margin: 0px 0px 10px;
  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const BundleDeadline = styled.p`
  font-size: 18px;
  font-weight: 600;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 22px;
  }
`;

const BundleNamesContainer = styled.div`
  margin: 0px 0px 35px;
`;

const HorizontalRule = styled.hr`
  margin: 10px 0px 15px;
  width: 100%;
`;

const AdditionalInformation = styled.div`
  background-color: var(--custom-colorNotif1);
  color: white;
  padding: 3rem;

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const Schedule = () => {
  const embedSrc = schedules[appStore.projectIdentifier];

  return (
    <Modal
      closeBtnOffset='3rem'
      onClose={() => changeParams({ schedule: undefined })}
      overflow='auto'
    >
      {embedSrc ? (
        <ModalEmbed src={schedules[appStore.projectIdentifier]} />
      ) : (
        <StyledSchedules>
          <ModalHeader
            bgImage={SchedulesBackground}
            title={text('orderSchedule')}
            description={appStore.projectName}
          />
          <Content>
            <ContentTitle>{text('orderScheduleTexts.orderScheduleDescriptionTitle')}</ContentTitle>
            <ContentDesc>{text('orderScheduleTexts.orderScheduleDescriptionText')}</ContentDesc>
            <Events>
              {calendarStore.calendarEvents.map((ce) => (
                <EventWrapper key={ce.id}>
                  <EventTitle>{ce.name}</EventTitle>
                  <EventDescription>{ce.description}</EventDescription>
                  <EventDate>{ce.eventDate}</EventDate>
                </EventWrapper>
              ))}
            </Events>
            {materialSelectorStore.bundlesGroupedByDeadlineExcludingEventDeadlines.size > 0 && (
              <OtherDeadlines>
                <OtherDeadlinesTitle className='font-bold'>
                  {!!calendarStore.calendarEvents.length
                    ? text('orderScheduleTexts.otherDeadlines')
                    : text('orderScheduleTexts.orderDeadlines')}
                </OtherDeadlinesTitle>
                {Array.from(
                  materialSelectorStore.bundlesGroupedByDeadlineExcludingEventDeadlines,
                  ([key, value]) => (
                    <OtherDeadline key={key}>
                      <BundleDeadline>{key}</BundleDeadline>
                      <HorizontalRule />
                      <BundleNamesContainer>
                        {value.map((a: any) => (
                          <BundleName key={a.bundleName}>{a.bundleName}</BundleName>
                        ))}
                      </BundleNamesContainer>
                    </OtherDeadline>
                  )
                )}
              </OtherDeadlines>
            )}
          </Content>
          <AdditionalInformation>
            {
              text('orderScheduleTexts.orderScheduleAdditionalInformation')
                .split('\n')
                .map((t) => (
                  <p key={t}>{t}</p>
                )) /* Lets allow line breaks */
            }
          </AdditionalInformation>
        </StyledSchedules>
      )}
    </Modal>
  );
};

export default observer(Schedule);
