import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { Offer } from '../../store/models/Offer';
import OfferRows from './OfferRows';
import { SectionArrow } from '../Styled/SectionTitleDivider';
import Button from '../Button/Button';
import { text } from '../../utils';
import appStore from '../../store/AppStore';
import MessagesIcon from '../Icons/messages.svg';
import CircleBadge from '../CircleBadge/CircleBadge';
import DownloadIcon from '../Icons/download.svg';
import { OffersStore } from '../../store/OffersStore';
import confirmationDialogStore from '../../store/ConfirmationDialogStore';
import { mediaSmallMax } from '../Styled/media';
import {
  RoomDivider,
  ConfirmationPhaseTitle,
  StyledSectionResizer,
  PhaseHeader
} from '../ConfirmationPhases/ConfirmationPhasesStyledComponents';
import InfoBox from '../InfoBox/InfoBox';
import { offerRowSort } from './utils';
import AuxiliarButton from '../../auxiliaries/AuxiliarButton';
// change DB4D69 to var(--custom-colorNotif2)

const OfferDescription = styled.p`
  font-weight: 300;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--custom-colorAccent2);

  @media ${mediaSmallMax} {
    margin: 1rem;
    font-size: 16px;
  }
`;

const StyledRoomDivider = styled(RoomDivider)<{
  isOpenOfferOrDeadline: boolean;
  integratedApp?: boolean;
  overrideBackgroundColor?: string;
}>`
  height: ${({ integratedApp }) => integratedApp && '50px'};
  border-radius: ${({ integratedApp }) => integratedApp && '8px'};
  background-color: ${({ isOpenOfferOrDeadline, overrideBackgroundColor }) =>
    isOpenOfferOrDeadline
      ? overrideBackgroundColor
        ? overrideBackgroundColor
        : 'var(--custom-colorNotif1)'
      : 'var(--color-lightGray)'};
  color: ${({ isOpenOfferOrDeadline, overrideBackgroundColor }) =>
    isOpenOfferOrDeadline
      ? overrideBackgroundColor
        ? 'var(--custom-colorAccent2)'
        : 'white'
      : 'var(--custom-colorAccent2)'};

  & svg {
    fill: ${({ isOpenOfferOrDeadline, overrideBackgroundColor }) =>
      isOpenOfferOrDeadline
        ? overrideBackgroundColor
          ? 'var(--custom-colorAccent2)'
          : 'white'
        : 'var(--custom-colorAccent2)'};
  }
`;

const OfferInformationContainer = styled.div`
  margin: 1rem 3rem;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;

  @media ${mediaSmallMax} {
    margin: 0;
  }
`;

const Footer = styled.div`
  margin-top: 2rem;
  background: #f4f4f4;
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: row;

  @media ${mediaSmallMax} {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: none;
  }
`;

const MessagesAndPrintContainer = styled.div`
  margin-top: 2rem;

  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: row;

  @media ${mediaSmallMax} {
    margin-top: 1rem;
  }
`;

const StyledButtonText = styled.p`
  margin: auto;
  font-style: normal;
  font-weight: normal;
  text-align: center;
`;

const StyledText = styled.p`
  font-size: 18px;
  line-height: 29px;
  color: var(--custom-colorAccent2);

  @media ${mediaSmallMax} {
    font-size: inherit;
    line-height: inherit;
  }
`;
const AcceptedRowsText = styled(StyledText)`
  font-weight: bold;
`;

const TotalPriceText = styled(StyledText)`
  font-style: normal;
  font-weight: 300;
  margin: auto 10px auto auto;
`;

const DownloadPDFText = styled(TotalPriceText)`
  text-decoration: underline;
`;

const MessagesButton = styled(Button)`
  display: flex;
  padding: 0 1.5rem 0 1.5rem;
  height: 48px;
  background: #ffffff;
  border: 1px solid var(--custom-colorAccent2);
  width: 210px;
  border: 1px solid var(--custom-colorAccent2);
  box-sizing: border-box;
  background: white;
  margin-left: 1rem;
  color: var(--custom-colorAccent2);

  &:hover {
    background: white !important;
    color: var(--custom-colorAccent2) !important;
  }
`;

const MessagesButtonBadge = styled(CircleBadge)`
  position: absolute;
  top: -6px;
  right: 5px;
  background-color: var(--custom-colorNotif3);
  transform: translateX(50%);
  font-family: var(--custom-fontNameLight);
`;

const StyledDownloadImg = styled.img`
  @media ${mediaSmallMax} {
    max-width: 22px;
  }
`;

const DownloadPDFContainer = styled.div`
  margin: auto 0px auto auto;
  display: flex;
  cursor: pointer;

  @media ${mediaSmallMax} {
    margin: auto !important;

    & p {
      font-size: 15px;
    }
  }
`;

const FooterDescription = styled.div`
  margin: auto 0 auto 0;

  @media ${mediaSmallMax} {
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    padding: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  margin: auto 0px auto auto;

  @media ${mediaSmallMax} {
    margin: auto;
    margin-top: 1rem;
  }
`;

interface OffersConfirmationDropdownProps {
  offersStore: OffersStore;
  openAtStart: boolean;
  offer: Offer;
}

const OffersConfirmationDropdown = ({
  offersStore,
  openAtStart,
  offer
}: OffersConfirmationDropdownProps) => {
  const [maxHeight, setMaxHeight] = useState('48px');
  const [isOpen, setIsOpen] = useState(!!openAtStart);
  const [acceptedRows, setAcceptedRows] = useState(
    offer.rows.filter((row) => row.isAccepted).length
  );
  const [price, setPrice] = useState(offer.formatedPrice);
  const [repeatedBundles, setRepeatedBundles] = useState(offersStore.getRepeatedBundles(offer));

  const canOfferBeAccepted =
    offersStore.isOfferReadyToAccept(offer.id, offer.rows) && repeatedBundles.length === 0;

  useEffect(() => {
    if (offer.isOpen) {
      setPrice(offer.getSelectedRowsPrice());
      const rows = offersStore.getAcceptedRows(offer.id);
      if (rows) setAcceptedRows(rows.length);
      setRepeatedBundles(offersStore.getRepeatedBundles(offer));
    } else {
      setPrice(offer.formatedPrice);
      setAcceptedRows(offer.acceptedRows.length);
    }
  }, [offersStore.flag]); // eslint-disable-line

  const reset = async () => {
    await offersStore.fetchApartmentOffers();
  };

  const confirmationTexts = {
    confirmationTitle: text('modalOfferTitle'),
    confirmationText:
      appStore.organisationId === 'srv'
        ? text('modalOfferDesc') +
          price?.toString() +
          appStore.currencySymbol +
          text('additionalModalOfferDesc')
        : text('modalOfferDesc') + price?.toString() + appStore.currencySymbol,
    okText: text('acceptOffer'),
    cancelText: text('cancel')
  };

  const warningModalTexts = {
    confirmationTitle: text('offerModal.attention'),
    confirmationText: text('offerModal.modalText'),
    okText: text('offerModal.viewOffer'),
    cancelText: text('offerModal.closeOffer')
  };

  const acceptOffer = async () => {
    const success = await offersStore.updateOfferRowStates(offer.id);
    if (success) {
      reset();
    }
  };

  return (
    <>
      <PhaseHeader style={{ maxHeight, minHeight: maxHeight }}>
        <StyledSectionResizer effectListeners={[isOpen, 3]} setNewHeight={setMaxHeight}>
          <StyledRoomDivider
            id={offer.isOpen && isOpen ? 'preventUnload' : ''}
            overrideBackgroundColor={
              appStore.organisationId === 'srv' && isOpen ? 'var(--custom-colorMain)' : '#FFE856'
            }
            isOpenOfferOrDeadline={!!offer.deadline || offer.isOpen}
            integratedApp={appStore.integratedApp}
            open={isOpen}
            onClick={async () => {
              if (offer.isOpen && isOpen) {
                confirmationDialogStore.open(
                  warningModalTexts,
                  () => {
                    return null;
                  },
                  () => {
                    setIsOpen(!isOpen);
                  }
                );
              } else {
                setIsOpen(!isOpen);
              }
            }}
          >
            <ConfirmationPhaseTitle integratedApp={appStore.integratedApp}>
              {`${offer.normalizedState}${
                offer.isAccepted ? ` (${offer.acceptedRows.length}/${offer.rows.length})` : ''
              } : ${offer.name} ${offer.formatedDeadline ? `- ${offer.formatedDeadline}` : ''}`}
            </ConfirmationPhaseTitle>
            <SectionArrow open={isOpen} />
          </StyledRoomDivider>
          {isOpen && (
            <OfferInformationContainer>
              <OfferDescription>
                {offer.description} <br /> {text('offerValidFrom') + offer.formatedOfferedOn}
                {offer.formatedDeadline ? ' - ' + offer.formatedDeadline : null}
              </OfferDescription>
              {offer.rows.sort(offerRowSort).map((row) => {
                return (
                  <OfferRows offersStore={offersStore} row={row} offerId={offer.id} key={row.id} />
                );
              })}
              <MessagesAndPrintContainer>
                <div style={{ margin: 'auto 0 auto 0', display: 'none' }}>
                  <MessagesButton>
                    <MessagesButtonBadge>!</MessagesButtonBadge>
                    <img src={MessagesIcon} alt={MessagesIcon} />{' '}
                    <StyledButtonText>{text('offerMessage')}</StyledButtonText>
                  </MessagesButton>
                </div>

                <DownloadPDFContainer onClick={() => offersStore.printOffer(offer)}>
                  <DownloadPDFText>{text('downloadOfferPDF')}</DownloadPDFText>{' '}
                  <StyledDownloadImg src={DownloadIcon} alt={DownloadIcon} />
                </DownloadPDFContainer>
              </MessagesAndPrintContainer>
              {!!repeatedBundles.length && (
                <InfoBox
                  styles={{ marginTop: '1rem', marginBottom: '-1rem' }}
                  title={text('offerWarningMessage.selectOnlyOneOfferLine')}
                  description={text('offerWarningMessage.selectOnlyOneOfferLineDescription', {
                    bundleName: repeatedBundles[0].name
                  })}
                />
              )}
              <Footer>
                <FooterDescription>
                  <AcceptedRowsText>
                    {acceptedRows}/{offer.rows.length} {text('changesAccepted')}
                  </AcceptedRowsText>
                  <TotalPriceText>
                    {text('totalPrice')}{' '}
                    {price
                      ? price.toFixed(appStore.tenantSettings.pricePrecision).toString()
                      : '0,00'}{' '}
                    {appStore.currencySymbol}
                  </TotalPriceText>
                </FooterDescription>
                {offer.isOpen && (
                  <ButtonContainer>
                    {
                      <AuxiliarButton
                        caption={text('acceptOffer')}
                        disabled={!canOfferBeAccepted}
                        onClick={() => {
                          if (canOfferBeAccepted) {
                            confirmationDialogStore.open(
                              confirmationTexts,
                              acceptOffer,
                              () => {
                                return null;
                              },
                              () => {
                                return null;
                              },
                              'green'
                            );
                          }
                        }}
                      />
                    }
                  </ButtonContainer>
                )}
              </Footer>
            </OfferInformationContainer>
          )}
        </StyledSectionResizer>
      </PhaseHeader>
    </>
  );
};

export default observer(OffersConfirmationDropdown);
