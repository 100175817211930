import { useState, useEffect } from 'react';
import appStore from '../store/AppStore';
import { useFetchProjectSettings } from './useFetchProjectSettings';

interface ShowMessagingProps {
  messagingEnabled: boolean;
  newMessagingEnabled: boolean;
}

/**
 * useMessaging hook
 *
 * @returns - ShowMessagingProps
 */
export const useShowMessaging = (): ShowMessagingProps => {
  const { tenantSettings, tenantSettingsFetched } = appStore;
  const {
    loading: loadingProjectSettings,
    settings: projectSettings,
    error: projectSettingsError
  } = useFetchProjectSettings();
  const [messagingEnabled, setMessagingEnabled] = useState<boolean>(false);
  const [newMessagingEnabled, setNewMessagingEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (loadingProjectSettings || !tenantSettingsFetched || !tenantSettings.useMessaging) return;

    let newMessagingProjectSetting: boolean | null = null;
    let newMessagingEnabled: boolean | null = false;

    if (!projectSettingsError) {
      for (const setting of projectSettings) {
        if (setting.key !== 'useNewMessaging') continue;

        newMessagingProjectSetting = setting.value as boolean | null;
      }
    }

    if (newMessagingProjectSetting !== null) newMessagingEnabled = newMessagingProjectSetting;
    else newMessagingEnabled = tenantSettings.useNewMessaging as boolean;

    setMessagingEnabled(true);
    setNewMessagingEnabled(newMessagingEnabled);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProjectSettings, tenantSettingsFetched]);

  return { messagingEnabled, newMessagingEnabled };
};
