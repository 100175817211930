import React from 'react';
import styled from 'styled-components/macro';
import ArrowRight from '../Icons/ArrowRight';
import Bell from '../Icons/Bell';
import { NavLink } from 'react-router-dom';
import { mediaSmallMax } from '../Styled/media';
import { ResponsiveCloseIcon } from '../Icons/Close';
import alertStore from '../../store/AlertStore';

const StyledNotification = styled.div<{ withBorder?: boolean }>`
  background-color: white;
  display: flex;
  min-height: 70px;
  border: ${({ withBorder }) => (withBorder ? '1px solid var(--custom-colorNotif1)' : 'none')};
  position: relative;

  .close-button {
    position: absolute;
    right: 0.4rem;
    top: 0.4em;

    svg {
      width: 1rem;
      height: 1rem;
      fill: var(--custom-colorAccent2);
    }

    &:hover {
      svg {
        fill: var(--custom-colorHover);
      }
    }
  }

  &.static {
    border: 1px solid var(--custom-colorAccent2);
    margin: 1rem;

    .content {
      display: block;
    }
  }

  &:not(.static) {
    padding-right: 2.5rem;
  }

  p:first-child:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${mediaSmallMax} {
    min-height: 52px;
  }
`;

const IconBackground = styled.div<{ bgColor?: string }>`
  display: flex;
  min-width: 70px;
  min-height: 70px;
  padding: 0.5rem;
  background-color: ${({ bgColor }) => bgColor || 'var(--custom-colorMain)'};
  justify-content: center;
  align-items: center;

  @media ${mediaSmallMax} {
    min-width: 33px;
    min-height: 0;
  }
`;

const NotificationContent = styled.div`
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &.static {
    display: block;
  }

  @media ${mediaSmallMax} {
    padding: 0.5rem;
  }
`;

const ArrowContainer = styled.div`
  position: relative;
  min-width: 70px;
`;

const Arrow = styled(ArrowRight)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
`;

const Link = styled(NavLink)``;

const Button = styled.button`
  outline: 0;

  ${StyledNotification} {
    grid-template-columns: 70px 1fr 70px;
  }

  &:hover ${Arrow} {
    left: 65%;
  }
`;

interface NotificationProps {
  children: JSX.Element | JSX.Element[];
  link?: boolean;
  url?: string;
  color?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  withBorder?: boolean;
  [x: string]: any;
  static?: boolean;
}

const Notification = ({
  children,
  link,
  url,
  color,
  icon,
  onClick,
  withBorder,
  isStatic,
  ...rest
}: NotificationProps) => {
  const content = (
    <StyledNotification
      withBorder={withBorder}
      className={isStatic ? 'static' : ''}
      {...rest}
      role='alert'
    >
      <IconBackground bgColor={color}>{icon ? icon : <Bell fill='white' />}</IconBackground>
      <NotificationContent className='content'>{children}</NotificationContent>
      {link && (
        <ArrowContainer>
          <Arrow fill='--custom-colorAccent' />
        </ArrowContainer>
      )}
      {!isStatic && (
        <button className='close-button' onClick={() => alertStore.hide()}>
          <ResponsiveCloseIcon />
        </button>
      )}
    </StyledNotification>
  );

  return url ? (
    <Link to={url}>
      <Button>{content}</Button>
    </Link>
  ) : link ? (
    <Button onClick={onClick}>{content}</Button>
  ) : (
    content
  );
};

export default Notification;
