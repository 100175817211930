import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

export const modalHeaderHeight = '16rem';
export const modalHeaderHeightMobile = '6.75rem';

export const StyledModalHeader = styled.header<{ bg: string }>`
  height: ${modalHeaderHeight};
  background-image: ${({ bg }) => bg};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;

  @media ${mediaSmallMax} {
    height: ${modalHeaderHeightMobile};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(79.98deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0) 96.9%);
  padding: 0 3rem;
  height: 100%;
  h1 {
    margin-bottom: 1.8rem;
  }

  p {
    width: 60%;

    &.subtitle {
      font-size: 25px;
      line-height: 30px;
      font-weight: 400;
      font-family: var(--custom-fontName);
      width: 100%;
    }
  }

  @media ${mediaSmallMax} {
    padding: 0 1rem;

    p {
      font-size: 14px;
      line-height: 15px;
      width: 100%;

      &.subtitle {
        font-size: 18px;
        line-height: 20px;
      }
    }

    h1 {
      @media ${mediaSmallMax} {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

interface ModalHeaderProps {
  bgImage: string;
  title?: string;
  subTitle?: string;
  description?: string;
  children?: ReactNode;
}

const ModalHeader = ({ title, description, bgImage, subTitle, children }: ModalHeaderProps) => {
  return (
    <StyledModalHeader bg={`url(${bgImage})`}>
      <Header>
        {title && <h1>{title}</h1>}
        {subTitle && <p className='subtitle'>{subTitle}</p>}
        {description && <p>{description}</p>}
        {children}
      </Header>
    </StyledModalHeader>
  );
};

export default ModalHeader;
