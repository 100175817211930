import MaterialSelectionMode from '../enums/MaterialSelectionMode';
import SelectionMode from '../enums/SelectionMode';
import Theme from '../models/Theme';
import PublishState from '../enums/PublishState';
import { observable, computed, action } from 'mobx';
import { getFormattedDateBasedOnLocale } from '../../utils/dates';
import DateFormat from '../enums/DateFormat';

interface ThemeSetData {
  deadline: null | string;
  id: number;
  materialSelectionMode: MaterialSelectionMode;
  name: string;
  displayName: string;
  packages: Theme[];
  projectId: number;
  publishState: PublishState;
  selectionMode: SelectionMode;
  isSubPackageSet: boolean;
  canBeIgnored: boolean;
  selected?: boolean;
}

export default class ThemeSet {
  @observable public deadline: string | null;
  @observable public id: number;
  @observable public materialSelectionMode: MaterialSelectionMode;
  @observable public name: string;
  @observable public themes: Theme[];
  @observable public projectId: number;
  @observable public publishState: PublishState;
  @observable public selectionMode: SelectionMode;
  @observable public isSubPackageSet: boolean;
  @observable public canBeIgnored: boolean;

  @observable public selected = false;

  constructor({
    deadline,
    id,
    materialSelectionMode,
    name,
    displayName,
    packages,
    projectId,
    publishState,
    selectionMode,
    isSubPackageSet,
    canBeIgnored,
    selected
  }: ThemeSetData) {
    this.deadline = deadline;
    this.id = id;
    this.materialSelectionMode = materialSelectionMode;
    this.name = displayName || name;
    this.themes = packages;
    this.projectId = projectId;
    this.publishState = publishState;
    this.selectionMode = selectionMode;
    this.isSubPackageSet = isSubPackageSet;
    this.canBeIgnored = canBeIgnored;
    this.selected = !!selected;
  }

  @action public select = () => {
    this.selected = true;
  };

  @action public deselect = () => {
    this.selected = false;
  };

  @computed get hasSelectedThemes() {
    return this.themes.some((t) => t.selected);
  }

  @computed get selectedThemes() {
    return this.themes.filter((t) => t.selected && t.canSelectComputed);
  }

  @computed get themesByRoom() {
    return this.themes.reduce((themesByRoom, theme) => {
      if (!theme.roomIds.length) {
        themesByRoom.set(null, [...(themesByRoom.get(null) || []), theme]);
      } else {
        theme.roomIds.forEach((roomId: number) => {
          themesByRoom.set(roomId, [...(themesByRoom.get(roomId) || []), theme]);
        });
      }

      return themesByRoom;
    }, new Map<number | null, Theme[]>());
  }

  @computed get image() {
    const theme =
      this.themes.find((ts) => ts.selected && !!ts.masterImage) ||
      this.themes.find((ts) => !!ts.masterImage);

    return theme && theme.masterImage ? theme.masterImage.thumbnailUrl : '';
  }

  @computed get deadlineAsDate() {
    return this.deadline && getFormattedDateBasedOnLocale(this.deadline, DateFormat.COMPACT);
  }

  @computed get showTick(): boolean {
    return this.themes.some((t) => t.hasReviewedOptions);
  }
}
