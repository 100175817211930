import ReactGA4 from 'react-ga4';
import { EventAction } from './EventAction';
import { EventCategory } from './EventCategory';
import { hotjar } from 'react-hotjar';

export interface GAEvent {
  action: EventAction | string;
  category: EventCategory;
  label?: string;
}

export const initializeGA = () => {
  ReactGA4.initialize('G-M851CJNTJZ');
  ReactGA4.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: 'Page'
  });
};

export const initializeHJ = (hotjarIds: number[] = []) => {
  for (let i = 0, length = hotjarIds.length; i < length; i++) {
    hotjar.initialize(hotjarIds[i], 6);
  }
};

export const modalView = (path: string) =>
  ReactGA4.send({ hitType: 'pageview', page: path, title: 'Modal' });

export const pageView = (path: string) =>
  ReactGA4.send({ hitType: 'pageview', page: path, title: 'Page' });

/**
 * Trigger event to all known trackers
 * @param gaEvent
 */
export const triggerGlobalEvent = (gaEvent: GAEvent, params?: any) => {
  ReactGA4.event(gaEvent.action, {
    category: gaEvent.category,
    label: gaEvent.label,
    ...params
  });
};

/**
 * Trigger event to GB tracker and explicitly listed trackers
 * @param gaEvent
 * @param trackers
 */
export const triggerEvent = (gaEvent: GAEvent, trackers: string[] = []) => {
  ReactGA4.event({
    category: gaEvent.category,
    action: gaEvent.action
  });
};

// These are internal
export const triggerGAError = (action: string, label?: string) =>
  ReactGA4.event({ category: EventCategory.ERROR, action, label });
