import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import Message from '../../store/models/Message';
import { text } from '../../utils';
import ArrowRight from '../../components/Icons/ArrowRight';
import pencilIcon from '../../components/Icons/pencil.svg';
import moment from 'moment';
import CircleBadge from '../../components/CircleBadge/CircleBadge';
import { mediaSmallMax } from '../../components/Styled/media';

const StyledCircle = styled(CircleBadge)<{ color: string }>`
  background-color: ${({ color }) => color};
  margin-left: 2rem;
`;

const StyledArrowRight = styled(ArrowRight)`
  position: relative;
  left: 0;
  align-self: center;
  justify-self: flex-end;
  transition: all 0.2s ease-in-out;
`;

const StyledRoomCard = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 55%;
  font-size: 18px;
  min-width: 138px;
  margin-bottom: 2.8rem;
  border: 1px solid var(--custom-colorAccent3);
  &:hover ${StyledArrowRight} {
    left: 10px;
  }

  @media ${mediaSmallMax} {
    width: 100%;
    margin-bottom: 1rem;

    svg {
      width: 12px;
    }
  }
`;

const RoomAside = styled.div`
  padding: 12px;
  border-right: 1px solid var(--custom-colorAccent3);
`;

const MessageDate = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  p {
    font-size: 25px;
    text-align: center;
    align-self: center;

    @media ${mediaSmallMax} {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const SendMessage = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);

  @media ${mediaSmallMax} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  p {
    text-align: center;
    align-self: center;

    @media ${mediaSmallMax} {
      font-size: 12px;
      line-height: 15px;
    }
  }

  img {
    margin: 0 auto;
    align-self: flex-end;
    padding-bottom: 12px;

    @media ${mediaSmallMax} {
      width: 18px;
      height: 18px;
      padding: 0;
      margin-bottom: 5px;
    }
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1.2rem 1.4rem 1.4rem 2.4rem;

  @media ${mediaSmallMax} {
    padding: 1rem 1rem 1rem 0.75rem;
  }
`;

const Content = styled.div`
  width: 100%;
  grid-column-start: span 5;
`;

const RoomName = styled.h3`
  margin-bottom: 1.4rem;
  display: flex;

  @media ${mediaSmallMax} {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 4px;
  }
`;

const MessageContent = styled.p`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  span {
    font-weight: bold;
    font-family: var(--custom-fontNameBold);
  }

  @media ${mediaSmallMax} {
    font-size: 12px;
    line-height: 15px;
  }
`;

interface RoomCardProps {
  name: string;
  unreadMessages: number;
  message: Message;
  onClick?: () => void;
}

const RoomCard = ({ name, message, onClick, unreadMessages }: RoomCardProps) => {
  const momentTime = message && message.sendTime ? moment.unix(message.sendTime) : undefined;

  return (
    <StyledRoomCard onClick={onClick}>
      <RoomAside>
        {message && momentTime ? (
          <MessageDate>
            <p>{momentTime.format('D')}</p>
            <p>{momentTime.format('MMM')}</p>
            <p>{momentTime.format('YYYY')}</p>
          </MessageDate>
        ) : (
          <SendMessage>
            <img src={pencilIcon} alt='' />
            <p>{text('sendMessage')}</p>
          </SendMessage>
        )}
      </RoomAside>
      <ContentContainer>
        <Content>
          <RoomName>
            {name}
            {unreadMessages > 0 && (
              <StyledCircle color={'var(--custom-colorNotif3)'}>!</StyledCircle>
            )}
            {message?.discussionEnd === 1 && (
              <StyledCircle color={'rgba(0, 159, 218, 0.8)'}>!</StyledCircle>
            )}
          </RoomName>
          {message && (
            <MessageContent>
              <span>{message && message.userName}: </span>
              {message && message.content}
            </MessageContent>
          )}
        </Content>
        <StyledArrowRight width={18} height={31} fill={'#294754'} />
      </ContentContainer>
    </StyledRoomCard>
  );
};

export default (withRouter as any)(observer(RoomCard));
