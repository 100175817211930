import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import { SectionTitleDivider, SectionTitle, SectionArrow } from '../Styled/SectionTitleDivider';
import ThemesGrid from './ThemesGrid';
import { observer } from 'mobx-react-lite';
import themeStore from '../../store/ThemeStore';
import { withRouter } from 'react-router';
import appStore from '../../store/AppStore';
import SectionResizer from '../SectionResizer/SectionResizer';
import { mediaSmallMax } from '../Styled/media';
import { Accordion, StyledPaper } from '@groupbuilderoy/gb-components-library';

const ThemeSelector = styled.div<{ frontPage?: boolean }>`
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  margin-top: 2rem;
  @media ${mediaSmallMax} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
  }

  ${({ frontPage }) =>
    frontPage
      ? `
    background-color: var(--color-lightGray);
    padding-top: 1rem;
    padding-bottom: 2rem;
  `
      : ''}
`;

const CostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Cost = styled.p`
  margin-right: 2rem;
`;

const ThemesSection = styled.section`
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  overflow: hidden;
`;

const Themes = ({ isFrontPage }: { isFrontPage?: boolean }) => {
  const { integratedApp } = appStore;
  const { themesOpen, toggleThemesOpen, selectedTheme, selectedThemeSet, startedEditingThemeSet } =
    themeStore;

  const [maxHeight, setMaxHeight] = useState('88px');

  if (integratedApp) {
    return (
      <StyledPaper style={{ width: 'auto', marginTop: '-1rem' }}>
        <Accordion
          customCaption={
            <>
              <SectionTitle style={{ display: 'block' }}>
                <h3 style={{ display: 'inline' }}>{text('styles')}</h3>
                {selectedTheme && !appStore.tenantSettings.customerJourney2_usePackageSets && (
                  <p style={{ display: 'inline' }}>{selectedTheme.name}</p>
                )}
                {selectedThemeSet &&
                  selectedTheme &&
                  appStore.tenantSettings.customerJourney2_usePackageSets &&
                  startedEditingThemeSet && (
                    <p
                      style={{ display: 'inline' }}
                    >{`${selectedThemeSet.name}: ${selectedTheme.name}`}</p>
                  )}
              </SectionTitle>
              <CostWrapper>
                {!appStore.tenantSettings.customerJourney2_showRooms &&
                  !appStore.tenantSettings.customerJourney2_showHeader &&
                  appStore.apartment && (
                    <Cost>
                      {`${text('total')}: ${appStore.apartment.cost} ${appStore.currencySymbol}`}
                    </Cost>
                  )}
              </CostWrapper>
            </>
          }
          customContent={
            <ThemeSelector>
              <ThemesGrid open={themesOpen} />
            </ThemeSelector>
          }
          type={'base' as any}
          isExpanded={true}
          onToggleExpanded={() => toggleThemesOpen(undefined, true)}
          unlimitedHeight={true}
          style={{ padding: '1rem' }}
        />
      </StyledPaper>
    );
  }

  return !isFrontPage ? (
    <ThemesSection id='themes' style={{ maxHeight, minHeight: maxHeight }}>
      <SectionResizer
        adjustment={themesOpen ? '1.5rem' : '1rem'}
        effectListeners={[themesOpen, selectedThemeSet]}
        setNewHeight={setMaxHeight}
      >
        <SectionTitleDivider open={themesOpen} onClick={() => toggleThemesOpen(undefined, true)}>
          <SectionTitle style={{ display: 'block' }}>
            <h3 style={{ display: 'inline' }}>{text('styles')}</h3>
            {selectedTheme && !appStore.tenantSettings.customerJourney2_usePackageSets && (
              <p style={{ display: 'inline' }}>{selectedTheme.name}</p>
            )}
            {selectedThemeSet &&
              selectedTheme &&
              appStore.tenantSettings.customerJourney2_usePackageSets &&
              startedEditingThemeSet && (
                <p
                  style={{ display: 'inline' }}
                >{`${selectedThemeSet.name}: ${selectedTheme.name}`}</p>
              )}
          </SectionTitle>
          <CostWrapper>
            {!appStore.tenantSettings.customerJourney2_showRooms &&
              !appStore.tenantSettings.customerJourney2_showHeader &&
              appStore.apartment && (
                <Cost>
                  {`${text('total')}: ${appStore.apartment.cost} ${appStore.currencySymbol}`}
                </Cost>
              )}
            <SectionArrow open={themesOpen} />
          </CostWrapper>
        </SectionTitleDivider>
        <ThemeSelector>
          <ThemesGrid open={themesOpen} />
        </ThemeSelector>
      </SectionResizer>
    </ThemesSection>
  ) : (
    <ThemeSelector frontPage={isFrontPage}>
      <ThemesGrid open />
    </ThemeSelector>
  );
};

export default (withRouter as any)(observer(Themes));
