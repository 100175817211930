import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import appStore from '../../store/AppStore';
import { text, isCurrentLocation, intlCurrencyFormat } from '../../utils';
import routingStore from '../../store/RoutingStore';
import classnames from 'classnames';
import { mediaMediumMin, mediaSmallMax } from '../Styled/media';
import { gbDefaultLogo } from '../../store/models/TenantSettings';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import { useTrackers } from '../../hooks/useTrackers';

const StyledHeaderBanner = styled.div<{ integratedCj2?: boolean }>`
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;

  position: sticky;
  top: 0;
  background-color: white;
  ${({ integratedCj2 }) =>
    integratedCj2 &&
    `margin: 2rem 24px 0px 24px !important; border-radius: 8px; box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06) !important;`}
  z-index: 300;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  @media ${mediaSmallMax} {
    z-index: 5;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoContainer = styled.div`
  max-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3.25rem;

  @media ${mediaSmallMax} {
    margin: 0 1rem;
    margin-top: 1rem;
    width: 15rem;
    max-width: 15rem;
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  max-height: 2.2rem;
  height: 2.2rem;

  @media ${mediaSmallMax} {
    margin-right: 2rem;
  }
`;

const HeaderPhases = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;

  @media ${mediaSmallMax} {
    align-self: flex-start;
    margin: 0 1rem;
  }
`;

const Phase = styled.div`
  cursor: pointer;
  padding: 3px;
  color: var(--custom-colorNavLinks);

  &.selected {
    border-bottom: 3px solid var(--custom-colorNavLinks);
  }

  @media ${mediaMediumMin} {
    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:not(:first-child) {
      margin-left: 2rem;
    }
  }

  @media ${mediaSmallMax} {
    text-align: center;
    font-size: 12px;
    line-height: 13px;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Cost = styled.p`
  margin-right: 4rem;

  &.visible-on-mobile {
    display: none;
  }

  @media ${mediaSmallMax} {
    font-size: 12px;
    line-height: 13px;
    margin-left: 0.5rem;
    margin-right: 0;
    text-align: center;

    &.visible-on-mobile {
      display: block;
    }

    &:not(.visible-on-mobile) {
      display: none;
    }
  }
`;

const HeaderBanner = () => {
  const { integratedApp } = appStore;
  const { triggerEvent } = useTrackers();
  return (
    <StyledHeaderBanner integratedCj2={integratedApp}>
      <LogoContainer>
        <Logo
          src={appStore.tenantSettings.logoUrls?.organisationLogoDarkUrl}
          onError={(e) => {
            if (e.currentTarget.src !== gbDefaultLogo) {
              e.currentTarget.src = gbDefaultLogo;
            }
          }}
        />
        <Cost className='visible-on-mobile'>
          {`${text('total')}: ${appStore.apartment && appStore.apartment.cost} ${
            appStore.currencySymbol
          }`}
        </Cost>
      </LogoContainer>
      <HeaderPhases>
        <Phase
          onClick={() => routingStore.push('/materials')}
          className={classnames({
            selected: isCurrentLocation('/materials')
          })}
        >
          1. {text('apartmentStyles')}
        </Phase>
        <Phase
          onClick={() => {
            triggerEvent({
              action: EventAction.SUMMARY_PAGE_CLICKED,
              category: EventCategory.USER
            });
            routingStore.push('/order-confirmation');
          }}
          className={classnames({
            selected: isCurrentLocation('/order-confirmation')
          })}
        >
          2. {text('apartmentSummary')}
        </Phase>
      </HeaderPhases>
      <Cost>
        {`${text('total')}: ${
          appStore.apartment &&
          intlCurrencyFormat(
            appStore.apartment.cost!,
            appStore.currencyCode,
            appStore.parseLanguage()
          )
        }`}
      </Cost>
    </StyledHeaderBanner>
  );
};

export default observer(HeaderBanner);
