import styled from 'styled-components/macro';

export const TooltipContainer = styled.div`
  background-color: white;
  padding: 8px;
  text-align: center;
  z-index: 1;
  margin: auto;
`;

export const Tooltip = styled.span`
  width: auto;
  text-align: center;

  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  margin-bottom: 1rem;
  max-width: 100%;
  font-family: var(--custom-fontNameBold);
`;
