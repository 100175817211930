import React from 'react';
// eslint-disable-next-line
export default (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='35'
    viewBox='0 0 78 76'
    fill='white'
    {...props}
  >
    <path d='M38.4727 0.0927734C17.334 0.0927734 0.207031 17.0029 0.207031 37.874C0.207031 58.7451 17.334 75.6553 38.4727 75.6553C59.6113 75.6553 76.7383 58.7451 76.7383 37.874C76.7383 17.0029 59.6113 0.0927734 38.4727 0.0927734ZM71.8008 37.874C71.8008 55.9572 56.9729 70.7803 38.4727 70.7803C20.1576 70.7803 5.14453 56.14 5.14453 37.874C5.14453 19.7908 19.9725 4.96777 38.4727 4.96777C56.7877 4.96777 71.8008 19.608 71.8008 37.874ZM39.7842 51.067L57.5283 33.5322C58.2535 32.8162 58.2535 31.6584 57.5283 30.9424L56.4328 29.8607C55.7076 29.1447 54.535 29.1447 53.8098 29.8607L38.4727 45.0342L23.1201 29.876C22.3949 29.16 21.2223 29.16 20.4971 29.876L19.4016 30.9576C18.6764 31.6736 18.6764 32.8314 19.4016 33.5475L37.1457 51.0822C37.8863 51.783 39.059 51.783 39.7842 51.067Z' />
    <path d='M195.273 0.0927734C174.134 0.0927734 157.007 17.0029 157.007 37.874C157.007 58.7451 174.134 75.6553 195.273 75.6553C216.412 75.6553 233.538 58.7451 233.538 37.874C233.538 17.0029 216.412 0.0927734 195.273 0.0927734ZM228.601 37.874C228.601 55.9572 213.773 70.7803 195.273 70.7803C176.958 70.7803 161.945 56.14 161.945 37.874C161.945 19.7908 176.773 4.96777 195.273 4.96777C213.588 4.96777 228.601 19.608 228.601 37.874ZM196.584 51.067L214.329 33.5322C215.054 32.8162 215.054 31.6584 214.329 30.9424L213.233 29.8607C212.508 29.1447 211.335 29.1447 210.61 29.8607L195.273 45.0342L179.92 29.876C179.195 29.16 178.022 29.16 177.297 29.876L176.202 30.9576C175.477 31.6736 175.477 32.8314 176.202 33.5475L193.946 51.0822C194.687 51.783 195.859 51.783 196.584 51.067Z' />
  </svg>
);
