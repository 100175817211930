import styled from 'styled-components/macro';
import Button from '../Button/Button';
import { MaterialImageWrapper } from '../Materials/styledComponents';
import { FormattedTextSection } from '../Styled/FormattedTextSection';
import { mediaMediumMin, mediaSmallMax } from '../Styled/media';

export const OrderConfirmationButton = styled(Button)`
  margin: 2rem 0;
  align-self: center;
  background-color: white @media ${mediaSmallMax} {
    margin: 1rem;
    align-self: stretch;

    border: 1px solid;
  }
`;

export const ConfirmationBundle = styled.div`
  display: flex;
  min-height: 6rem;
  padding: 1rem 0;
  position: relative;

  &:not(:last-child):after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background: var(--custom-colorAccent3);
    opacity: 0.5;
    width: 100%;
    content: '';
  }

  @media ${mediaSmallMax} {
    justify-content: space-between;
  }
`;

export const BundleInfo = styled(FormattedTextSection)`
  overflow: hidden;
  flex-grow: 1;
  padding-right: 1rem;

  p {
    &.font-light,
    &.font-normal {
      line-height: 16px;
      font-size: 12px;

      @media ${mediaSmallMax} {
        line-height: 13px;
        font-size: 12px;
      }
    }
    &.font-medium {
      font-size: 18px;

      @media ${mediaSmallMax} {
        line-height: 15px;
        font-size: 14px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  @media ${mediaSmallMax} {
    padding-left: 1rem;
  }
`;

export const SelectedMaterialPrice = styled.p<{ askForPrice?: boolean }>`
  position: absolute;
  top: 2rem;
  right: 1rem;
  ${({ askForPrice }) =>
    askForPrice &&
    'background-color: #f2f2f2; padding: 2px 6px; font-size: 17px; border-radius: 10px'};

  @media ${mediaSmallMax} {
    &.visible-on-desktop {
      display: none;
    }

    position: relative;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 13px;
  }

  @media ${mediaMediumMin} {
    &:not(.visible-on-desktop) {
      display: none;
    }
  }
`;

export const StyledImageWrapper = styled(MaterialImageWrapper)`
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  max-height: 6rem;
  margin-right: 2rem;

  @media ${mediaSmallMax} {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
`;

export const StyledBundlesConfirmation = styled.div`
  padding: 0.5rem 3rem;
  display: flex;
  flex-direction: column;

  @media ${mediaSmallMax} {
    padding: 0 1rem;
  }
`;
