import { observable, action } from 'mobx';

interface ApartmentData {
  name: string;
  id: number;
  cost: string | null;
  projectId: string;
  ifcguid: string;
  projectIfcguid: string;
  threeDUrl: string;
}

export default class Apartment {
  @observable public name: string;
  @observable public id: number = -1;
  @observable public cost: string | null = '';
  @observable public projectId: string = '';
  @observable public ifcguid: string = '';
  @observable public projectIfcGuid: string = '';
  @observable public threeDUrl: string = '';

  constructor({ name, id, cost, projectId, ifcguid, projectIfcguid, threeDUrl }: ApartmentData) {
    this.name = name;
    this.id = id;
    this.ifcguid = ifcguid;
    this.projectIfcGuid = projectIfcguid;
    this.cost = cost;
    this.projectId = projectId;
    this.threeDUrl = threeDUrl;
  }

  @action setThreeDUrl = (threeDUrl: string) => {
    this.threeDUrl = threeDUrl;
  };
}
