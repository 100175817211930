import { appendScript } from './util';

declare global {
  interface Window {
    Userlane: any;
  }
}

const setUpUserlane = (userlaneId: string) => {
  return appendScript(
    'userlane-id',
    `
      (function(i,s,o,g,r,a,m){i['UserlaneCommandObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://cdn.userlane.com/userlane.js','Userlane');
    `,
    () => window.Userlane('init', userlaneId)
  );
};

export default setUpUserlane;
